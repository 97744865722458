<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span> {{ trainingObj.name }}</span>
      </div>
      <div class="text item" style="font-size: 14px">
        {{ trainingObj.content }}
      </div>
    </el-card>
    <div id="trainingInquiryList" v-if="company">
      <h4>
        <span>{{ company.companyName }} </span>
        <span class="ml-20"
          >{{
            company.provinceName +
            company.cityName +
            company.regionName +
            company.streetName
          }}
        </span>
      </h4>
      <el-row :gutter="20">
        <el-col :span="7">
          <span>搜索内容：</span>
          <el-input
            style="width: 300px"
            v-model="searchInput"
            placeholder="请输入课程名称/关键字"
          ></el-input>
        </el-col>
        <el-col :span="5">
          <span>岗位：</span>
          <el-select v-model="positionValue" placeholder="请选择岗位" clearable>
            <el-option
              v-for="item in positionList"
              :key="item.id"
              :label="item.postionName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <span>课程：</span>
          <el-select v-model="lessonValue" placeholder="请选择课程" clearable>
            <el-option
              v-for="item in lessonList"
              :key="item.id"
              :label="item.lessonName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-button type="primary" @click="search">查询</el-button>
          <!-- <el-button type="" @click="reset">重置</el-button> -->
        </el-col>
      </el-row>
      <!-- <el-row :gutter="20">
        <el-col :span="3">
          <div
            style="
              padding: 10px 10px 10px 22px;
              border: 1px solid #dcdfe6;
              border-radius: 5px;
              position: relative;
              cursor: pointer;
            "
            @click="rank"
          >
            按学习进度排序
            <i
              class="el-icon-caret-top icon-top"
              :class="rankValue == 'top' ? 'icon-active' : ''"
            ></i
            ><i
              class="el-icon-caret-bottom icon-bottom"
              :class="rankValue == 'bottom' ? 'icon-active' : ''"
            ></i>
          </div>
        </el-col>
      </el-row> -->
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column type="selection" fixed></el-table-column>
        <el-table-column
          prop="userCode"
          label="用户编码"
          fixed
        ></el-table-column>
        <el-table-column prop="name" label="用户姓名" fixed></el-table-column>
        <el-table-column
          prop="postionName"
          label="岗位"
          fixed
        ></el-table-column>
        <el-table-column prop="lessonName" label="参与培训课程" width="150">
        </el-table-column>
        <el-table-column prop="totalHour" label="课程总课时数" width="150">
        </el-table-column>
        <el-table-column prop="studyHour" label="学习时长" width="150">
          <template slot-scope="scope">
            {{ scope.row.studyHour | timeCycle }}
          </template>
        </el-table-column>
        <el-table-column prop="studyProgress" label="学习进度">
          <template slot-scope="scope">
            {{ scope.row.studyProgress }}%
          </template>
        </el-table-column>

        <el-table-column label="操作" fixed="right" width="300">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="navigationCheckStudyBehaviorRecord(scope.row)"
              >查看学习行为记录</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalItems"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getPlatformTrainingCompanyDataList,
  getAddPlatformPositionList,
  getAddLessonList,
} from "@/api/trainingManage";
export default {
  data() {
    return {
      searchInput: "",
      dropdownValue: "",
      trainingObj: "",
      company: "",
      rankValue: "top",
      tableData: [],
      positionList: [],
      lessonList: [],
      positionValue: "",
      lessonValue: "",
      currentPage: 1,
      pageSize: 10,
      totalItems: 0,
      lessonId: "",
      positionId: "",
    };
  },
  filters: {
    // 过滤时间
    timeCycle(value) {
      const remainder = value % 60 < 10 ? "0" + (value % 60) : value % 60;
      const numStr = String((value / 60).toFixed(2));
      if (value > 60 && remainder == 0) {
        return (
          Math.floor(value / 60) +
          "分钟" +
          numStr.slice(numStr.indexOf(".") + 1) +
          "秒"
        );
      } else if (value > 60 && remainder != 0) {
        return Math.floor(value / 60) + "分钟" + remainder + "秒";
      } else {
        return value + "秒";
      }
    },
  },
  created() {
    this.trainingObj = JSON.parse(sessionStorage.getItem("unitData"));
    this.getPlatformTrainingCompanyDataList(this.$route.query.id);
    this.getAddPlatformPositionList();
    this.getAddLessonList(this.trainingObj.id);
    // window.addEventListener("beforeunload", () => {
    //   sessionStorage.getItem("lessonId");
    //   sessionStorage.getItem("positionId");
    // });
  },
  // destroyed() {
  //   sessionStorage.removeItem("lessonId");
  //   sessionStorage.removeItem("positionId");
  // },
  methods: {
    // 查看单位用户数据
    async getPlatformTrainingCompanyDataList(id) {
      let res = await getPlatformTrainingCompanyDataList({
        id: id,
        name: this.searchInput,
        trainingId: this.trainingObj.id,
        positionId: this.positionValue,
        positionId1: sessionStorage.getItem("positionId"),
        lessonId: this.lessonValue,
        lessonId1: sessionStorage.getItem("lessonId"),
        startNum: this.currentPage,
        pageSize: this.pageSize,
      });
      if (res.data.result == "success") {
        this.tableData = res.data.platformTrainingCompanyDataList;
        this.company = res.data.company;
        this.totalItems = res.data.size;
      }
    },
    // 获取岗位
    async getAddPlatformPositionList() {
      let res = await getAddPlatformPositionList();
      if (res.data.result == "success") {
        this.positionList = res.data.msg;
      }
    },
    // 获取课程列表
    async getAddLessonList(id) {
      let res = await getAddLessonList({
        traingId: id,
      });
      if (res.data.result == "success") {
        this.lessonList = res.data.msg;
      }
    },
    // 搜索
    search() {
      // Implement search functionality
      this.getPlatformTrainingCompanyDataList(this.$route.query.id);
    },
    // 重置
    reset() {
      // Implement reset functionality
    },
    // 排序
    rank() {
      if (this.rankValue == "top") {
        this.rankValue = "bottom";
        return;
      }
      if (this.rankValue == "bottom") {
        this.rankValue = "top";
        return;
      }
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getPlatformTrainingCompanyDataList(this.$route.query.id);
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.getPlatformTrainingCompanyDataList(this.$route.query.id);
    },
    // 跳转查看学习行为记录
    navigationCheckStudyBehaviorRecord(row) {
      this.$router.push({
        path: "/checkStudyBehaviorRecord",
      });
      sessionStorage.setItem(
        "studyRecord",
        JSON.stringify({
          user: row,
          company: this.company,
        })
      );
    },
  },
  mounted() {
    // Fetch address options for cascader
    // Fetch initial data for table
  },
};
</script>

<style scoped>
/* Add custom styles here */
#trainingInquiryList {
  padding: 20px;
}
.el-row {
  margin-bottom: 20px;
}
.ml-20 {
  margin-left: 20px;
}
.icon-top {
  position: absolute;
  right: 18px;
  top: 4px;
  font-size: 24px;
  color: #cccccc;
}
.icon-bottom {
  position: absolute;
  right: 18px;
  top: 14px;
  font-size: 24px;
  color: #cccccc;
}
.icon-active {
  color: #2d8cf0;
}
</style>
