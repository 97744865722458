<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span> 岗位管理</span>
      </div>
      <div class="text item" style="font-size: 14px">
        对岗位进行管理的模块。
      </div>
      <!-- <el-button @click="study">查看学习数据</el-button> -->
    </el-card>
    <el-row style="margin: 20px">
      <el-col :span="10">
        <span>搜索内容：</span
        ><el-input
          style="width: 500px"
          v-model="search"
          placeholder="请输入岗位名称"
          clearable
        ></el-input
      ></el-col>
      <el-col :span="4"
        ><el-button type="primary" @click="searchCarousel"
          >查询</el-button
        ></el-col
      >
    </el-row>
    <el-row style="margin: 20px">
      <el-button type="primary" @click="NewPosition">新建岗位</el-button>
    </el-row>
    <el-table :data="tableData" border style="width: 80%">
      <el-table-column
        type="index"
        width="80px"
        label="序号"
        align="center"
      ></el-table-column>
      <el-table-column prop="id" label="ID" align="center"></el-table-column>
      <el-table-column
        prop="postionName"
        label="岗位名称"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="positionNumber"
        label="岗位编号"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="setPositionManagement(scope.row)"
            >修改</el-button
          >
          <el-button
            type="danger"
            @click="deletesetPositionManagement(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- 新增弹窗 -->
    <el-dialog
      title="新增岗位"
      :visible.sync="dialogFormVisible"
      style="width: 80%; margin: auto"
    >
      <el-form :model="form" style="width: 60%">
        <el-form-item label="岗位名称" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="岗位编号" :label-width="formLabelWidth">
          <el-input v-model="form.coding" autocomplete="off"></el-input>
          <div>说明：岗位编码必须设置为两位数。</div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="NewPositionName">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 修改弹窗 -->
    <el-dialog
      title="修改岗位"
      :visible.sync="dialogTableVisible"
      style="width: 80%; margin: auto"
    >
      <el-form :model="form" style="width: 60%">
        <el-form-item label="岗位名称" :label-width="formLabelWidth">
          <el-input v-model="set.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="岗位编号" :label-width="formLabelWidth">
          <el-input v-model="set.coding" autocomplete="off"></el-input>
          <div>说明：岗位编码必须设置为两位数。</div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="setPositionName">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  PositionManagementList,
  NewPositionName,
  setPositionName,
  deletesetPositionManagement,
} from "@/api/slideshowManagement";
export default {
  data() {
    return {
      search: "",
      tableData: [],
      dialogFormVisible: false,
      form: {
        name: "",
        coding: "",
      },
      set: {
        name: "",
        coding: "",
      },
      formLabelWidth: "120px",
      dialogTableVisible: false,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      id: "",
    };
  },
  created() {
    this.PositionManagementList();
  },
  methods: {
    async PositionManagementList() {
      let param = {
        name: this.search,
        page: this.currentPage,
        size: this.pageSize,
      };
      let res = await PositionManagementList(param);
      console.log(res);
      if ("success" == res.data.result) {
        this.tableData = res.data.data;
        this.total = res.data.total;
      }
    },
    // 查询
    searchCarousel() {
      this.PositionManagementList();
    },
    // 修改
    setPositionManagement(row) {
      this.id = row.id;
      this.dialogTableVisible = true;
      this.set.name = row.postionName;
      this.set.coding = row.positionNumber;
    },
    // 确认修改
    async setPositionName() {
      let param = {
        id: this.id,
        positionName: this.set.name,
        positionNumber: this.set.coding,
      };
      let res = await setPositionName(param);
      if ("操作成功" == res.data.msg) {
        this.$message({
          type: "success",
          message: res.data.msg,
        });
        this.dialogTableVisible = false;
        this.PositionManagementList();
      } else {
        this.$message.error(res.data.msg);
      }
    },
    // 删除
    deletesetPositionManagement(row) {
      this.$confirm("确定删除此岗位吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let param = {
            positionId: row.id,
          };
          let res = await deletesetPositionManagement(param);
          if ("操作成功" == res.data.msg) {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
            this.dialogTableVisible = false;
            this.PositionManagementList();
          }
        })
        .catch(() => {});
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.PositionManagementList();
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.PositionManagementList();
    },
    NewPosition() {
      this.dialogFormVisible = true;
    },
    // 新增岗位确认
    async NewPositionName() {
      if (this.form.name == "") {
        this.$message.error("请填写岗位名称");
        return;
      }
      if (this.form.coding == "") {
        this.$message.error("请填写岗位编号");
        return;
      }
      let param = {
        positionName: this.form.name,
        positionNumber: this.form.coding,
      };
      let res = await NewPositionName(param);
      console.log(res, "sssssd");
      if ("操作成功" == res.data.msg) {
        this.$message({
          type: "success",
          message: res.data.msg,
        });
        this.dialogFormVisible = false;
      }
    },
  },
};
</script>
