<template>
  <div class="home-page">
    <h1>欢迎使用职业健康培训平台</h1>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.home-page {
  width: 100%;
  height: 90vh;
  /* background-image: url("../../assets/images/homePage.png"); */
  position: relative;
}
.home-page h1 {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  font-size: 48px;
  color: #409eff;
}
</style>
