<template>
  <div class="add-plat-form-admin">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="150px"
      class="demo-ruleForm"
    >
      <el-form-item label="模板名称" prop="templateName">
        <el-input
          v-model="ruleForm.templateName"
          placeholder="请输入模板名称"
          maxlength="30"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="上传图片" prop="imageUrl">
        <el-upload
          class="avatar-uploader"
          action="/course/oss/uploadFile"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <img
            v-if="ruleForm.imageUrl"
            :src="ruleForm.imageUrl"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="上传模板文件" prop="fileList">
        <el-upload
          class="upload-demo"
          action="none"
          :auto-upload="false"
          :on-change="templateFileChange"
          :before-remove="beforeRemove"
          multiple
          :limit="1"
          :on-exceed="handleExceed"
          :file-list="ruleForm.fileList"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传pdf文件</div>
        </el-upload>
      </el-form-item>
      <el-form-item label="是否公开" prop="isPublic">
        <el-switch
          v-model="ruleForm.isPublic"
          active-value="1"
          inactive-value="0"
        ></el-switch>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >保存</el-button
        >
        <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  educationCertificateTemplate,
  updateCertificateTemplate,
} from "@/api/certificateTemplate";
export default {
  data() {
    return {
      ruleForm: {
        templateId: "",
        templateName: "",
        imageUrl: "",
        fileList: [],
        templatePdfUrl: "",
        isPublic: "1",
      },
      rules: {
        templateName: [
          {
            required: true,
            message: "请输入模板名称",
            trigger: "blur",
          },
        ],
        imageUrl: [
          {
            required: true,
            message: "请上传模板图片",
            trigger: "click",
          },
        ],
        fileList: [
          {
            required: true,
            message: "请上传模板文件",
            trigger: "click",
          },
        ],
        isPublic: [
          {
            required: true,
            message: "",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    let certificateTemplateData = JSON.parse(
      sessionStorage.getItem("certificateTemplateData")
    );
    this.ruleForm.templateId = certificateTemplateData.id;
    this.ruleForm.templateName = certificateTemplateData.templateName;
    this.ruleForm.imageUrl = certificateTemplateData.templateUrl;
    this.ruleForm.templatePdfUrl = certificateTemplateData.templatePdfUrl;
    this.ruleForm.isPublic = String(certificateTemplateData.isOpen);
    console.log(
      certificateTemplateData,
      "certificateTemplateData",
      this.ruleForm
    );
  },
  methods: {
    submitForm(formName) {
      let param = {
        templateName: this.ruleForm.templateName,
        templateUrl: this.ruleForm.imageUrl,
        templatePdfUrl: this.ruleForm.templatePdfUrl,
        templateFile: this.ruleForm.fileList,
        isOpen: this.ruleForm.isPublic,
      };
      console.log(param, "param", this.ruleForm.fileList);
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = await updateCertificateTemplate(param);
          if (res.data.result == "success") {
            this.$message.success("保存成功");
            this.$router.go(-1);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 上传图片成功后
    handleAvatarSuccess(res, file) {
      console.log(res.msg, file);
      // this.imageUrl = URL.createObjectURL(file.raw);
      this.ruleForm.imageUrl = file.response.msg;
    },
    // 上传图片前
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传模板图片只能是 JPG和PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传模板图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    // 上传模板文件
    async templateFileChange(file, fileList) {
      const isPDF = file.raw.type === "application/pdf";
      console.log(file, fileList, "templateFileChange", isPDF);
      this.ruleForm.fileList = [];
      if (!isPDF) {
        this.$message.error("上传模板文件只能是 PDF 格式!");
        return;
      }
      let formData = new FormData();
      formData.append("file", file.raw);
      let res = await educationCertificateTemplate(formData);
      if (res.data.result == "success") {
        this.ruleForm.fileList.push(file);
        this.ruleForm.templatePdfUrl = res.data.msg;
        console.log(res, "res");
      }
    },
    // 监听用户上传文件数量
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    // 监听用户是否删除当前选择的文件
    beforeRemove(file, fileList) {
      console.log(fileList);
      return this.$confirm(`确定移除 ${file.name}？`);
    },
  },
};
</script>

<style scoped>
.add-plat-form-admin {
  width: 50%;
  padding: 50px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  border: 1px dashed #d9d9d9;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  border: 1px dashed #d9d9d9;
  width: 178px;
  height: 178px;
  display: block;
}
</style>
