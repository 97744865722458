<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span> 新建课程类别</span>
      </div>
      <div class="text item" style="font-size: 14px">添加课程类别</div>
    </el-card>
    <el-tabs
      v-model="activeTab"
      class="login-tabs"
      :before-leave="beforeTabLeave"
    >
      <el-tab-pane label="课程基本设置" name="courseSetting">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          label-width="150px"
          class="demo-ruleForm"
        >
          <el-form-item label="课程类别名称" prop="category">
            <el-input
              v-model="form.category"
              placeholder="请输入课程类别名称"
              style="width: 500px"
              maxlength="30"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="是否公开">
            <el-switch
              v-model="form.isPublic"
              active-value="1"
              inactive-value="0"
            ></el-switch>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="accomplishSubmitForm('form')"
              >完成</el-button
            >
            <el-button type="primary" @click="settingsSubcategories('form')"
              >前往设置子分类</el-button
            >
            <!-- <el-button @click="resetForm('form')">重置</el-button> -->
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="课程子分类设置" name="subclassificationSetting">
        <el-form
          :model="subform"
          :rules="subformRules"
          ref="subform"
          label-width="150px"
          class="demo-ruleForm"
        >
          <el-form-item
            v-for="(domain, index) in subform.subclassification"
            :label="index > 0 ? '子分类' + index : '子分类'"
            :key="domain.key"
            :prop="'subclassification.' + index + '.name'"
            :rules="{
              required: true,
              message: '请输入子分类的名称',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="domain.name"
              placeholder="请输入子分类的名称"
              style="width: 500px"
            ></el-input>
            <el-button @click.prevent="subclassificationRemove(domain)"
              >删除</el-button
            >
          </el-form-item>
          <el-form-item>
            <div class="subclassification" @click="addSubclassification">
              <i class="el-icon-plus"></i>
              <span>添加子分类</span>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('subform')"
              >提交</el-button
            >
            <!-- <el-button @click="resetForm('form')">重置</el-button> -->
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { addCourseClasses, saveChildrenCategory } from "@/api/courseManage";
export default {
  data() {
    return {
      activeTab: "courseSetting",
      parentId: "", // 父级id
      form: {
        category: "",
        isPublic: "1",
      },
      subform: {
        subclassification: [{ name: "" }], //子分类
      },

      rules: {
        category: [
          { required: true, message: "请输入课程类别名称", trigger: "blur" },
        ],
      },
      subformRules: {},
    };
  },
  methods: {
    // tab 栏切换时触发
    beforeTabLeave(activeName, oldActiveName) {
      console.log(activeName, oldActiveName);
      let flag = true;
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (!this.parentId) {
            let res = await addCourseClasses({
              courseName: this.form.category,
              isOpen: this.form.isPublic,
            });
            console.log(res);
            if (res.data.result == "success") {
              this.parentId = res.data.id;
              flag = true;
            }
          } else {
            flag = true;
          }
        } else {
          flag = false;
        }
      });
      console.log(flag);
      return flag;
    },
    // 子分类提交
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let childrenCourseName = this.subform.subclassification
            .map((item) => item.name)
            .join(",");
          let res = await saveChildrenCategory({
            parentId: this.parentId,
            childrenCourseName: childrenCourseName,
          });
          console.log(res);
          if (res.data.result == "success") {
            this.$router.go(-1);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 点击按钮完成触发提交课程设置并返回上一步
    accomplishSubmitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = await addCourseClasses({
            courseName: this.form.category,
            isOpen: this.form.isPublic,
          });
          console.log(res);
          if (res.data.result == "success") {
            this.$router.go(-1);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 前往设置子分类
    settingsSubcategories(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (!this.parentId) {
            let res = await addCourseClasses({
              courseName: this.form.category,
              isOpen: this.form.isPublic,
            });
            console.log(res);
            if (res.data.result == "success") {
              this.parentId = res.data.id;
              this.activeTab = "subclassificationSetting";
            }
          } else {
            this.activeTab = "subclassificationSetting";
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 添加子分类
    addSubclassification() {
      this.subform.subclassification.push({
        name: "",
        key: Date.now(),
      });
    },
    // 删除子分类
    subclassificationRemove(item) {
      let index = this.subform.subclassification.indexOf(item);
      if (index !== -1) {
        this.subform.subclassification.splice(index, 1);
      }
    },
  },
};
</script>
<style scoped>
.demo-ruleForm {
  margin-top: 20px;
}

.subclassification {
  border: 1px dashed #797979;
  width: 300px;
  height: 30px;
  border-radius: 10px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  margin-top: 10px;
}
.login-tabs {
  margin: 20px;
  width: 50%;
}
</style>
