import { render, staticRenderFns } from "./invoiceReviewList.vue?vue&type=template&id=3cb8e111&scoped=true&"
import script from "./invoiceReviewList.vue?vue&type=script&lang=js&"
export * from "./invoiceReviewList.vue?vue&type=script&lang=js&"
import style0 from "./invoiceReviewList.vue?vue&type=style&index=0&id=3cb8e111&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cb8e111",
  null
  
)

export default component.exports