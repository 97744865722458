<template>
  <div>
    <el-card class="box-card">
      <div class="clearfix" v-if="governmentData">
        <div>培训机构名称：{{ governmentData ? governmentData.name : "" }}</div>
        <div style="margin: 30px 0 0">
          所在地：<span v-if="governmentData.power == 1">{{
            governmentData.countyName
          }}</span>
          <span v-else>
            {{ governmentData.provinceName ? governmentData.provinceName : "" }}
            {{ governmentData.cityName ? governmentData.cityName : "" }}
            {{ governmentData.regionName ? governmentData.regionName : "" }}
            {{ governmentData.streetName ? governmentData.streetName : "" }}
          </span>
        </div>
      </div>
    </el-card>
    <div class="add-plat-form-admin">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="设置账号" prop="accountNumber">
          <el-input
            v-model="ruleForm.accountNumber"
            placeholder="请输入账号"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="设置密码" prop="password">
          <el-input
            show-password
            type="password"
            v-model="ruleForm.password"
            placeholder="请输入账号密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="再次输入密码" prop="verifyPassword">
          <el-input
            show-password
            type="password"
            v-model="ruleForm.verifyPassword"
            placeholder="请输入账号密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="管理员姓名" prop="adminName">
          <el-input
            v-model="ruleForm.adminName"
            placeholder="请输入姓名"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input
            v-model="ruleForm.phone"
            placeholder="请输入联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >保存</el-button
          >
          <el-button @click="onCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { saveTrainingInstitutionsUser } from "@/api/trainingInstitution";
export default {
  data() {
    return {
      governmentData: "",
      ruleForm: {
        accountNumber: "",
        password: "",
        verifyPassword: "",
        adminName: "",
        phone: "",
      },
      rules: {
        accountNumber: [
          {
            required: true,
            message: "账号可为字母、数字或符号",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "至少6位密码，区分大小写",
            trigger: "blur",
          },
          {
            // pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}/,
            pattern: /[a-zA-Z\d]{6,}/,
            message: "至少6位密码，区分大小写",
            trigger: "blur",
          },
        ],
        verifyPassword: [
          { required: true, message: "确认密码", trigger: "blur" },
          {
            // pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}/,
            pattern: /[a-zA-Z\d]{6,}/,
            message: "至少6位密码，区分大小写",
            trigger: "blur",
          },
        ],
        adminName: [
          {
            required: true,
            message: "姓名只可为汉字、字母、数字或符号",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.governmentData = JSON.parse(sessionStorage.getItem("governmentData"));
  },
  methods: {
    submitForm(formName) {
      if (this.ruleForm.password != this.ruleForm.verifyPassword) {
        this.$message({
          message: "请确认两次密码输入是否一致",
          type: "warning",
        });
        return;
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = await saveTrainingInstitutionsUser({
            account: this.ruleForm.accountNumber,
            password: this.ruleForm.password,
            confirmPassword: this.ruleForm.verifyPassword,
            name: this.ruleForm.adminName,
            phone: this.ruleForm.phone,
            trainingInstitutionId: this.governmentData.id,
          });
          if (res.data.msg == "success") {
            this.$message({
              type: "success",
              message: "保存成功",
            });
            this.$router.go(-1);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    onCancel() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
.add-plat-form-admin {
  width: 70%;
  padding: 50px;
}
</style>
