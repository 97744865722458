<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span> 普通用户管理</span>
      </div>
      <div class="text item" style="font-size: 14px">对普通用户进行管理。</div>
      <!-- <el-button @click="study">查看学习数据</el-button> -->
    </el-card>
    <el-row style="margin: 20px 0">
      <el-col :span="6">
        <el-input
          v-model="searchText"
          placeholder="请输入用户名/关键字"
          @keyup.enter.native="search"
        />
      </el-col>
      <el-col :span="6" style="text-align: center">
        <el-select v-model="selectedUnit" placeholder="请选择" @change="search">
          <el-option
            v-for="status in status"
            :key="status.value"
            :label="status.label"
            :value="status.value"
          />
        </el-select>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="search">搜索</el-button>
        <!-- <el-button type="" @click="reset">重置</el-button> -->
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="DerivedData">导出数据</el-button>
      </el-col>
      <el-col :span="2">
        <el-upload
          class="upload-demo"
          action="none"
          :auto-upload="false"
          :on-change="commonYonghua"
          multiple
          :limit="1"
          :file-list="fileList"
          :on-exceed="handleExceed"
          :show-file-list="false"
        >
          <el-button type="primary">导入表格</el-button>
        </el-upload>
      </el-col>
      <el-col :span="4" style="line-height: 40px">
        <a @click="templateLink">普通用户导入模板下载</a>
      </el-col>

      <!-- <el-col :span="6" style="text-align: right">
        <el-button type="primary" @click="addUser">添加用户</el-button>
      </el-col> -->
    </el-row>
    <el-table
      :data="tableData"
      @selection-change="handleSelectionChange"
      border
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column
        prop="userCode"
        label="用户编码"
        width="120"
        align="center"
      />
      <el-table-column prop="name" label="姓名" width="200" align="center" />
      <el-table-column prop="sex" label="性别" width="120" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.sex != null">{{
            scope.row.sex == 0 ? "女" : "男"
          }}</span>
          <span v-else>未填写</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="education"
        label="学历"
        width="200"
        align="center"
      />
      <el-table-column
        prop="streetName"
        label="联系地址"
        width="200"
        align="center"
      />
      <el-table-column
        prop="companyName"
        label="所属单位"
        width="200"
        align="center"
      />
      <el-table-column prop="name" label="地区" width="200" align="center">
        <template slot-scope="scope">
          {{ scope.row.provinceName ? scope.row.provinceName : "" }}
          {{ scope.row.cityName ? scope.row.cityName : "" }}
          {{ scope.row.regionName ? scope.row.regionName : "" }}
          {{ scope.row.streetName ? scope.row.streetName : "" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="positionName"
        label="岗位"
        width="200"
        align="center"
      />
      <el-table-column prop="phone" label="联系电话" width="200" align="center">
        <template slot-scope="scope">
          <!-- <span v-if="scope.row.phone">{{
            scope.row.phone.substring(0, 3) +
            "****" +
            scope.row.phone.substring(7, 11)
          }}</span>
          <span v-else>未填写</span> -->
          {{ scope.row.phone }}
        </template>
      </el-table-column>
      <template v-if="$store.state.userIsPrimaryAdmin == 1">
        <el-table-column label="启用/禁用" width="200" align="center">
          <template slot-scope="scope">
            <el-radio-group
              v-model="scope.row.recovery"
              @change="changeStatus(scope.row)"
            >
              <el-radio :label="false">启用</el-radio>
              <el-radio :label="true">禁用</el-radio>
            </el-radio-group>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="300" fixed="right">
          <template slot-scope="scope">
            <!-- <el-button type="info" @click="sendMessage(scope.row)"
            >发送消息</el-button
          > -->
            <el-dialog
              title="给用户发消息"
              :visible.sync="dialogVisible"
              :close-on-click-modal="false"
            >
              <p>接收人：{{ sendMsgData.wechatName }}</p>
              <el-form>
                <el-form-item label="内容">
                  <el-input type="textarea" v-model="messageContent"></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="sendMessage">确 定</el-button>
              </div>
            </el-dialog>
            <el-button type="danger" @click="deleteUser(scope.row)"
              >删除</el-button
            >
            <el-button type="primary" @click="setAdmin(scope.row)"
              >设为管理员</el-button
            >
          </template>
        </el-table-column>
      </template>
    </el-table>
    <el-pagination
      :total="total"
      :page-size="pageSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
    />
    <!-- 导出数据弹窗 -->
    <el-dialog
      title="导出设置"
      :visible.sync="dialogVisiv"
      width="30%"
      :before-close="handleClose"
    >
      <template>
        <div style="margin: -20px 0 20px">请勾选需要加密的内容：</div>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="1">身份证号</el-checkbox>
          <el-checkbox label="2">手机号</el-checkbox>
        </el-checkbox-group>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiv = false">取 消</el-button>
        <el-button type="primary" @click="examineExportListAll"
          >确定导出</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getUserList,
  updateUserStatus,
  deleteUser,
  setAdminOrBindAdminUser,
  examineExportListAll,
  templateLink,
  commonYonghua,
} from "@/api/userManagement";
export default {
  data() {
    return {
      searchText: "",
      selectedUnit: "",
      tableData: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      recovery: false,
      ids: [],
      status: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "启用",
          value: false,
        },
        {
          label: "禁用",
          value: true,
        },
      ],
      selectedRows: [],
      dialogVisible: false, // 发消息弹出框
      sendMsgData: "", // 发送消息对象的信息
      messageContent: "", // 发送消息的内容
      dialogVisiv: false, //导出数据
      checkList: [],
      fileList: [],
    };
  },
  created() {
    this.getUserList();
  },
  methods: {
    // 获取模版
    async templateLink() {
      let res = await templateLink();
      console.log(res, "1231321");
      let link = document.createElement("a");
      let body = document.querySelector("body");
      let blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }); // res就是接口返回的文件流了
      console.log(blob, "55");
      link.href = window.URL.createObjectURL(blob);
      link.download = `普通用户名单模版`;
      // fix Firefox
      link.style.display = "none";
      body.appendChild(link);
      link.click();
      body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
      this.dialogVisiv = false;
    },
    // 导入模版
    commonYonghua(file, fileList) {
      console.log(file, fileList);
      const isXlsx =
        file.raw.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.raw.type === "application/vnd.ms-excel";
      this.fileList = [];
      if (!isXlsx) {
        this.$message.error("上传文件只能是 Excel 表格!");
        return;
      }
      let formData = new FormData();
      formData.append("file", file.raw);
      let result = commonYonghua(formData);
      result.then((res) => {
        console.log(res, "res");
        if (res.status == "200") {
          this.$message.success("导入成功");
          this.getUserList();
        } else {
          this.$message.error("导入失败");
        }
      });
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    study() {
      this.$router.push("/checkStudyData");
    },
    // 获取普通用户列表
    async getUserList() {
      let param = {
        name: this.searchText, //名称
        status: this.selectedUnit, //状态	true禁用	false禁用
        startNum: this.currentPage, //起始页码
        pageSize: this.pageSize, //每页条数
      };
      console.log(param, "param");
      let res = await getUserList(param);
      if (res.data.result == "success") {
        this.tableData = res.data.list;
        this.total = res.data.size;
      }
      console.log(this.tableData, "res");
    },
    search() {
      // 发送搜索请求
      console.log("search", this.searchText, this.selectedUnit);
      this.getUserList();
    },
    // 导出数据
    DerivedData() {
      this.dialogVisiv = true;
    },
    async examineExportListAll() {
      let encryptIdCard = this.checkList.indexOf("1") != -1 ? true : false;
      let encryptPhone = this.checkList.indexOf("2") != -1 ? true : false;
      console.log(encryptPhone, encryptIdCard, this.checkList);
      let param = {
        name: this.searchText,
        status: this.selectedUnit,
        encryptPhone: encryptPhone,
        encryptIdCard: encryptIdCard,
        ids: this.selectedRows.join(","),
      };
      console.log(param, "param");
      let res = await examineExportListAll(param);
      console.log(res, "121");
      let link = document.createElement("a");
      let body = document.querySelector("body");
      let blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }); // res就是接口返回的文件流了
      console.log(blob, "55");
      link.href = window.URL.createObjectURL(blob);
      link.download = `普通用户名单`;
      // fix Firefox
      link.style.display = "none";
      body.appendChild(link);
      link.click();
      body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
      this.dialogVisiv = false;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    // 重置
    reset() {
      this.searchText = "";
      this.selectedUnit = "";
    },
    handleCurrentChange(currentPage) {
      // 发送分页请求
      console.log("handleCurrentChange", currentPage);
      this.currentPage = currentPage;
      this.search();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search();
    },
    handleSelectionChange(selectedRows) {
      this.selectedRows = selectedRows.map((item) => item.id);
      // this.getUserList();
    },
    async changeStatus(row) {
      // 发送修改状态请求
      console.log("changeStatus", row);
      let res = await updateUserStatus({ id: row.id, recovery: row.recovery });
      if (res.data.result == "success") {
        this.$message({
          type: "success",
          message: res.data.msg,
        });
        this.getUserList();
      }
      console.log(res, "changeStatus");
    },
    sendMessage(row) {
      console.log("sendMessage", row);
      this.sendMsgData = row;

      this.dialogVisible = true;
    },
    async deleteUser(row) {
      console.log("deleteUser", row);
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await deleteUser({
            ids: row.id,
          });
          console.log("deleteUser", res);
          if (res.data.result == "success") {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
            this.getUserList();
          }
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    // 设为管理员
    async setAdmin(row) {
      console.log("setAdmin", row);
      let res = await setAdminOrBindAdminUser({
        id: row.id,
        isAdmin: 1,
      });
      if (res.data.result == "success") {
        this.$message({
          type: "success",
          message: res.data.msg,
        });
        this.getUserList();
      }
      console.log("setAdmin", res);
    },
    addUser() {
      console.log("addUser");
    },
  },
};
</script>
<style scoped>
.el-button {
  margin: 0 10px;
}
</style>
