<template>
  <div class="editHelpDocument">
    <el-form ref="form" :model="form" :rules="rules" label-width="200px">
      <el-form-item label="文档名称" prop="name">
        <el-input
          style="width: 600px"
          v-model="form.name"
          placeholder="请输入文档名称"
          maxlength="50"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="文档类型" prop="type">
        <div>
          <p>说明：</p>
          <p>
            1、文档类型可设置为章节和内容，设置为章节的话，则可在此文档下创建子文档；设置为内容的话，
            则可在此文档中设置内容。
          </p>
          <p>
            2、设置后不可轻易修改类型，修改类型后，本文档中原有的内容或子文档将消失。
          </p>
        </div>
        <el-radio-group v-model="form.type">
          <el-radio :label="1">章节</el-radio>
          <el-radio :label="2">文章</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="form.type == 2" label="文档内容" prop="content">
        <WangEditorVue @getEditorContent="getEditorContent"></WangEditorVue>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit('form')">完成</el-button>
        <el-button @click="onCancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import WangEditorVue from "@/components/WangEditor.vue";
import {
  updateEducationHelpDocument,
  selectEducationHelpDocument,
} from "@/api/helpDocumentManagement";
export default {
  components: { WangEditorVue },
  data() {
    return {
      parentId: "", // 文章id
      form: {
        name: "",
        type: 1,
        content: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入文档名称",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: "请输入文档名称",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.selectEducationHelpDocument();
  },
  methods: {
    selectEducationHelpDocument() {
      selectEducationHelpDocument({ id: this.$route.query.parentId }).then(
        (res) => {
          this.parentId = res.data.id;
        }
      );
    },
    onSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = updateEducationHelpDocument({
            name: this.form.name,
            content: this.form.type == 2 ? this.form.content : "",
            type: this.form.type,
            groupId: this.$route.query.groupId,
            parentId: this.$route.query.parentId
              ? this.$route.query.parentId
              : "",
          });
          res.then((data) => {
            if (data.data.result == "success") {
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.$router.back();
            }
            console.log(res, valid);
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getEditorContent(val) {
      this.form.content = val;
    },
    // 过滤字符串中的标签，保留内容部分
    stripHtmlTags(str) {
      if (str === null || str === "") return false;
      else str = str.toString();
      return str.replace(/<[^>]*>/g, "");
    },
    onCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.editHelpDocument {
  width: 80%;
}
</style>
