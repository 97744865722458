<template>
  <div id="invoiceReviewList">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="3" style="line-height: 50px">
          <el-button type="primary" @click="back">返回上一页</el-button>
        </el-col>
        <el-col
          :span="10"
          style="
            height: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
          "
          v-if="lessonName"
        >
          <div>课程名称：{{ lessonName.name }}</div>
          <div v-if="hourId">课时名称：</div>
        </el-col>
      </el-row>
    </el-card>
    <el-row :gutter="20" style="margin: 20px 0">
      <el-col :span="2">
        <el-button type="primary" @click="newUnitPayPackage">新增</el-button>
      </el-col>
    </el-row>
    <el-table
      ref="multipleTable"
      :data="tableData"
      style="width: 100%"
      border
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" fixed></el-table-column>
      <el-table-column prop="name" label="套餐名称" fixed> </el-table-column>
      <el-table-column prop="inTime" label="创建时间"> </el-table-column>

      <el-table-column prop="totalFee" label="价格（人民币：元）">
      </el-table-column>
      <el-table-column prop="redemptionCodeNumber" label="兑换码数量">
      </el-table-column>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <el-button type="primary" @click="editPackage(scope.row)"
            >编辑</el-button
          >
          <el-button type="danger" @click="deletePackage(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalItems"
    ></el-pagination>
    <el-dialog
      width="33%"
      :title="title"
      center
      :visible.sync="dialogFormVisible"
    >
      <el-form
        ref="formRules"
        label-width="150px"
        :model="form"
        :rules="formRules"
      >
        <el-form-item label="套餐金额" prop="packageMoney">
          <el-input-number
            :min="0.01"
            :decimal-length="1"
            v-model="form.packageMoney"
          ></el-input-number
          ><span>&nbsp;&nbsp;元：人民币</span>
        </el-form-item>
        <el-form-item label="兑换码数量" prop="codeNumber">
          <el-input-number
            @change="toInteger('codeNumber')"
            :min="1"
            :step="1"
            v-model.number="form.codeNumber"
          ></el-input-number
          ><span>&nbsp;&nbsp;个</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('formRules')"
          >完 成</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listLessonPackageBack,
  saveLessonPackage,
  deleteLessonPackage,
  updateLessonPackage,
} from "@/api/unitPayPackageManagement";
import { msToDate } from "@/plugins/msToDate";

export default {
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      currentPage: 1,
      pageSize: 10,
      totalItems: 0,
      lessonName: "",
      title: "新建单位支付套餐",
      form: {
        id: "",
        name: "",
        orderNumber: 1,
        packageMoney: 1,
        codeNumber: 1,
      },
      dialogFormVisible: false,
      formRules: {
        packageMoney: [
          {
            required: true,
          },
        ],
        codeNumber: [
          {
            required: true,
          },
        ],
      },
    };
  },
  created() {
    this.lessonId = this.$route.query.lessonId
      ? this.$route.query.lessonId
      : "";
    this.hourId = this.$route.query.hourId ? this.$route.query.hourId : "";
    this.listLessonPackageBack();
  },
  methods: {
    async listLessonPackageBack() {
      let res = await listLessonPackageBack({
        startNum: this.currentPage,
        pageSize: this.pageSize,
        lessonId: this.lessonId,
        hourId: this.hourId,
      });
      if (res.data.list) {
        this.tableData = res.data.list;
        this.lessonName = res.data.lessonName;
        this.tableData.forEach((item) => (item.inTime = msToDate(item.inTime)));
        this.totalItems = res.data.listSize;
      }
    },
    newUnitPayPackage() {
      this.dialogFormVisible = true;
      this.title = "新建单位支付套餐";
    },
    editPackage(row) {
      console.log(row);
      this.dialogFormVisible = true;
      this.title = "修改单位支付套餐";
      this.form = {
        id: row.id,
        name: row.name,
        orderNumber: row.orderNumber,
        codeNumber: row.redemptionCodeNumber,
        packageMoney: row.totalFee,
      };
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.title == "新建单位支付套餐") {
            let res = await saveLessonPackage({
              redemptionCodeNumber: this.form.codeNumber,
              totalFee: this.form.packageMoney,
              lessonId: this.lessonId,
              hourId: this.hourId,
            });
            if (res.data.result == "success") {
              this.dialogFormVisible = false;
              this.$message.success("保存成功");
              this.listLessonPackageBack();
            }
          } else {
            let res = await updateLessonPackage({
              id: this.form.id,
              name: this.form.name,
              orderNumber: this.form.orderNumber,
              redemptionCodeNumber: this.form.codeNumber,
              totalFee: this.form.packageMoney,
              lessonId: this.lessonId,
              hourId: this.hourId,
            });
            if (res.data.result == "success") {
              this.dialogFormVisible = false;
              this.$message.success("保存成功");
              this.listLessonPackageBack();
            }
          }
          this.form = {
            id: "",
            name: "",
            orderNumber: 1,
            codeNumber: 1,
            packageMoney: 1,
          };
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    deletePackage(row) {
      this.$confirm("确认删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await deleteLessonPackage({ ids: row.id });
        if (res.data.result === "success") {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.listLessonPackageBack();
        }
      });
    },
    back() {
      this.$router.back();
    },
    toInteger(key) {
      let reg = /^[0-9]+$/;
      if (!reg.test(this.form[key])) {
        this.$message.warning("只能输入整数");
        // 用以在dom渲染挂载后重新触发dom渲染挂载
        this.$nextTick(() => {
          this.ruleForm[key] = parseInt(this.ruleForm[key]);
        });
      }
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.listLessonPackageBack();
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.listLessonPackageBack();
    },
    handleSelectionChange(val) {
      console.log(val, "handleSelectionChange");
      val.forEach((item) => this.multipleSelection.push(item.id));
    },
  },
  mounted() {
    // Fetch address options for cascader
    // Fetch initial data for table
  },
};
</script>

<style scoped>
.ml-20 {
  margin-left: 20px;
}
</style>
