import axios from "../plugins/axios";
// 考试
// 获取考试列表
export const getExamList = (param) =>
  axios.get(
    "/course/exam/getExamList?pageSize=" +
      param.pageSize +
      "&startNum=" +
      param.startNum +
      "&name=" +
      param.name
  );
//手动刷新列表  ManualUpdate
export const ManualUpdate = () => axios.get("/course/exam/syncExaimInfo");

// 考试设置
export const saveTrainingExam = (param) =>
  axios.post("/course/platformTrainingExam/saveTrainingExam", param);
// 考试设置详情
export const platformTrainingExamDetail = (param) =>
  axios.get("/course/platformTrainingExam/detail?traingId=" + param.traingId);
//更新成绩
export const syncExamScore = (param) =>
  axios.get("/course/exam/syncExamScore?pid=" + param.pid);
