import axios from "../plugins/axios";

// 获取轮播列表 type-0轮播图 1公告
export const getSlidelList = (param) =>
  axios.get(
    "/course/slideshow/getSlidelList?name=" +
      param.name +
      "&startNum=" +
      param.startNum +
      "&pageSize=" +
      param.pageSize +
      "&type=" +
      param.type
  );

// 保存轮播图
/**
 *
 * @param {*} param
 * id-修改时传
 * recovery -启用、禁用
 * title-名称
 * image-图片
 * link-链接
 * type-0轮播图 1公告
 * @returns
 */
export const updateSlideshow = (param) =>
  axios.post("/course/slideshow/updateSlideshow", param);

// 轮播图上移下移
export const moveSlideshow = (param) =>
  axios.get(
    "/course/slideshow/moveSlideshow?id1=" + param.id1 + "&id2=" + param.id2
  );

// 轮播图显示/隐藏 id recovery-true 禁用 false启用 type-0轮播图 1公告
export const updateSidershowStatus = (param) =>
  axios.post("/course/slideshow/updateSidershowStatus", param);

// 轮播图查看详情
export const findSlideshowById = (param) =>
  axios.get("/course/slideshow/findSlideshowById?id=" + param.id);
// 轮播图删除
export const slideshowDelete = (param) =>
  axios.get("/course/slideshow/delete?ids=" + param.id);

//岗位管理
export const PositionManagementList = (param) =>
  axios.get(
    "/course/platformTraining/getAddPlatformPositionListByPage?name=" +
      param.name +
      "&page=" +
      param.page +
      "&size=" +
      param.size
  );
//新增岗位
export const NewPositionName = (param) =>
  axios.post("/course/platformTraining/savePosition", param);
//修改岗位
export const setPositionName = (param) =>
  axios.post("/course/platformTraining/updatePosition", param);
//删除岗位
export const deletesetPositionManagement = (param) =>
  axios.post("/course/platformTraining/deletePosition", param);
