<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span> 课程内容分类管理</span>
      </div>
      <div class="text item" style="font-size: 14px">对课程类别进行管理。</div>
    </el-card>
    <el-input
      v-model="searchText"
      placeholder="请输入课程类别名称/关键字"
      style="width: 400px; margin-top: 20px"
      clearable
    ></el-input>
    <el-select
      style="margin: 0 30px"
      v-model="selecteValue"
      placeholder="请选择"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <el-button type="primary" @click="getEducationCourseCategoryList"
      >搜索</el-button
    >
    <!-- <el-button @click="reset">重置</el-button><br /> -->
    <div style="margin: 20px 0">
      <el-button type="primary" @click="skipNewClasses"
        ><i class="el-icon-circle-plus"></i> 新建</el-button
      >
      <!-- <el-button>批量删除</el-button> -->
    </div>

    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column type="selection"></el-table-column>
      <el-table-column prop="id" label="ID" width="100"></el-table-column>
      <el-table-column prop="courseName" label="课程类别名称"></el-table-column>
      <el-table-column label="子分类">
        <template slot-scope="scope">
          <el-button type="text" @click="showSubclassificationList(scope.row)"
            >查看详情</el-button
          >
        </template>
      </el-table-column>
      <el-table-column prop="inTime" label="创建时间"></el-table-column>
      <el-table-column prop="isOpen" label="状态">
        <template slot-scope="scope">
          <span>{{ scope.row.isOpen == 1 ? "公开" : "隐藏" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click="handleEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-dialog title="子分类" :visible.sync="dialogTableVisible">
      <div>课程类别：{{ currentCourse ? currentCourse.courseName : "" }}</div>
      <template>
        <el-table
          :data="courseSubList"
          border
          style="margin-top: 20px"
          max-height="500px"
        >
          <el-table-column
            label="序号"
            type="index"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column label="子分类" prop="courseName" align="center">
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChangeChildren"
          @current-change="handleCurrentChangeChildren"
          :current-page="currentPageChildren"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalChildren"
        >
        </el-pagination>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  getEducationCourseCategoryList,
  deleteCourseCategory,
  getChildrenCourseCategoryList,
} from "@/api/courseManage";

export default {
  data() {
    return {
      searchText: "",
      selecteValue: "",
      dialogTableVisible: false,
      courseSubList: [],
      tableData: [],
      currentCourse: "",
      options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1",
          label: "公开",
        },
        {
          value: "0",
          label: "隐藏",
        },
      ],
      currentPage: 1,
      pageSize: 10,
      total: 10,
      currentPageChildren: 1,
      pageSizeChildren: 10,
      totalChildren: 10,
    };
  },
  created() {
    this.getEducationCourseCategoryList();
  },
  methods: {
    renderTime(date) {
      let dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
    },
    // 获取课程类别列表
    async getEducationCourseCategoryList() {
      let res = await getEducationCourseCategoryList({
        name: this.searchText, //名称
        status: this.selecteValue, //状态	true禁用	false禁用
        startNum: this.currentPage, //起始页码
        pageSize: this.pageSize, //每页条数
      });
      if (res.data.result == "success") {
        res.data.list.forEach((element) => {
          element.inTime = this.renderTime(element.inTime);
        });
        this.tableData = res.data.list;
        this.total = res.data.listSize;
      }
    },
    // 重置
    reset() {
      this.searchText = "";
      this.selecteValue = "";
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.getEducationCourseCategoryList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getEducationCourseCategoryList();
    },
    handleSizeChangeChildren(val) {
      console.log(`每页 ${val} 条`);
      this.pageSizeChildren = val;
      this.getChildrenCourseCategoryList();
    },
    handleCurrentChangeChildren(val) {
      console.log(`当前页: ${val}`);
      this.currentPageChildren = val;
      this.getChildrenCourseCategoryList();
    },
    showSubclassificationList(row) {
      console.log(row, "row");
      this.dialogTableVisible = true;
      this.currentCourse = row;
      this.getChildrenCourseCategoryList();
    },
    async getChildrenCourseCategoryList() {
      let res = await getChildrenCourseCategoryList({
        categoryId: this.currentCourse.id,
        startNum: this.currentPageChildren,
        pageSize: this.pageSizeChildren,
      });
      if (res.data.result == "success") {
        this.courseSubList = res.data.list;
        this.totalChildren = res.data.listSize;
      }
    },
    // 编辑课程类别
    handleEdit(row) {
      console.log(row);
      this.$router.push({ path: "/editClasses" });
      sessionStorage.setItem("unitData", JSON.stringify(row));
    },
    handleDelete(index, row) {
      this.tableData.splice(index, row);
      this.$confirm("此操作将永久删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await deleteCourseCategory({
            ids: row.id,
          });
          if (res.data.result == "success") {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
          }
          this.getEducationCourseCategoryList();
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    // 新建课程类别
    skipNewClasses() {
      this.$router.push({ path: "/newClasses" });
    },
  },
};
</script>
