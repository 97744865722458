<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>培训机构管理</span>
      </div>
      <div class="text item" style="font-size: 14px">对培训机构进行管理。</div>
    </el-card>
    <el-row style="margin: 20px 0">
      <el-col :span="6">
        <el-input
          v-model="searchText"
          placeholder="请输入培训机构/关键字"
          clearable
        ></el-input>
      </el-col>
      <el-col :span="6" style="text-align: center">
        <el-select v-model="selectedOption" placeholder="请选择搜索条件">
          <el-option label="全部" value=""></el-option>
          <el-option label="启用" value="false"></el-option>
          <el-option label="禁用" value="true"></el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="search">搜索</el-button>
        <!-- <el-button @click="reset">重置</el-button> -->
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <span class="ml-20">省：</span>
        <el-select
          v-model="provinceId"
          placeholder="请选择省"
          clearable
          @change="getFindCityList(provinceId)"
          @clear="clearProvId"
          :disabled="
            $store.state.userPower == 3 ||
            $store.state.userPower == 4 ||
            $store.state.userPower == 5 ||
            $store.state.userPower == 6
          "
        >
          <el-option
            v-for="item in provList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <span class="ml-20">市：</span>
        <el-select
          v-model="cityId"
          placeholder="请选择市"
          @change="getFindDistrictList(cityId)"
          @clear="clearCityId"
          clearable
          :disabled="
            $store.state.userPower == 4 ||
            $store.state.userPower == 5 ||
            $store.state.userPower == 6
          "
        >
          <el-option
            v-for="item in cityList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <span class="ml-20">区：</span>
        <el-select
          v-model="regionId"
          placeholder="请选择区"
          @change="getFindStreetList(regionId)"
          @clear="clearRegionId"
          clearable
          :disabled="$store.state.userPower == 5 || $store.state.userPower == 6"
        >
          <el-option
            v-for="item in regionList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <span class="ml-20">街道：</span>
        <el-select
          v-model="streetId"
          placeholder="请选择街道"
          clearable
          :disabled="$store.state.userPower == 6"
        >
          <el-option
            v-for="item in streetList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row style="margin: 20px 0">
      <el-col :span="3">
        <el-button type="primary" @click="newTrainingInstitution"
          >新增培训机构</el-button
        >
      </el-col>
    </el-row>
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column type="selection"></el-table-column>
      <el-table-column prop="id" label="id"></el-table-column>
      <el-table-column prop="name" label="培训机构名称"></el-table-column>
      <el-table-column label="所在地">
        <template slot-scope="scope">
          <span v-if="scope.row.power == 1">{{ scope.row.countyName }}</span>
          <span v-else>
            {{ scope.row.provinceName ? scope.row.provinceName : "" }}
            {{ scope.row.cityName ? scope.row.cityName : "" }}
            {{ scope.row.regionName ? scope.row.regionName : "" }}
            {{ scope.row.streetName ? scope.row.streetName : "" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-radio-group
            v-model="scope.row.recovery"
            @change="changeStatus(scope.row)"
          >
            <el-radio :label="false">启用</el-radio>
            <el-radio :label="true">禁用</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="500">
        <template slot-scope="scope">
          <el-button type="primary" @click="editItem(scope.row)"
            >编辑</el-button
          >
          <el-button type="primary" @click="showDetail(scope.row)"
            >查看成员</el-button
          >
          <el-button type="primary" @click="AvailableCourses(scope.row)"
            >设置可查看的课程</el-button
          >
          <el-button type="primary" @click="availableTraining(scope.row)"
            >设置可查看的培训</el-button
          >
          <el-button type="danger" @click="deleteItem(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  getEducationTrainingInstitutionsList,
  updateTraininginstitutionsStatus,
  deleteTraininginstitutions,
} from "@/api/trainingInstitution";
import {
  findProviceList,
  findCityList,
  findDistrictList,
  findStreetList,
} from "@/api/common";
export default {
  data() {
    return {
      searchText: "",
      selectedOption: "",
      provinceId: "",
      cityId: "",
      regionId: "",
      streetId: "",
      tableData: [],
      provList: [], //省级
      cityList: [], //市级列表
      regionList: [], // 区级
      streetList: [], // 街道
      currentPage: 1,
      pageSize: 10,
      total: 0,
    };
  },
  created() {
    this.getEducationTrainingInstitutionsList();
    this.getFindProviceList();
  },
  watch: {},
  methods: {
    // 获取培训机构管理列表
    async getEducationTrainingInstitutionsList() {
      let res = await getEducationTrainingInstitutionsList({
        name: this.searchText, //名称
        status: this.selectedOption, //状态	true禁用	false禁用
        startNum: this.currentPage, //起始页码
        pageSize: this.pageSize, //每页条数
        provinceId: this.provinceId,
        cityId: this.cityId,
        regionId: this.regionId,
        streetId: this.streetId,
      });
      if (res.data.result == "success") {
        this.tableData = res.data.educationTrainingInstitutionsList;
        this.total = res.data.size;
      }
    },
    // 获取省
    async getFindProviceList() {
      let res = await findProviceList();
      if (res.data.result == "success") {
        this.provList = res.data.msg;
      }
    },
    // 清空省触发
    clearProvId() {
      this.cityId = "";
      this.regionId = "";
      this.streetId = "";
      this.cityList = [];
      this.regionList = [];
      this.streetList = [];
    },
    // 清空市触发
    clearCityId() {
      this.regionId = "";
      this.streetId = "";
      this.regionList = [];
      this.streetList = [];
    },
    // 清空区触发
    clearRegionId() {
      this.streetId = "";
      this.streetList = [];
    },
    // 获取市
    async getFindCityList(id) {
      let res = await findCityList({ id: id });
      if (res.data.result == "success") {
        this.cityList = res.data.msg;
      }
    },
    // 获取区
    async getFindDistrictList(id) {
      let res = await findDistrictList({ id: id });
      if (res.data.result == "success") {
        this.regionList = res.data.msg;
      }
    },
    // 获取街道
    async getFindStreetList(id) {
      let res = await findStreetList({ id: id });
      if (res.data.result == "success") {
        this.streetList = res.data.msg;
      }
    },
    // 添加培训机构
    newTrainingInstitution() {
      this.$router.push({ path: "/newTrainingInstitution" });
    },
    search() {
      // 发送搜索请求
      this.getEducationTrainingInstitutionsList();
    },
    // 重置
    reset() {
      this.searchText = "";
      this.selectedOption = "";
      // this.search();
    },
    // 启用禁用状态
    async changeStatus(row) {
      console.log(row, "changeStatus");
      let res = await updateTraininginstitutionsStatus({
        id: row.id,
        recovery: row.recovery,
      });
      if (res.data.msg == "success") {
        this.$message({
          type: "success",
          message: "修改状态成功",
        });
        this.getEducationTrainingInstitutionsList();
      }
    },
    // 跳转培训机构成员页
    showDetail(row) {
      // 发送获取详情请求
      this.$router.push({
        path: "/trainingInstitutionMemberList",
        query: { id: row.id },
      });
      sessionStorage.setItem("governmentData", JSON.stringify(row));
    },
    // 设置可查看的课程
    AvailableCourses(row) {
      console.log(row, "row");
      this.$router.push({
        path: "/AvailableCoursesList",
        query: { id: row.id },
      });
      sessionStorage.setItem("governmentData", JSON.stringify(row));
    },
    availableTraining(row) {
      console.log(row, "row");
      this.$router.push({
        path: "/AvailableTrainingList",
        query: { id: row.id },
      });
      sessionStorage.setItem("governmentData", JSON.stringify(row));
    },
    // 编辑弹出框
    editItem(row) {
      this.$router.push({
        path: "/editTrainingInstitution",
        query: { id: row.id },
      });
    },
    deleteItem(row) {
      // 发送删除请求
      console.log("deleteItem", row);
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await deleteTraininginstitutions({
            id: row.id,
          });
          if (res.data.result == "success") {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.getEducationTrainingInstitutionsList();
          }
          console.log("deleteUser", res);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.search();
    },
  },
};
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  border: 1px dashed #d9d9d9;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  border: 1px dashed #d9d9d9;
  width: 178px;
  height: 178px;
  display: block;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
</style>
