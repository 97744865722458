<template>
  <el-card class="document-content">
    <template>
      <el-button type="" @click="navigationNewDocument">添加新文档</el-button>
      <el-scrollbar style="margin-top: 30px"
        ><el-tree
          :data="data"
          node-key="id"
          :props="defaultProps"
          default-expand-all
          :expand-on-click-node="false"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span
              >{{ data.name }}{{ data.type == 1 ? "(章节)" : "(内容)" }}</span
            >
            <span style="margin-left: 100px">
              <el-button
                v-if="data.type == 1"
                type="text"
                size="mini"
                @click="() => append(data)"
              >
                添加子文档
              </el-button>
              <el-button type="text" size="mini" @click="() => edit(data)">
                编辑
              </el-button>
              <el-button
                type="text"
                size="mini"
                @click="() => remove(node, data)"
              >
                删除
              </el-button>
            </span>
          </span>
        </el-tree></el-scrollbar
      >
    </template>
  </el-card>
</template>

<script>
import {
  findAllDocumentList,
  deleteEducationHelpDocument,
} from "@/api/helpDocumentManagement";
export default {
  props: ["GroupId"],
  data() {
    return {
      data: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
    };
  },
  watch: {
    GroupId(val) {
      this.findAllDocumentList(val);
    },
  },
  methods: {
    // 获取分组列表
    async findAllDocumentList(val) {
      let res = await findAllDocumentList({
        groupId: val,
      });
      if (res.data.result == "success") {
        this.data = res.data.msg;
        console.log(this.data);
      }
    },
    // 跳转添加新文档
    navigationNewDocument() {
      this.$router.push({
        path: "/newHelpDocument",
        query: {
          groupId: this.GroupId,
        },
      });
    },
    // 新增子文档
    append(node) {
      this.$router.push({
        path: "/newHelpDocument",
        query: {
          groupId: this.GroupId,
          parentId: node.id,
        },
      });
    },
    // 编辑文档
    edit(node) {
      this.$router.push({
        path: "/editHelpDocument",
        query: {
          groupId: this.GroupId,
          parentId: node.id,
        },
      });
    },
    remove(node, data) {
      console.log(node, data);
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await deleteEducationHelpDocument({
            id: data.id,
          });
          if (res.data.result == "success") {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.findAllDocumentList();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style scoped>
.document-content {
  width: 100%;
  height: 100%;
}
</style>
