<template>
  <div class="newSlideshow">
    <el-form ref="form" :model="form" :rules="rules" label-width="200px">
      <el-form-item label="隐私政策名称" prop="name">
        <el-input
          style="width: 600px"
          v-model="form.name"
          placeholder="请输入隐私政策名称"
          maxlength="50"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="隐私政策内容" prop="link">
        <WangEditorVue
          :content="content"
          @getEditorContent="getEditorContent"
        ></WangEditorVue>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit('form')">完成</el-button>
        <el-button @click="onCancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import WangEditorVue from "@/components/WangEditor.vue";
import { selectAgreement, updateAgreement } from "@/api/agreementManagement";
export default {
  components: { WangEditorVue },
  data() {
    return {
      form: {
        name: "",
        link: "",
      },
      content: "",
      rules: {
        name: [
          {
            required: true,
            message: "请输入隐私政策名称",
            trigger: "blur",
          },
        ],
        link: [
          {
            required: true,
            message: "请输入隐私政策内容",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.selectAgreement();
  },
  methods: {
    // 查看详情
    async selectAgreement() {
      let res = await selectAgreement({
        id: this.$route.query.id,
      });
      if (res.data.msg == "success") {
        this.form = {
          name: res.data.data.name,
          link: res.data.data.content,
        };
        this.content = res.data.data.content;
      }
    },
    onSubmit(formName) {
      if (!this.stripHtmlTags(this.form.link)) {
        this.$message({
          message: "请输入隐私政策内容",
          type: "warning",
        });
        return false;
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = updateAgreement({
            id: this.$route.query.id,
            name: this.form.name,
            content: this.form.link,
            type: 1,
          });
          res.then((data) => {
            if (data.data.result == "success") {
              this.$message({
                message: data.data.msg,
                type: "success",
              });
              this.$router.back();
            }
            console.log(res, valid);
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getEditorContent(val) {
      console.log(this.stripHtmlTags(val), "val", val);
      this.form.link = val;
    },
    // 过滤字符串中的标签，保留内容部分
    stripHtmlTags(str) {
      if (str === null || str === "") return false;
      else str = str.toString();
      return str.replace(/<[^>]*>/g, "");
    },
    onCancel() {
      console.log("cancel!");
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.newSlideshow {
  width: 80%;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  border: 1px dashed #999;
  border-radius: 10px;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  border: 1px dashed #999;
  border-radius: 10px;
  width: 178px;
  height: 178px;
  display: block;
}
</style>
