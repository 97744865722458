<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span> 考试列表</span>
      </div>
      <div class="text item" style="font-size: 14px">
        对从考试系统当中同步过来的考试数据进行管理的模块。
      </div>
    </el-card>
    <el-row style="margin: 20px 0">
      <el-col :span="8">
        <span>搜索内容：</span>
        <el-input
          style="width: 400px"
          v-model="searchText"
          clearable
          placeholder="请输入考试GUID/名称"
          @keyup.enter.native="search"
        />
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="search">搜索</el-button>
        <!-- <el-button type="" @click="reset">重置</el-button> -->
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="ManualUpdate">手动更新</el-button>
        <!-- <el-button type="" @click="reset">重置</el-button> -->
      </el-col>
    </el-row>
    <el-table :data="tableData" border>
      <el-table-column prop="pid" label="GUID" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.pid }}</span>
          <el-button type="text" @click="copyText(scope.row)">复制</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="pname" label="考试名称" align="center" />
      <el-table-column label="考试起止时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.time1 }}</span
          >——
          <span>{{ scope.row.time2 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="GradeScore(scope.row)"
            >更新成绩分数</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="total"
      :page-size="pageSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
    />
  </div>
</template>

<script>
import { getExamList, ManualUpdate, syncExamScore } from "@/api/examinationApi";
export default {
  data() {
    return {
      searchText: "",
      tableData: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      recovery: false,
      ids: [],
      status: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "启用",
          value: false,
        },
        {
          label: "禁用",
          value: true,
        },
      ],
      selectedRows: [],
      dialogVisible: false, // 发消息弹出框
      sendMsgData: "", // 发送消息对象的信息
      messageContent: "", // 发送消息的内容
    };
  },
  created() {
    this.getExamList();
  },
  methods: {
    // 获取普通用户列表
    async getExamList() {
      let param = {
        name: this.searchText, //名称
        startNum: this.currentPage, //起始页码
        pageSize: this.pageSize, //每页条数
      };
      let res = await getExamList(param);
      if (res.data.result == "success") {
        this.tableData = res.data.examList;
        this.total = res.data.size;
      }
    },
    async ManualUpdate() {
      let res = await ManualUpdate();
      if ("同步成功!" == res.data.msg) {
        console.log(res, "=>res");
        this.$message({
          type: "success",
          message: res.data.msg,
        });
      }
    },
    //复制地址的操作
    copyText(row) {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = row.pid; // 将需要复制的文本赋值到创建的input输入框中，this.ruleForm.url这个是我要复制的内容
      document.body.appendChild(input); // 将输入框暂时创建到实例里面
      input.select(); // 选中输入框中的内容
      document.execCommand("Copy"); // 执行复制操作
      document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    async GradeScore(row) {
      let param = {
        pid: row.pid,
      };
      let res = await syncExamScore(param);
      console.log(res, "ssss");
      if ("同步考试成绩成功" == res.data.msg) {
        console.log(res, "=>res");
        this.$message({
          type: "success",
          message: res.data.msg,
        });
      }
    },

    search() {
      // 发送搜索请求
      this.getExamList();
    },
    // 重置
    reset() {
      this.searchText = "";
    },
    handleCurrentChange(currentPage) {
      // 发送分页请求
      console.log("handleCurrentChange", currentPage);
      this.currentPage = currentPage;
      this.search();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search();
    },
  },
};
</script>
<style scoped>
.el-button {
  margin: 0 10px;
}
</style>
