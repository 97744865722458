<template>
  <div class="studyData">
    <div class="card-container">
      <el-card class="card-item">
        <div slot="header" class="card-header">
          <span>访问量</span>
          <span
            style="
              display: inline-block;
              width: 30px;
              height: 20px;
              background-color: #f6ffed;
              color: #8bd963;
              border: 1px solid #cbf1ae;
              border-radius: 3px;
              font-size: 10px;
              text-align: center;
              line-height: 20px;
            "
            >日</span
          >
        </div>
        <div class="card-content">
          <div class="card-number">0</div>
          <div class="card-compare f-s-14">
            日同比 <span class="compare-icon">0%</span>
            <i class="el-icon-caret-top red"></i>
            &nbsp;&nbsp;&nbsp;&nbsp; 周同比 <span class="compare-icon">0%</span>
            <i class="el-icon-caret-bottom green"></i>
          </div>
        </div>
        <div
          class="card-total"
          style="
            display: flex;
            justify-content: space-between;
            border-top: 1px solid #ebeef5;
            padding: 10px 0;
            margin-top: 15px;
          "
        >
          <span>总访问量</span>
          <span>0</span>
        </div>
      </el-card>
      <el-card class="card-item">
        <div slot="header" class="card-header">
          <span>销售额</span>
          <span
            style="
              display: inline-block;
              width: 30px;
              height: 20px;
              background-color: #e6f7ff;
              color: #1d92ff;
              border: 1px solid #a4dcff;
              border-radius: 3px;
              font-size: 10px;
              text-align: center;
              line-height: 20px;
            "
            >月</span
          >
        </div>
        <div class="card-content">
          <div class="card-number">￥0</div>
          <el-progress
            class="card-progress"
            :percentage="0"
            color="#2d8cf0"
          ></el-progress>
        </div>
        <div
          class="card-total"
          style="
            display: flex;
            justify-content: space-between;
            border-top: 1px solid #ebeef5;
            padding: 10px 0;
            margin-top: 20px;
          "
        >
          <span>总订单额</span>
          <span>0</span>
        </div>
      </el-card>
      <el-card class="card-item">
        <div slot="header" class="card-header">
          <span>订单量</span>
          <span
            style="
              display: inline-block;
              width: 30px;
              height: 20px;
              background-color: #fff1f0;
              color: #f5252f;
              border: 1px solid #ffa8a3;
              border-radius: 3px;
              font-size: 10px;
              text-align: center;
              line-height: 20px;
            "
            >周</span
          >
        </div>
        <div class="card-content" style="margin-top: 0">
          <div
            ref="orderVolume"
            :style="{ width: '350px', height: '80px' }"
          ></div>
        </div>
        <div
          class="card-total"
          style="
            display: flex;
            justify-content: space-between;
            border-top: 1px solid #ebeef5;
            padding: 10px 0;
            margin-top: 0px;
          "
        >
          <span>转化率</span>
          <span>0%</span>
        </div>
      </el-card>
      <el-card class="card-item">
        <div slot="header" class="card-header">
          <span>新增用户</span>
          <i class="el-icon-question" style="color: #adb0bc"></i>
        </div>
        <div class="card-content">
          <div class="card-number">2位</div>
          <div class="card-avatar-list">
            <el-avatar
              class="card-avatar"
              src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
            ></el-avatar>
            <el-avatar
              class="card-avatar"
              src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
            ></el-avatar>
            <el-avatar
              class="card-avatar"
              src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
            ></el-avatar>
            <el-avatar
              class="card-avatar"
              src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
            ></el-avatar>
            <span
              style="
                display: inline-block;
                width: 25px;
                height: 25px;
                background-color: #fde3cf;
                border-radius: 50%;
                color: #f68a36;
                font-size: 10px;
                line-height: 25px;
                text-align: center;
              "
              >+2</span
            >
          </div>
        </div>
        <div
          class="card-total"
          style="
            display: flex;
            justify-content: space-between;
            border-top: 1px solid #ebeef5;
            padding: 10px 0;
            margin-top: 15px;
          "
        >
          <span>总用户</span>
          <span>2人</span>
        </div>
      </el-card>
    </div>
    <div class="card-main">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span
            ><i
              class="el-icon-picture-outline"
              style="
                padding: 6px;
                background-color: #e6f7ff;
                color: #6ebbfe;
                border-radius: 50%;
                font-size: 14px;
              "
            ></i
            >&nbsp;&nbsp;访问量</span
          >
          <div class="clearfix-right">
            <div style="color: #509ff3">今日</div>
            <div>本月</div>
            <div>全年</div>
            <el-date-picker
              v-model="value1"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="box-card-main">
          <div ref="visitorVolume" class="box-card-main-left"></div>
          <div class="box-card-main-right">
            <div class="title">访问量排行</div>
            <div
              class="visitorVolumeList"
              v-for="item in arae"
              :key="item.value"
            >
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  height: 30px;
                "
              >
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <span
                    style="
                      padding: 5px 10px;
                      background-color: #e6f7ff;
                      border-radius: 50%;
                      color: #97c3f9;
                      margin-right: 15px;
                    "
                    >{{ item.value }}</span
                  >
                  <span>{{ item.name }}</span>
                </div>
                <div>0</div>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value1: "",
      chartOptions: {
        // Echarts chart options here
        title: {
          text: "0",
          left: "left",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          show: false,
        },
        yAxis: {
          type: "value",
          show: false,
        },
        series: [
          {
            data: [1, 6, 5, 7, 9, 20, 1],
            type: "line",
            areaStyle: {},
            symbol: "none",
          },
        ],
      },
      visitorVolumeOptions: {
        title: {
          text: "访问量趋势",
          left: "left",
        },
        xAxis: {
          type: "category",
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [120, 200, 150, 80, 70, 110, 130, 666, 456, 234, 123, 78],
            type: "bar",
          },
        ],
      },
      arae: [
        { name: "和平区", value: 1 },
        { name: "河西区", value: 2 },
        { name: "南开区", value: 3 },
        { name: "红桥区", value: 4 },
        { name: "河北区", value: 5 },
        { name: "河东区", value: 6 },
        { name: "西青区", value: 7 },
      ],
    };
  },
  mounted() {
    this.orderVolumeChart();
    this.visitorVolumeChart();
  },
  methods: {
    orderVolumeChart() {
      let myChart = this.$echarts.init(this.$refs.orderVolume);
      myChart.setOption(this.chartOptions);
    },
    visitorVolumeChart() {
      let visitor = this.$echarts.init(this.$refs.visitorVolume);
      visitor.setOption(this.visitorVolumeOptions);
    },
  },
};
</script>

<style scoped>
.card-container {
  display: flex;
  justify-content: space-between;
}

.card-item {
  width: 24%;
  height: 200px;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-number {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.compare-icon {
  margin-right: 5px;
}

.red {
  color: red;
}

.green {
  color: green;
}

.card-total {
  font-size: 14px;
  color: #999;
  margin-top: 10px;
}

.card-progress {
  margin-top: 10px;
}

.card-chart {
  height: 200px;
  margin-top: 10px;
}

.chart {
  width: 100%;
  height: 100%;
}

.card-avatar-list {
  display: flex;
  margin-top: 10px;
}

.card-avatar {
  margin-right: -5px;
  width: 25px;
  height: 25px;
}
.f-s-14 {
  font-size: 14px;
}
.card-main {
  margin-top: 20px;
}
.clearfix {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clearfix-right {
  width: 600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.box-card-main {
  display: flex;
}
.box-card-main-left {
  width: 75%;
  height: 500px;
}
.box-card-main-right {
  width: 25%;
}
.box-card-main-right .title {
  font-weight: 700;
}
.visitorVolumeList {
  margin-top: 30px;
}
</style>
