<template>
  <div class="headerContent">
    <div class="pageSetup">
      <!-- 导航菜单展开收起 -->
      <!-- <i
        class="el-icon-s-fold"
        style="color: #666666; font-size: 24px; cursor: pointer"
        @click="setNavMenuToggle"
      ></i> -->
      <!-- 刷新 -->
      <i
        class="el-icon-refresh"
        style="color: #666666; font-size: 24px; margin: 0 20px; cursor: pointer"
        @click="refresh"
      ></i>

      <!--面包屑-->
      <el-breadcrumb class="breadcrumb" separator="/">
        <!--默认给一个首页的跳转，如果不需要可以去掉-->
        <!-- <el-breadcrumb-item :to="{ path: '/dashboard' }"
          >首页</el-breadcrumb-item
        > -->
        <!--面包屑列表-->
        <el-breadcrumb-item
          v-for="(item, index) in breadList"
          :key="index"
          @click.native="breadcrumbClick(item)"
          style="cursor: pointer"
        >
          {{ item.name }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="userSetup">
      <el-dropdown>
        <el-avatar
          src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
          shape="square"
          style="vertical-align: middle; margin-right: 8px"
        ></el-avatar>
        <span style="color: #2d8cf0">你好！{{ userInfo.name }}</span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>姓名：{{ userInfo.name }}</el-dropdown-item>
          <el-dropdown-item>账号：{{ userInfo.account }}</el-dropdown-item>
          <el-dropdown-item>角色：{{ userRoleName }}</el-dropdown-item>
          <el-dropdown-item divided>
            <i class="el-icon-s-tools"></i>
            <span class="dropdownSpan" @click="updatePassword">修改密码</span>
          </el-dropdown-item>
          <el-dropdown-item>
            <i class="el-icon-s-promotion"></i>
            <span class="dropdownSpan" @click="logout">退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog
      class="updatePassword"
      title="修改密码"
      :visible.sync="dialogFormVisible"
      style="width: 50%; margin: auto"
    >
      <el-form
        :model="form"
        :rules="formRules"
        ref="ruleForm"
        label-width="100px"
      >
        <el-form-item label="原密码" prop="originalPassword">
          <el-input
            v-model="form.originalPassword"
            autocomplete="no"
            placeholder="请输入原密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            v-model="form.newPassword"
            autocomplete="no"
            placeholder="请输入新密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="confirmNewPassword">
          <el-input
            v-model="form.confirmNewPassword"
            autocomplete="no"
            placeholder="请确认新密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { updateBackAdminPassword } from "@/api/userLogin";
export default {
  props: {
    navigateItem: Array,
  },
  data() {
    return {
      currentNavigate: "",
      breadList: [],
      userInfo: "",
      dialogFormVisible: false,
      form: {
        originalPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
      formRules: {
        originalPassword: [
          { required: true, message: "请输入原密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            pattern: /[a-zA-Z\d]{6,}/,
            message: "请输入新密码",
            trigger: "blur",
          },
        ],
        confirmNewPassword: [
          { required: true, message: "请确认新密码", trigger: "blur" },
          {
            pattern: /[a-zA-Z\d]{6,}/,
            message: "请确认新密码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    userRoleName() {
      let userRoleName = "";
      switch (this.userInfo.power) {
        case 1:
          userRoleName = "平台管理员";
          break;
        case 2:
          userRoleName = "国家级管理员";
          break;
        case 3:
          userRoleName = "省级管理员";
          break;
        case 4:
          userRoleName = "市级管理员";
          break;
        case 5:
          userRoleName = "区(县)级管理员";
          break;
        case 6:
          userRoleName = "街道级管理员";
          break;
      }
      return userRoleName;
    },
    bread() {
      return this.$store.state.breadListState;
    },
  },
  watch: {
    $route(to) {
      console.log(to);
      this.currentNavigate = to.fullPath;
      this.getBreadcrumb();
    },
    bread(val) {
      console.log(val, "breadListState");
    },
  },
  components: {},

  created() {
    console.log(this.$route.path);
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (sessionStorage.getItem("navigateIndex")) {
      this.currentNavigate = this.$route.fullPath;
    }
    this.breadList = this.$store.state.breadListState;
  },

  methods: {
    // 导航菜单关闭开启切换
    setNavMenuToggle() {
      this.$store.commit("setNavMenuToggle");
    },
    // 刷新
    refresh() {
      window.location.reload();
    },
    // 面包屑点击事件
    breadcrumbClick(item) {
      console.log(item, this.currentNavigate, "this.currentNavigate");
      if (this.currentNavigate != item.path) {
        this.$router.push({
          path: item.path,
        });
      }
    },

    // 面包屑数据处理
    getBreadcrumb() {
      let that = this;
      // 由于本项目大部分属于‘一级’页面，所以在设置路由时候，一级页面不设置breadNumber，‘二级’页面以上才设置breadNumber
      let breadNumber =
        typeof this.$route.meta.breadNumber !== "undefined"
          ? this.$route.meta.breadNumber
          : 1;
      // 获取当前页面的名字和路由，并组合成新的对象
      let newBread = { name: this.$route.name, path: this.$route.fullPath };
      let vuexBreadList = []; // 默认初始化面包屑数据
      if (breadNumber !== 1) {
        // 当前面包屑breadNumber大于1时才会从vuex中获取值
        vuexBreadList = that.$store.state.breadListState; // 获取breadList数组
      }
      if (breadNumber < vuexBreadList.length) {
        // "回退"面包屑----判断条件：当前路由breadNumber小于vuexBreadList的长度
        vuexBreadList.splice(
          breadNumber - vuexBreadList.length,
          vuexBreadList.length - breadNumber
        );
      }
      if (breadNumber > vuexBreadList.length) {
        // 添加面包屑----判断条件：当前路由breadNumber大于vuexBreadList的长度
        vuexBreadList.push(newBread);
      }
      // 处理完数据后，将最终的数据更新到vuex（用于页面刷新）
      that.$store.commit("breadListMutations", vuexBreadList);
      // 处理完数据后，将最终的数据更新为新的面包屑数组
      that.breadList = vuexBreadList;
    },
    // 退出登录
    logout() {
      sessionStorage.clear();
      this.$store.commit("breadListMutations", []);
      this.$router.push({ path: "/login" });
    },
    // 修改密码
    updatePassword() {
      this.dialogFormVisible = true;
    },
    async submitForm(formName) {
      if (this.form.newPassword != this.form.confirmNewPassword) {
        this.$message({
          message: "请确认两次密码输入是否一致",
          type: "warning",
        });
        return;
      }

      let param = {
        id: this.userInfo.id,
        oldPassword: this.form.originalPassword,
        password: this.form.newPassword,
        confirmPassword: this.form.confirmNewPassword,
      };
      console.log(param, "param", formName);
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // 表单验证通过
          let res = await updateBackAdminPassword(param);
          if (res.data.result == "success") {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
            this.dialogFormVisible = false;
            sessionStorage.clear();
            this.$router.push({ path: "/login" });
          } else {
            this.$message({
              type: "error",
              message: res.data.msg,
            });
          }
        } else {
          // 表单验证不通过
          console.log("validate failed");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.headerContent {
  width: 100%;
  height: 100%;
  border: 1px solid #eee;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pageSetup {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.userSetup {
  margin-right: 20px;
}
.el-input {
  width: 300px;
}
.headerContent .updatePassword {
  text-align: left;
}
.dropdownSpan {
  display: inline-block;
  width: 100px;
  height: 30px;
}
</style>
