<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span> 用户协议管理</span>
      </div>
      <div class="text item" style="font-size: 14px">
        这里是对小程序上用户注册时所需要阅读并同意的用户协议的管理模块。
      </div>
      <!-- <el-button @click="study">查看学习数据</el-button> -->
    </el-card>
    <el-row style="margin: 20px">
      <el-button type="primary" @click="createAgreement"
        >新建用户协议</el-button
      >
    </el-row>
    <el-table :data="tableData" border>
      <el-table-column prop="name" label="用户协议名称"></el-table-column>
      <el-table-column prop="inTime" label="创建时间"> </el-table-column>
      <el-table-column label="是否启用">
        <template slot-scope="scope">
          <el-radio-group
            v-model="scope.row.recovery"
            @change="updateAgreementStatus(scope.row)"
          >
            <el-radio :label="false">启用</el-radio>
            <el-radio :label="true">禁用</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click="editAgreement(scope.row)"
            >修改</el-button
          >
          <el-button type="danger" @click="deleteAgreement(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  seleteAgreementList,
  updateAgreementStatus,
  deleteAgreement,
} from "@/api/agreementManagement";
import { msToDate } from "@/plugins/msToDate";
export default {
  data() {
    return {
      search: "",
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      srcList: [],
    };
  },
  created() {
    this.seleteAgreementList();
  },
  methods: {
    async seleteAgreementList() {
      let res = await seleteAgreementList({
        name: this.search,
        startNum: this.currentPage,
        pageSize: this.pageSize,
        type: 0,
      });
      if (res.data.result == "success") {
        res.data.list.forEach((element) => {
          element.inTime = msToDate(element.inTime);
        });
        this.tableData = res.data.list;
        this.total = res.data.listSize;
      }
    },
    // 启用/禁用
    async updateAgreementStatus(row) {
      let res = await updateAgreementStatus({
        id: row.id,
        recovery: row.recovery,
        type: 0,
      });
      if (res.data.msg == "success") {
        this.$message({
          message: "修改状态成功",
          type: "success",
        });
        this.seleteAgreementList();
      }
    },
    searchAgreement() {
      // Implement search logic here
      this.seleteAgreementList();
    },
    createAgreement() {
      // Implement create logic here
      this.$router.push({
        path: "/newAgreement",
      });
    },
    editAgreement(row) {
      // Implement edit logic here
      this.$router.push({
        path: "/editAgreement",
        query: { id: row.id },
      });
    },
    deleteAgreement(row) {
      // Implement delete logic here
      console.log(row);
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await deleteAgreement({
            id: row.id,
          });
          if (res.data.result == "success") {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.seleteAgreementList();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.searchAgreement();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchAgreement();
    },
  },
};
</script>
<style scoped>
.icon {
  font-size: 32px;
  display: flex;
  justify-content: space-around;
}
</style>
