<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span> {{ trainingObj.name }}</span>
      </div>
      <div class="text item" style="font-size: 14px">
        {{ trainingObj.content }}
      </div>
    </el-card>
    <div id="trainingInquiryList">
      <el-row :gutter="20">
        <el-col :span="7">
          <span>搜索内容：</span>
          <el-input
            style="width: 300px"
            v-model="searchInput"
            placeholder="输入框搜索功能"
          ></el-input>
        </el-col>
        <el-col :span="5">
          <span>岗位：</span>
          <el-select v-model="positionValue" placeholder="请选择岗位" clearable>
            <el-option
              v-for="item in positionList"
              :key="item.id"
              :label="item.postionName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>

        <el-col :span="5">
          <span>课程：</span>
          <el-select v-model="lessonValue" placeholder="请选择课程" clearable>
            <el-option
              v-for="item in lessonList"
              :key="item.id"
              :label="item.lessonName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-button type="primary" @click="search">查询</el-button>
          <!-- <el-button type="default" @click="reset">重置</el-button> -->
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <span>地区：</span>
          <span class="ml-20">省：</span>
          <el-select
            v-model="provId"
            placeholder="请选择省"
            @change="getFindCityList(provId)"
          >
            <el-option
              v-for="item in provList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <span class="ml-20">市：</span>
          <el-select
            v-model="cityId"
            placeholder="请选择市"
            @change="getFindDistrictList(cityId)"
          >
            <el-option
              v-for="item in cityList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <span class="ml-20">区：</span>
          <el-select
            v-model="regionId"
            placeholder="请选择区"
            @change="getFindStreetList(regionId)"
          >
            <el-option
              v-for="item in regionList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <span class="ml-20">街道：</span>
          <el-select
            v-model="streetId"
            placeholder="请选择街道"
            @change="getAddCompanyList"
          >
            <el-option
              v-for="item in streetList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <span class="ml-20">单位：</span>
          <el-select v-model="unitValue" placeholder="请选择单位" clearable>
            <el-option
              v-for="item in unitList"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column type="selection" fixed></el-table-column>
        <el-table-column prop="id" label="uni-id" fixed></el-table-column>
        <el-table-column
          prop="name"
          label="课程名称"
          fixed
          width="300"
        ></el-table-column>
        <el-table-column prop="companyName" label="参与培训单位" width="300">
        </el-table-column>
        <el-table-column prop="postionName" label="参与培训岗位" width="300">
        </el-table-column>
        <el-table-column prop="area" label="单位所属地区" width="300">
          <template slot-scope="scope">
            {{
              scope.row.provinceName +
              scope.row.cityName +
              scope.row.regionName +
              scope.row.streetName
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="zongshu"
          label="参与人数"
          width="100"
        ></el-table-column>
        <!-- <el-table-column prop="contactNumber" label="联系电话"></el-table-column> -->
        <el-table-column
          prop="shichang"
          label="已完成人数"
          width="100"
        ></el-table-column>
        <el-table-column prop="jindu" label="培训进度" width="100">
          <template slot-scope="scope"> {{ scope.row.jindu }}% </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="400">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="navigationCheckUnitData(scope.row)"
              >查看单位数据</el-button
            >
            <el-button
              type="primary"
              @click="navigationCheckUnitUserData(scope.row)"
              >查看单位用户数据</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalItems"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {
  findCityList,
  findDistrictList,
  findProviceList,
  findStreetList,
} from "@/api/userLogin";
import {
  getPlatformTrainingDataList,
  getAddPlatformPositionList,
  getAddLessonList,
  getAddCompanyList,
} from "@/api/trainingManage";
export default {
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      createTime: "",
      trainingObj: "",
      positionList: [],
      lessonList: [],
      unitList: [],
      provList: [], //省级
      cityList: [], //市级列表
      regionList: [], // 区级
      streetList: [], // 街道
      provId: "",
      cityId: "",
      regionId: "",
      streetId: "",
      searchInput: "",
      positionValue: "",
      lessonValue: "",
      unitValue: "",
      selectedDate: "",
      selectedAddress: [],
      addressOptions: [],
      createDialogVisible: false,
      positionName: "",
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      totalItems: 0,
      detailObj: "",
    };
  },
  created() {
    this.getFindProviceList();
    this.getPlatformTrainingDataList(this.$route.query.id);
    this.getAddPlatformPositionList();
    this.getAddLessonList(this.$route.query.id);
    // this.getAddCompanyList([]);
  },
  methods: {
    // 获取培训数据
    async getPlatformTrainingDataList(id) {
      let res = await getPlatformTrainingDataList({
        id: id,
        name: this.searchInput,
        positionId: this.positionValue,
        companyId: this.unitValue,
        lessonId: this.lessonValue,
        provinceId: this.provId,
        cityId: this.cityId,
        regionId: this.regionId,
        streetId: this.streetId,
        pageSize: this.pageSize,
        startNum: this.currentPage,
      });
      if (res.data.result == "success") {
        this.tableData = res.data.platformTrainingDataList;
        this.trainingObj = res.data.platformTraining;
        this.totalItems = res.data.size;
        console.log(this.tableData, "getPlatformTrainingDataList");
      }
    },
    // 获取岗位
    async getAddPlatformPositionList() {
      let res = await getAddPlatformPositionList();
      if (res.data.result == "success") {
        this.positionList = res.data.msg;
      }
    },
    // 获取课程列表
    async getAddLessonList(id) {
      let res = await getAddLessonList({
        traingId: id,
      });
      if (res.data.result == "success") {
        this.lessonList = res.data.msg;
      }
    },
    // 获取单位管理列表
    async getAddCompanyList() {
      let res = await getAddCompanyList({
        companyName: this.unitValue,
        areaList: [
          {
            street: this.streetId,
            district: this.regionId,
            city: this.cityId,
            provice: this.provId,
          },
        ],
        startNum: 1,
        pageSize: 1000,
      });
      if (res.data.result == "success") {
        this.unitList = res.data.list;
      }
    },
    // 获取省
    async getFindProviceList() {
      let res = await findProviceList();
      if (res.data.result == "success") {
        this.provList = res.data.msg;
      }
    },
    // 获取市
    async getFindCityList(id) {
      let res = await findCityList({ id: id });
      if (res.data.result == "success") {
        this.cityList = res.data.msg;
      }
    },
    // 获取区
    async getFindDistrictList(id) {
      let res = await findDistrictList({ id: id });
      if (res.data.result == "success") {
        this.regionList = res.data.msg;
      }
    },
    // 获取街道
    async getFindStreetList(id) {
      let res = await findStreetList({ id: id });
      if (res.data.result == "success") {
        this.streetList = res.data.msg;
      }
    },
    search() {
      this.getPlatformTrainingDataList(this.$route.query.id);
      // Implement search functionality
    },
    reset() {
      // Implement reset functionality
    },
    showCreateDialog() {
      this.createDialogVisible = true;
    },
    cancelCreate() {
      this.createDialogVisible = false;
      this.positionName = "";
    },
    confirmCreate() {
      // Implement create functionality
    },
    navigationCheckTrainingData(row) {
      console.log(row);
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getPlatformTrainingDataList(this.$route.query.id);
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.getPlatformTrainingDataList(this.$route.query.id);
    },
    closeDetailDialog() {
      this.detailDialogVisible = false;
    },
    // 跳转查看单位用户数据
    navigationCheckUnitUserData(row) {
      sessionStorage.setItem("unitData", JSON.stringify(this.trainingObj));
      sessionStorage.setItem("lessonId", row.id);
      sessionStorage.setItem("positionId", row.positionId);
      this.$router.push({
        path: "/checkUnitUserData",
        query: { id: row.companyId },
      });
    },
    // 跳转查看单位数据
    navigationCheckUnitData(row) {
      this.trainingObj.companyId = row.companyId;
      sessionStorage.setItem("unitData", JSON.stringify(this.trainingObj));
      sessionStorage.setItem("lessonId", row.id);
      sessionStorage.setItem("positionId", row.positionId);
      this.$router.push({
        path: "/checkUnitData",
        query: { id: row.id },
      });
    },
  },
  mounted() {
    // Fetch address options for cascader
    // Fetch initial data for table
  },
};
</script>

<style scoped>
/* Add custom styles here */
#trainingInquiryList {
  padding: 20px;
}
.el-row {
  margin-bottom: 20px;
}
.ml-20 {
  margin-left: 20px;
}
</style>
