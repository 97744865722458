<template>
  <div id="catalogueContent">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>帮助文档管理</span>
      </div>
      <div class="text item" style="font-size: 14px">
        对显示在前台给用户看的帮助文档进行管理的模块。
      </div>
    </el-card>
    <div id="sectionAndPeriod">
      <div class="sectionCatalogue">
        <el-card class="card">
          <div slot="header">
            <span>帮助文档分组</span>
          </div>
          <div class="text item">
            <el-scrollbar style="height: 500px">
              <template v-if="tableData.length != 0">
                <div
                  v-for="(item, index) in tableData"
                  :key="item.id"
                  class="sectionItem"
                  @click="activeDocumentGroup(item, index)"
                >
                  <div
                    :style="item.active ? 'font-weight:700;color:#0099FF' : ''"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </template>
            </el-scrollbar>
          </div>
          <el-button
            style="width: 100%; margin-top: 20px"
            @click="navigationDocumentGrouping"
            >编辑文档分组</el-button
          >
        </el-card>
      </div>
      <div class="period">
        <template v-if="tableData">
          <documentContent :GroupId="GroupId"></documentContent>
        </template>
        <template v-else>
          <div style="margin-top: 100px; text-align: center">
            暂未添加文档内容~~~
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import documentContent from "./documentContent.vue";
import { getEducationHelpDocumentGroupList } from "@/api/helpDocumentManagement";
export default {
  components: {
    documentContent,
  },
  data() {
    return {
      sectionValue: "",
      chargeMode: "0", // 收费模式
      tableData: [],
      currentPage: 1,
      pageSize: 100,
      total: 0,
      GroupId: "",
    };
  },
  created() {
    this.getEducationHelpDocumentGroupList();
  },
  methods: {
    async getEducationHelpDocumentGroupList() {
      let res = await getEducationHelpDocumentGroupList({
        name: "",
        startNum: this.currentPage,
        pageSize: this.pageSize,
      });
      if (res.data.result == "success") {
        this.tableData = res.data.educationHelpDocumentGroupList.map((item) => {
          item.active = false;
          return item;
        });
        if (this.tableData.length > 0) {
          this.tableData[0].active = true;
          this.GroupId = this.tableData[0].id;
        }
        this.total = res.data.size;
      }
    },
    // 当前文档分组
    activeDocumentGroup(item, index) {
      this.tableData.forEach((item) => {
        item.active = false;
      });
      this.tableData[index].active = !this.tableData[index].active;
      this.GroupId = this.tableData[index].id;
    },
    // 跳转文档分组列表
    navigationDocumentGrouping() {
      this.$router.push({ path: "/helpGocumentGroupList" });
    },
  },
};
</script>

<style scoped>
#sectionAndPeriod {
  margin-top: 20px;
  display: flex;
}
.sectionCatalogue .card {
  width: 300px;
  height: 100%;
  max-height: 800px;
  margin-right: 20px;
}
.ml-20 {
  margin-left: 20px;
}

.sectionItem {
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #eee;
  cursor: pointer;
}
.sectionItem:hover {
  background-color: #ccc;
}
.period {
  width: 80%;
}
</style>
