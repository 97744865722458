<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>单位管理</span>
      </div>
      <div class="text item" style="font-size: 14px">对单位进行管理。</div>
    </el-card>
    <el-row style="margin: 20px 0">
      <el-button
        :type="status == '' ? 'primary' : ''"
        round
        @click="cutAuditStatus('')"
        >全部</el-button
      >
      <el-button
        :type="status == '1' ? 'primary' : ''"
        round
        @click="cutAuditStatus('1')"
        >已通过</el-button
      >
      <el-button
        :type="status == '2' ? 'primary' : ''"
        round
        @click="cutAuditStatus('2')"
        >未通过</el-button
      >
      <el-button
        :type="status == '0' ? 'primary' : ''"
        round
        @click="cutAuditStatus('0')"
        >未审核</el-button
      >
    </el-row>
    <el-row style="margin: 20px 0">
      <el-col :span="6">
        <el-input
          v-model="searchText"
          placeholder="请输入单位/关键字"
          clearable
        ></el-input>
      </el-col>
      <el-col :span="6" style="text-align: center">
        <el-select v-model="selectedOption" placeholder="请选择搜索条件">
          <el-option label="全部" value=""></el-option>
          <el-option label="启用" value="false"></el-option>
          <el-option label="禁用" value="true"></el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="search">搜索</el-button>
        <!-- <el-button @click="reset">重置</el-button> -->
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <span class="ml-20">省：</span>
        <el-select
          v-model="provinceId"
          placeholder="请选择省"
          clearable
          @change="getFindCityList(provinceId)"
          :disabled="
            $store.state.userPower == 3 ||
            $store.state.userPower == 4 ||
            $store.state.userPower == 5 ||
            $store.state.userPower == 6
          "
        >
          <el-option
            v-for="item in provList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <span class="ml-20">市：</span>
        <el-select
          v-model="cityId"
          placeholder="请选择市"
          @change="getFindDistrictList(cityId)"
          clearable
          :disabled="
            $store.state.userPower == 4 ||
            $store.state.userPower == 5 ||
            $store.state.userPower == 6
          "
        >
          <el-option
            v-for="item in cityList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <span class="ml-20">区：</span>
        <el-select
          v-model="regionId"
          placeholder="请选择区"
          @change="getFindStreetList(regionId)"
          clearable
          :disabled="$store.state.userPower == 5 || $store.state.userPower == 6"
        >
          <el-option
            v-for="item in regionList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <span class="ml-20">街道：</span>
        <el-select
          v-model="streetId"
          placeholder="请选择街道"
          clearable
          :disabled="$store.state.userPower == 6"
        >
          <el-option
            v-for="item in streetList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row style="margin: 20px 0">
      <el-col :span="3">
        <el-button type="primary" @click="addUnit">添加单位</el-button>
      </el-col>
      <el-col :span="3">
        <el-upload
          class="upload-demo"
          action="none"
          :auto-upload="false"
          :on-change="importExcel"
          multiple
          :limit="1"
          :file-list="fileList"
          :on-exceed="handleExceed"
          :show-file-list="false"
        >
          <el-button type="primary">单位导入</el-button>
        </el-upload>
      </el-col>
      <el-col :span="4" style="line-height: 40px">
        <a
          href="https://jwzjy.oss-cn-wulanchabu.aliyuncs.com/%E5%85%AC%E5%8F%B8%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx"
          >单位导入模板下载</a
        >
      </el-col>
    </el-row>
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column type="selection"></el-table-column>
      <el-table-column prop="companyCode" label="单位编码"></el-table-column>
      <el-table-column prop="companyName" label="单位"></el-table-column>
      <!-- <el-table-column prop="courseNum" label="学习课程数量"></el-table-column> -->
      <el-table-column label="地区">
        <template slot-scope="scope">
          {{ scope.row.provinceName ? scope.row.provinceName : "" }}
          {{ scope.row.cityName ? scope.row.cityName : "" }}
          {{ scope.row.regionName ? scope.row.regionName : "" }}
          {{ scope.row.streetName ? scope.row.streetName : "" }}
        </template>
      </el-table-column>
      <el-table-column label="启用/禁用">
        <template slot-scope="scope">
          <el-radio-group
            v-model="scope.row.recovery"
            @change="changeStatus(scope.row)"
          >
            <el-radio :label="false">启用</el-radio>
            <el-radio :label="true">禁用</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="500">
        <template slot-scope="scope">
          <el-button type="primary" @click="showUnitDetail(scope.row)"
            >查看单位详情</el-button
          >
          <el-button type="primary" @click="editItem(scope.row)"
            >编辑</el-button
          >
          <el-button type="danger" @click="deleteItem(scope.row)"
            >删除</el-button
          >
          <el-button type="primary" @click="showDetail(scope.row)"
            >查看单位成员</el-button
          >
          <!-- <el-button type="primary" @click="setJob(scope.row)"
            >职位管理</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- 编辑单位 -->
    <el-dialog
      :visible.sync="editUnitDialogVisible"
      title="编辑单位"
      :close-on-click-modal="false"
    >
      <el-form :model="editUnitForm" :rules="rules" ref="editUnitForm">
        <el-form-item label="单位名称" prop="companyName">
          <el-input v-model="editUnitForm.companyName"></el-input>
        </el-form-item>
        <el-form-item label="单位介绍" prop="companyIntroduction">
          <el-input
            type="textarea"
            v-model="editUnitForm.companyIntroduction"
            :maxlength="120"
          ></el-input>
        </el-form-item>
        <el-form-item label="单位所属地区" prop="streetId">
          <el-select
            style="width: 150px"
            v-model="editUnitForm.provinceId"
            placeholder="请选择省"
            @clear="clearProvId"
            clearable
            @change="getFindCityList(editUnitForm.provinceId)"
          >
            <el-option
              v-for="item in provList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            style="width: 150px"
            v-model="editUnitForm.cityId"
            placeholder="请选择市"
            @clear="clearCityId"
            clearable
            @change="getFindDistrictList(editUnitForm.cityId)"
          >
            <el-option
              v-for="item in cityList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            style="width: 150px"
            v-model="editUnitForm.regionId"
            placeholder="请选择区"
            @clear="clearRegionId"
            clearable
            @change="getFindStreetList(editUnitForm.regionId)"
          >
            <el-option
              v-for="item in regionList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            style="width: 150px"
            v-model="editUnitForm.streetId"
            placeholder="请选择街道"
            clearable
          >
            <el-option
              v-for="item in streetList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="统一社会信用代码" prop="unifyTheSocialCreditCode">
          <el-input
            v-model="editUnitForm.unifyTheSocialCreditCode"
            placeholder="请输入统一社会信用代码"
          ></el-input>
        </el-form-item>
        <el-form-item label="单位资质" prop="imageUrl">
          <el-upload
            class="avatar-uploader"
            action="/course/oss/uploadFile"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="editUnitForm.imageUrl"
              :src="editUnitForm.imageUrl"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editUnitDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="updateUnit">修改</el-button>
      </div>
    </el-dialog>
    <!-- 添加单位 -->
    <el-dialog
      :visible.sync="newUnitDialogVisible"
      title="添加单位"
      :close-on-click-modal="false"
    >
      <el-form :model="newUnitForm" :rules="rules" ref="newUnitForm">
        <el-form-item label="单位名称" prop="name">
          <el-input
            v-model="newUnitForm.name"
            placeholder="请输入单位名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="单位介绍" prop="detail">
          <el-input
            type="textarea"
            v-model="newUnitForm.detail"
            rows="10"
            maxlength="120"
            placeholder="请输入单位介绍"
          ></el-input>
        </el-form-item>
        <el-form-item label="单位所属地区" prop="streetId">
          <el-select
            style="width: 150px"
            v-model="newUnitForm.provinceId"
            placeholder="请选择省"
            @clear="clearProvId"
            clearable
            @change="getFindCityList(newUnitForm.provinceId)"
          >
            <el-option
              v-for="item in provList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            style="width: 150px"
            v-model="newUnitForm.cityId"
            placeholder="请选择市"
            @clear="clearCityId"
            clearable
            @change="getFindDistrictList(newUnitForm.cityId)"
          >
            <el-option
              v-for="item in cityList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            style="width: 150px"
            v-model="newUnitForm.regionId"
            placeholder="请选择区"
            @clear="clearRegionId"
            clearable
            @change="getFindStreetList(newUnitForm.regionId)"
          >
            <el-option
              v-for="item in regionList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            style="width: 150px"
            v-model="newUnitForm.streetId"
            placeholder="请选择街道"
            clearable
          >
            <el-option
              v-for="item in streetList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="统一社会信用代码" prop="unifyTheSocialCreditCode">
          <el-input
            v-model="newUnitForm.unifyTheSocialCreditCode"
            placeholder="请输入统一社会信用代码"
          ></el-input>
        </el-form-item>
        <el-form-item label="单位资质" prop="imageUrl">
          <el-upload
            class="avatar-uploader"
            action="/course/oss/uploadFile"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="newUnitForm.imageUrl"
              :src="newUnitForm.imageUrl"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="newUnitDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitForm">添加</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="unitDetailDialogVisible"
      title="单位详情"
      :close-on-click-modal="false"
      width="33%"
    >
      <el-descriptions :column="1">
        <el-descriptions-item label="单位名称">{{
          unitDetailForm.companyName
        }}</el-descriptions-item>
        <el-descriptions-item label="单位简介">{{
          unitDetailForm.companyIntroduction
        }}</el-descriptions-item>
        <el-descriptions-item label="组织机构代码">{{
          unitDetailForm.usciCode
        }}</el-descriptions-item>
        <el-descriptions-item label="营业执照">
          <el-image
            style="width: 178px; height: 178px"
            v-if="unitDetailForm.companyQualifications"
            :src="unitDetailForm.companyQualifications"
            :preview-src-list="srcList"
          >
          </el-image>
          <span v-else>未上传</span>
        </el-descriptions-item>
        <el-descriptions-item label="所在地区">{{
          unitDetailForm.areaName
        }}</el-descriptions-item>
      </el-descriptions>
      <div
        v-if="unitDetailForm.isPass != 0"
        slot="footer"
        class="dialog-footer"
      >
        <el-button type="primary" plain @click="unitAudit('1')">通过</el-button>
        <el-button type="danger" plain @click="unitAudit('2')">驳回</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  saveCompany,
  getEducationCompanyList,
  updateCompanyStatus,
  deleteCompany,
  updateCompany,
  examineInvoice,
  getEducationCompanyById,
  importExcel,
} from "@/api/unitManagement";
import {
  findProviceList,
  findCityList,
  findDistrictList,
  findStreetList,
} from "@/api/userLogin";
export default {
  data() {
    return {
      searchText: "",
      selectedOption: "",
      provinceId: "",
      cityId: "",
      regionId: "",
      streetId: "",
      newUnitDialogVisible: false, // 添加单位弹出框
      newUnitForm: {
        name: "",
        detail: "",
        imageUrl: "",
        unifyTheSocialCreditCode: "",
        provinceId: "",
        cityId: "",
        regionId: "",
        streetId: "",
      }, // 存放添加单位填写的数据
      rules: {
        name: [{ required: true, message: "请输入单位名称", trigger: "blur" }],
        unifyTheSocialCreditCode: [
          {
            required: true,
            message: "请输入统一社会信用代码",
            trigger: "blur",
          },
        ],
        detail: [
          { required: true, message: "请输入单位介绍", trigger: "blur" },
        ],
        streetId: [
          { required: true, message: "请选择单位所在地区", trigger: "blur" },
        ],
        imageUrl: [
          {
            required: true,
            message: "请上传单位资质图片",
            trigger: "click",
          },
        ],
      }, // 验证添加单位的数据
      editUnitDialogVisible: false, // 编辑单位弹出框
      editUnitForm: {
        companyName: "",
        companyIntroduction: "",
        imageUrl: "",
        unifyTheSocialCreditCode: "",
        provinceId: "",
        cityId: "",
        regionId: "",
        streetId: "",
      }, // 存放编辑单位填写的数据
      editRules: {
        companyName: [
          { required: true, message: "请输入单位名称", trigger: "blur" },
        ],
        unifyTheSocialCreditCode: [
          {
            required: true,
            message: "请输入统一社会信用代码",
            trigger: "blur",
          },
        ],
        companyIntroduction: [
          { required: true, message: "请输入单位介绍", trigger: "blur" },
        ],
        streetId: [
          { required: true, message: "请选择单位所在地区", trigger: "blur" },
        ],
        imageUrl: [
          {
            required: true,
            message: "请上传单位资质图片",
            trigger: "click",
          },
        ],
      }, // 验证编辑单位的数据
      unitDetailDialogVisible: false, // 单位详情弹出框
      unitDetailForm: {}, // 单位详情数据
      srcList: [], // 存放预览图
      fileList: [], // 存放单位导入文件
      // tableData: [],
      tableData: [],
      provList: [], //省级
      cityList: [], //市级列表
      regionList: [], // 区级
      streetList: [], // 街道
      currentPage: 1,
      pageSize: 10,
      total: 0,
      detailData: "",
      status: "",
    };
  },
  created() {
    this.getEducationCompanyList();
    this.getFindProviceList();
  },
  watch: {
    editUnitDialogVisible(val) {
      if (!val) {
        this.newUnitForm.imageUrl = "";
        this.srcList = [];
        this.cityList = []; //市级列表
        this.regionList = []; // 区级
        this.streetList = []; // 街道
        this.provinceId = "";
        this.cityId = "";
        this.regionId = "";
        this.streetId = "";
      }
    },
    newUnitDialogVisible(val) {
      if (!val) {
        this.editUnitForm.imageUrl = "";
        this.srcList = [];
        this.cityList = []; //市级列表
        this.regionList = []; // 区级
        this.streetList = []; // 街道
        this.provinceId = "";
        this.cityId = "";
        this.regionId = "";
        this.streetId = "";
      }
    },
  },
  methods: {
    // 单位导入
    importExcel(file, fileList) {
      console.log(file, fileList);
      const isXlsx =
        file.raw.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.raw.type === "application/vnd.ms-excel";
      this.fileList = [];
      if (!isXlsx) {
        this.$message.error("上传文件只能是 Excel 表格!");
        return;
      }
      let formData = new FormData();
      formData.append("file", file.raw);
      let result = importExcel(formData);
      result.then((res) => {
        console.log(res, "res");
        if (res.data.result == "success") {
          this.$message.success(res.data.msg);
          this.getEducationCompanyList();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    // 审核状态切换
    cutAuditStatus(status) {
      this.status = status;
      this.getEducationCompanyList();
    },
    // 获取单位管理列表
    async getEducationCompanyList() {
      let res = await getEducationCompanyList({
        name: this.searchText, //名称
        status: this.selectedOption, //状态	true禁用	false禁用
        isPass: this.status, //0-未审核 1-审核通过 2-审核不通过
        startNum: this.currentPage, //起始页码
        pageSize: this.pageSize, //每页条数
        provinceId: this.provinceId,
        cityId: this.cityId,
        regionId: this.regionId,
        streetId: this.streetId,
      });
      if (res.data.result == "success") {
        this.tableData = res.data.list;
        this.total = res.data.listSize;
      }
      console.log(res, "getEducationCompanyList");
    },
    // 确认添加单位
    submitForm() {
      // 提交表单功能
      this.$refs.newUnitForm.validate(async (valid) => {
        if (valid) {
          // 提交表单
          let res = await saveCompany({
            companyName: this.newUnitForm.name,
            companyIntroduction: this.newUnitForm.detail,
            provinceId: this.newUnitForm.provinceId,
            cityId: this.newUnitForm.cityId,
            regionId: this.newUnitForm.regionId,
            streetId: this.newUnitForm.streetId,
            usciCode: this.newUnitForm.unifyTheSocialCreditCode,
            companyQualifications: this.newUnitForm.imageUrl,
          });
          if (res.data.result == "success") {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
            this.newUnitDialogVisible = false;

            this.getEducationCompanyList();
          }
          console.log(res, "submitForm");
        }
      });
    },
    // 上传图片成功后
    handleAvatarSuccess(res, file) {
      console.log(res.msg, file);
      // this.imageUrl = URL.createObjectURL(file.raw);
      this.newUnitForm.imageUrl = file.response.msg;
      this.editUnitForm.imageUrl = file.response.msg;
    },
    // 上传图片前
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传模板图片只能是 JPG和PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传模板图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    // 获取省
    async getFindProviceList() {
      let res = await findProviceList();
      if (res.data.result == "success") {
        this.provList = res.data.msg;
      }
    },
    // 清空省触发
    clearProvId() {
      this.ruleForm.cityId = "";
      this.ruleForm.regionId = "";
      this.ruleForm.streetId = "";
      this.cityList = [];
      this.regionList = [];
      this.streetList = [];
    },
    // 清空市触发
    clearCityId() {
      this.ruleForm.regionId = "";
      this.ruleForm.streetId = "";
      this.regionList = [];
      this.streetList = [];
    },
    // 清空区触发
    clearRegionId() {
      this.ruleForm.streetId = "";
      this.streetList = [];
    },
    // 获取市
    async getFindCityList(id) {
      let res = await findCityList({ id: id });
      if (res.data.result == "success") {
        this.cityList = res.data.msg;
      }
    },
    // 获取区
    async getFindDistrictList(id) {
      let res = await findDistrictList({ id: id });
      if (res.data.result == "success") {
        this.regionList = res.data.msg;
      }
    },
    // 获取街道
    async getFindStreetList(id) {
      let res = await findStreetList({ id: id });
      if (res.data.result == "success") {
        this.streetList = res.data.msg;
      }
    },
    // 添加单位弹出框
    addUnit() {
      this.newUnitDialogVisible = true;
    },
    search() {
      // 发送搜索请求
      this.getEducationCompanyList();
    },
    // 重置
    reset() {
      this.searchText = "";
      this.selectedOption = "";
      // this.search();
    },
    // 启用禁用状态
    async changeStatus(row) {
      console.log(row, "changeStatus");
      let res = await updateCompanyStatus({
        id: row.id,
        recovery: row.recovery,
      });
      if (res.data.result == "success") {
        this.$message({
          type: "success",
          message: res.data.msg,
        });
        this.getEducationCompanyList();
      }
    },
    // 跳转单位成员页
    showDetail(row) {
      // 发送获取详情请求
      console.log("showDetail", row);
      this.$router.push({ path: "/memberManagement", query: { id: row.id } });
      sessionStorage.setItem("unitData", JSON.stringify(row));
    },
    // 弹出单位详情框
    async showUnitDetail(row) {
      let res = await getEducationCompanyById({ id: row.id });
      console.log("showUnitDetail", res);
      if (res.data.result == "success") {
        this.unitDetailDialogVisible = true;
        this.unitDetailForm = res.data.educationCloudCompany;
        this.srcList.push(this.unitDetailForm.companyQualifications);
      }
    },
    // 单位审核
    async unitAudit(state) {
      console.log(state, "unitAudit");
      let res = await examineInvoice({
        companyId: this.unitDetailForm.id,
        userId: JSON.parse(sessionStorage.getItem("userInfo")).id,
        examineStatus: state,
      });
      if (res.data.result == "success") {
        this.$message.success(res.data.msg);
        this.unitDetailDialogVisible = false;
        this.getEducationCompanyList();
        console.log(res);
      } else {
        this.$message.error(res.data.msg);
        this.unitDetailDialogVisible = false;
        this.getEducationCompanyList();
      }
    },
    // 编辑弹出框
    editItem(row) {
      console.log("row", row);
      this.editUnitDialogVisible = true;
      this.editUnitForm = row;
      if (this.editUnitForm.provinceId) {
        this.getFindCityList(this.editUnitForm.provinceId);
      }
      if (this.editUnitForm.cityId) {
        this.getFindDistrictList(this.editUnitForm.cityId);
      }
      if (this.editUnitForm.regionId) {
        this.getFindStreetList(this.editUnitForm.regionId);
      }
    },
    // 提交编辑
    async updateUnit() {
      let res = await updateCompany({
        id: this.editUnitForm.id,
        companyName: this.editUnitForm.companyName,
        companyIntroduction: this.editUnitForm.companyIntroduction,
      });
      if (res.data.result == "success") {
        this.$message({
          type: "success",
          message: res.data.msg,
        });
        this.editUnitDialogVisible = false;
        this.editUnitForm = {};
        this.getEducationCompanyList();
      }
      console.log(res, "updateUnit");
    },
    deleteItem(row) {
      // 发送删除请求
      console.log("deleteItem", row);
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await deleteCompany({
            ids: row.id,
          });
          if (res.data.result == "success") {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
            this.getEducationCompanyList();
          }
          console.log("deleteUser", res);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    setJob(row) {
      // 发送设为职位管理请求
      console.log("setJob", row);
      this.$router.push({ path: "/jobManagement" });
      sessionStorage.setItem("unitData", JSON.stringify(row));
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.search();
    },
  },
};
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  border: 1px dashed #d9d9d9;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  border: 1px dashed #d9d9d9;
  width: 178px;
  height: 178px;
  display: block;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
</style>
