<template>
  <div id="view_container">
    <div style="height: 80%">
      <div class="logo-and-name">
        <img
          src="../../public/logo.png"
          alt=""
          style="
            width: 100px;
            height: 100px;
            border-radius: 50%;
            vertical-align: middle;
          "
        />
        <span style="font-weight: 700; font-size: 32px; margin-left: 20px"
          >精卫医教云管理平台</span
        >
        <div style="color: #c5c5c5; font-size: 14px; margin-top: 10px">
          让你的学习更加系统~
        </div>
      </div>
      <div class="login-container">
        <el-tabs v-model="activeTab" class="login-tabs">
          <el-tab-pane label="账号密码登录" name="password">
            <el-form
              ref="loginForm"
              :model="loginForm"
              :rules="loginRules"
              class="login-form"
            >
              <el-form-item prop="phone">
                <el-input
                  prefix-icon="el-icon-user-solid"
                  v-model="loginForm.phone"
                  placeholder="请输入账号"
                ></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  prefix-icon="el-icon-lock"
                  show-password
                  v-model="loginForm.password"
                  type="password"
                  placeholder="请输入密码"
                ></el-input>
              </el-form-item>
              <el-row>
                <el-col :span="20">
                  <el-checkbox v-model="agree">
                    <span style="color: #606266">同意</span>
                  </el-checkbox>
                  <span style="color: #409eff; font-size: 14px; cursor: pointer"
                    >《隐私服务》</span
                  >
                  <span style="color: #409eff; font-size: 14px; cursor: pointer"
                    >《用户服务协议》</span
                  >
                </el-col>
                <el-col
                  :span="4"
                  style="
                    text-align: right;
                    color: #409eff;
                    font-size: 14px;
                    cursor: pointer;
                  "
                >
                  <!-- <span><router-link to="/retrieve">忘记密码</router-link></span> -->
                </el-col>
              </el-row>

              <el-form-item>
                <el-button
                  class="loginBtn"
                  type="primary"
                  @click="submitForm('loginForm')"
                  >登录</el-button
                >
              </el-form-item>
              <el-row style="color: #606266; font-size: 14px">
                <!-- <el-col :span="20">
                  <span>其他登录方式</span>
                  <div
                    style="
                      background-color: #07c763;
                      width: 25px;
                      height: 25px;
                      text-align: center;
                      line-height: 30px;
                      border-radius: 50%;
                      display: inline-block;
                      margin-left: 10px;
                      cursor: pointer;
                    "
                  >
                    <img src="../assets/images/微信_u378.png" alt="" />
                  </div>
                </el-col> -->
                <!-- <el-col :span="4" style="color: #409eff; cursor: pointer"
                  ><router-link to="/register">注册账号</router-link></el-col
                > -->
              </el-row>
            </el-form>
          </el-tab-pane>
          <!-- <el-tab-pane label="手机号短信验证码登录" name="sms">
            <el-form
              ref="smsForm"
              :model="smsForm"
              :rules="smsRules"
              class="login-form"
            >
              <el-form-item prop="phone">
                <el-input
                  prefix-icon="el-icon-user-solid"
                  v-model="smsForm.phone"
                  placeholder="请输入手机号"
                ></el-input>
              </el-form-item>
              <el-form-item prop="smsCode">
                <el-input
                  prefix-icon="el-icon-message"
                  v-model="smsForm.smsCode"
                  placeholder="请输入验证码"
                  style="width: 260px; margin-right: 20px"
                ></el-input>
                <el-button
                  type="primary"
                  :disabled="smsCodeDisabled"
                  @click="sendSmsCode"
                  >{{ smsCodeText }}</el-button
                >
              </el-form-item>
              <el-row>
                <el-col :span="20">
                  <el-checkbox v-model="agree">
                    <span style="color: #606266">同意</span>
                  </el-checkbox>
                  <span style="color: #409eff; font-size: 14px; cursor: pointer"
                    >《隐私服务》</span
                  >
                  <span style="color: #409eff; font-size: 14px; cursor: pointer"
                    >《用户服务协议》</span
                  >
                </el-col>
              </el-row>
              <el-form-item>
                <el-button
                  class="loginBtn"
                  type="primary"
                  @click="submitForm('smsForm')"
                  >登录</el-button
                >
              </el-form-item>
              <el-row style="color: #606266; font-size: 14px">
                <el-col :span="20">
                  <span>其他登录方式</span>
                  <div
                    style="
                      background-color: #07c763;
                      width: 25px;
                      height: 25px;
                      text-align: center;
                      line-height: 30px;
                      border-radius: 50%;
                      display: inline-block;
                      margin-left: 10px;
                      cursor: pointer;
                    "
                  >
                    <img src="../assets/images/微信_u378.png" alt="" />
                  </div>
                </el-col>
                <el-col :span="4" style="color: #409eff; cursor: pointer"
                  ><router-link to="/register">注册账号</router-link>
                </el-col>
              </el-row>
            </el-form>
          </el-tab-pane> -->
        </el-tabs>
      </div>
    </div>
    <div style="height: 20%; text-align: center">
      <a href="https://beian.miit.gov.cn" style="color: #999999" target="_blank"
        >ICP备案：津ICP备2023003542号-2</a
      >
    </div>
  </div>
</template>

<script>
import { adminBackLogin } from "@/api/userLogin";
// import axios from "axios";
export default {
  data() {
    return {
      activeTab: "password",
      agree: false,
      loginForm: {
        phone: "",
        password: "",
      },
      smsForm: {
        phone: "",
        smsCode: "",
      },
      smsCodeText: "获取验证码",
      smsCodeDisabled: false,
      loginRules: {
        phone: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            // pattern: /^1[3456789]\d{9}$/,
            message: "请输入账号",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      smsRules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        smsCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },
  methods: {
    async submitForm(formName) {
      console.log(
        this.loginForm.phone,
        this.loginForm.password,
        "this.loginForm"
      );
      if (!this.agree) {
        this.$message({
          type: "warning",
          message: "请勾选隐私服务和服务用户协议",
        });
        return;
      }
      let param = {
        account: this.loginForm.phone,
        password: this.loginForm.password,
      };

      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // 表单验证通过，提交表单
          let res = await adminBackLogin(param);
          console.log(res, "res");
          if (res.data.result == "success") {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
            sessionStorage.setItem("token", res.data.token);
            sessionStorage.setItem("userInfo", JSON.stringify(res.data.data));
            this.$store.commit("setUserInfo", res.data.data);
            // location = "http://teacherhall.zhjsy.fit/listLessonByNameAdmin.action?";
            this.$router.push({ path: "/homePage" });
            sessionStorage.setItem(
              "navigateIndex",
              JSON.stringify({
                menuId: 0,
                menuIndex: "/homePage",
                menuIcon: "",
                menuName: "首页",
                childrens: [],
              })
            );
          }
          if (res.data.result == "fail") {
            this.$message({
              type: "error",
              message: res.data.msg,
            });
          }
          console.log("submit form", this[formName]);
        } else {
          // 表单验证不通过
          console.log("validate failed");
          return false;
        }
      });
    },
    sendSmsCode() {
      // 发送短信验证码
      console.log("send sms code");
      this.smsCodeDisabled = true;
      let count = 60;
      const timer = setInterval(() => {
        count--;
        if (count === 0) {
          clearInterval(timer);
          this.smsCodeText = "获取验证码";
          this.smsCodeDisabled = false;
        } else {
          this.smsCodeText = `${count}秒后重发`;
        }
      }, 1000);
    },
  },
};
</script>

<!-- CSS代码 -->
<style scoped>
.logo-and-name {
  width: 500px;
  height: 150px;
  margin: 0 auto;
  text-align: center;
  margin-top: 80px;
}
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-tabs {
  width: 400px;
}
.login-form {
  margin-top: 20px;
}
.loginBtn {
  margin-top: 30px;
  padding: 12px 188px;
}
#view_container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
</style>
