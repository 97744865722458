<template>
  <div style="margin-top: 50px; text-align: center">
    <h1>404 Not Found</h1>
    <p>访问页面不存在...<router-link to="/">返回首页</router-link></p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
