<template>
  <div>
    <el-card class="box-card">
      <div class="clearfix">
        <span> {{ unitData.companyName }}</span>
      </div>
    </el-card>
    <el-row style="margin: 20px 0">
      <el-col :span="8">
        <span>搜索内容：</span>
        <el-input
          style="width: 300px"
          v-model="searchValue"
          placeholder="请输入用户名/关键字"
          clearable
        ></el-input>
      </el-col>
      <el-col :span="6" style="text-align: center">
        <span>状态：</span>
        <el-select v-model="selectValue" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="启用" value="false"></el-option>
          <el-option label="禁用" value="true"></el-option>
        </el-select>
      </el-col>
      <el-col :span="6" style="text-align: center">
        <span>性别：</span>
        <el-select v-model="gender" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="男" value="1"></el-option>
          <el-option label="女" value="0"></el-option>
        </el-select>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="search">搜索</el-button>
        <!-- <el-button @click="reset">重置</el-button> -->
      </el-col>
    </el-row>
    <!-- <el-row>
      <el-button type="primary" @click="viewUnit">查看单位管理员</el-button>
    </el-row> -->
    <el-row>
      <el-col :span="3">
        <el-upload
          class="upload-demo"
          action="none"
          :auto-upload="false"
          :on-change="importCompanyUserExcel"
          multiple
          :limit="1"
          :file-list="fileList"
          :on-exceed="handleExceed"
          :show-file-list="false"
        >
          <el-button type="primary">单位成员导入</el-button>
        </el-upload>
      </el-col>
      <el-col :span="4" style="line-height: 40px">
        <a
          href="https://jwzjy.oss-cn-wulanchabu.aliyuncs.com/%E5%8D%95%E4%BD%8D%E4%BA%BA%E5%91%98%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx"
          >单位成员导入模板下载</a
        >
      </el-col>
    </el-row>
    <el-table :data="tableData" style="width: 100%; margin-top: 20px" border>
      <el-table-column type="selection"></el-table-column>
      <el-table-column prop="id" label="uni-id" width="100"></el-table-column>

      <el-table-column prop="name" label="姓名" width="150"></el-table-column>
      <el-table-column prop="wechatName" label="性别" width="200">
        <template slot-scope="scope">
          <span v-if="scope.row.sex">{{
            scope.row.sex == 1 ? "男" : "女"
          }}</span>
          <span v-else>未填写</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="educationPlatformPostion.positionName"
        label="职位"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="phone"
        label="联系电话"
        width="200"
      ></el-table-column>
      <el-table-column label="启用/禁用" width="200">
        <template slot-scope="scope">
          <el-radio-group
            v-model="scope.row.recovery"
            @change="changeStatus(scope.row)"
          >
            <el-radio :label="false">启用</el-radio>
            <el-radio :label="true">禁用</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <!-- <el-button type="info" @click="sendMessage(scope.row)"
            >发消息</el-button
          > -->
          <!-- <el-dialog
            title="给用户发消息"
            :visible.sync="dialogVisible"
            :close-on-click-modal="false"
          >
            <p>接收人：{{ sendMsgData.wechatName }}</p>
            <el-form>
              <el-form-item label="内容">
                <el-input type="textarea" v-model="messageContent"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="sendMessage">确 定</el-button>
            </div>
          </el-dialog> -->
          <el-button type="danger" @click="deleteUser(scope.row)"
            >删除</el-button
          >
          <el-button type="primary" @click="setUnitManager(scope.row)"
            >设为单位管理员</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  deleteCompanyUser,
  getCompanyUserList,
  updateCompanyUserStatus,
  updateCompanyUserAdmin,
  importCompanyUserExcel,
} from "@/api/unitManagement";

export default {
  data() {
    return {
      searchValue: "", // 搜索内容
      selectValue: "", // 启用状态
      gender: "", // 性别
      tableData: [],
      fileList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      dialogVisible: false, // 发消息弹出框
      sendMsgData: "", // 发送消息对象的信息
      messageContent: "", // 发送消息的内容
      unitData: "", // 单位信息
    };
  },
  created() {
    this.unitData = JSON.parse(sessionStorage.getItem("unitData"));
    this.getCompanyUserList();
  },
  destroyed() {
    sessionStorage.removeItem("unitData");
  },
  methods: {
    // 单位导入
    importCompanyUserExcel(file, fileList) {
      console.log(file, fileList);
      const isXlsx =
        file.raw.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.raw.type === "application/vnd.ms-excel";
      this.fileList = [];
      if (!isXlsx) {
        this.$message.error("上传文件只能是 Excel 表格!");
        return;
      }
      let formData = new FormData();
      formData.append("file", file.raw);
      formData.append("companyId", this.$route.query.id);
      console.log(formData);
      let result = importCompanyUserExcel(formData);
      console.log(result);
      result.then((res) => {
        console.log(res, "res");
        if (res.data.result == "success") {
          this.$message.success(res.data.msg);
          this.getCompanyUserList();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    // 获取单位成员列表
    async getCompanyUserList() {
      let res = await getCompanyUserList({
        companyId: this.$route.query.id, //公司id
        name: this.searchValue, //名称
        sex: this.gender, //性别
        status: this.selectValue, //状态	true禁用	false禁用
        startNum: this.currentPage, //起始页码
        pageSize: this.pageSize, //每页条数
      });
      if (res.data.result == "success") {
        this.tableData = res.data.list;
        this.total = res.data.listSize;
      }
      console.log(res, "getCompanyUserList");
    },
    search() {
      // 发送搜索请求
      this.getCompanyUserList();
    },
    reset() {
      this.searchValue = "";
      this.selectValue = "";
      this.search();
    },
    // 启用禁用切换
    async changeStatus(row) {
      console.log(row, "changeStatus");
      let res = await updateCompanyUserStatus({
        id: row.id,
        recovery: row.recovery,
      });
      if (res.data.result == "success") {
        this.$message({
          type: "success",
          message: res.data.msg,
        });
        this.getCompanyUserList();
      }
      console.log(res, "changeStatus");
    },
    viewUnit() {
      // 查看单位管理员
    },
    sendMessage(row) {
      // 发送消息
      console.log("sendMessage", row);
      this.sendMsgData = row;
      this.dialogVisible = true;
    },
    deleteUser(row) {
      // 删除用户
      console.log("deleteUser", row);
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await deleteCompanyUser({
            ids: row.id,
          });
          if (res.data.result == "success") {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
            this.getCompanyUserList();
          }
          console.log("deleteUser", res);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async setUnitManager(row) {
      // 设为单位管理员
      console.log("setUnitManager", row);
      let res = await updateCompanyUserAdmin({
        ids: row.id,
        // isAdmin: 1,
      });
      if (res.data.result == "success") {
        this.$message({
          type: "success",
          message: res.data.msg,
        });
        this.getCompanyUserList();
      }
      console.log("setAdmin", res);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.search();
    },
  },
};
</script>
<style scoped>
.el-button {
  margin: 0 10px;
}
</style>
