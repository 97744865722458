import axios from "../plugins/axios";

/**
 * 国家级管理员，所在政府部门
 */
export const queryNationEducationGovDepartmentByPower = () =>
  axios.get("/api/user/queryNationEducationGovDepartmentByPower");

/**
 * 省市区街道管理员，所在政府部门
 */
export const queryEducationGovDepartmentById = (param) =>
  axios.get("/api/user/queryEducationGovDepartmentById?id=" + param.id);

// 获取行政地区
export const findCityAllList = () => axios.get("api/county/findCityAllList"); // 所有行政地区
export const findProviceList = () => axios.get("api/county/findProviceList"); // 省
export const findCityList = (param) =>
  axios.get("api/county/findCityList?parentId=" + param.id); // 市
export const findDistrictList = (param) =>
  axios.get("api/county/findDistrictList?parentId=" + param.id); // 区
export const findStreetList = (param) =>
  axios.get("api/county/findStreetList?parentId=" + param.id); // 街道

//岗位列表
export const getAddPlatformPositionList = () =>
  axios.get("/course/platformTraining/getAddPlatformPositionList");

/**
 * 单位列表
 * @param {*} param
 * areaList 省市区街道id areaList=[{street:'',district:'',city:'',provice:''}]
 * companyName 单位名称
 * startNum 当前页码
 * pageSize 每页条数
 * @returns
 */
export const getAddCompanyList = (param) =>
  axios.post("/api/company/getAddCompanyList", param);

//课程列表
export const getAddTrainingList = () =>
  axios.get("/course/platformTraining/getAddTrainingList");

// 图片上传接口 param:file-文件
export const uploadFile = (param) => {
  return axios({
    url: "/course/oss/uploadFile",
    method: "post",
    headers: { "Content-Type": "multipart/form-data" },
    data: param,
  });
};
