import axios from "../plugins/axios";

// 用户协议与隐私政策
// 获取用户协议与隐私政策列表 type=0-用户协议 1-隐私政策
export const seleteAgreementList = (param) =>
  axios.get(
    "/course/agreement/seleteAgreementList?name=" +
      param.name +
      "&startNum=" +
      param.startNum +
      "&pageSize=" +
      param.pageSize +
      "&type=" +
      param.type
  );

/**
 * 保存
 * @param {*} param
 * "name":""//名称
 * "content":""//内容
 * @returns
 */
export const saveAgreement = (param) =>
  axios.post("/course/agreement/saveAgreement", param);

/**
 * 更新
 * @param {*} param
 * "id":""
 * "name":""//名称
 * "content":""//内容
 * @returns
 */
export const updateAgreement = (param) =>
  axios.post("/course/agreement/updateAgreement", param);

// 用户协议和隐私政策显示/隐藏 id recovery-true 禁用 false启用
export const updateAgreementStatus = (param) =>
  axios.post("/course/agreement/updateAgreementStatus", param);

// 用户协议和隐私政策查看详情
export const selectAgreement = (param) =>
  axios.get("/course/agreement/selectAgreement?id=" + param.id);
// 用户协议和隐私政策删除
export const deleteAgreement = (param) =>
  axios.get("/course/agreement/deleteAgreement?id=" + param.id);
