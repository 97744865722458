import axios from "../plugins/axios";

// 兑换码管理列表
export const listRedemptionCode = (params) =>
  axios.get(
    "/course/redemptionCode/listRedemptionCode?feedbackTime=" +
      params.feedbackTime +
      "&feedbackEndTime=" +
      params.feedbackEndTime +
      "&status=" +
      params.status +
      "&lessonId=" +
      params.lessonId +
      "&hourId=" +
      params.hourId +
      "&startNum=" +
      params.startNum +
      "&pageSize=" +
      params.pageSize
  );

/**
 * 兑换码新建
 * @param {validityDate,lessonId,hourId,redemptionCode} params
 * "number"://兑换码数量
 * "validityDate"://兑换码有效期
 * "lessonId"://课程id
 * "hourId"://课时id
 * @returns
 */
export const saveRedemptionCode = (params) =>
  axios.post("/course/redemptionCode/saveRedemptionCode", params);

// 兑换码删除
export const deleteRedemptionCode = (params) =>
  axios.get("/course/redemptionCode/deleteRedemptionCode?ids=" + params.ids);

/**
 * 兑换码导出
 * @param {feedbackTime,feedbackEndTime,status,ids} params
 * feedbackTime--创建起始时间
 * feedbackEndTime--创建结束时间
 * status--兑换码状态
 * ids--兑换码id
 * @returns
 */
export const redemptionCodeExport = (params) => {
  return axios({
    url: "/course/redemptionCode/export",
    method: "get",
    responseType: "blob",
    params,
  });
};
