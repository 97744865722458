<template>
  <div class="new-redeem-code">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="150px"
      class="demo-ruleForm"
    >
      <el-form-item label="兑换码数量" prop="number">
        <el-input-number
          @change="toInteger('number')"
          :min="1"
          :step="1"
          v-model.number="ruleForm.number"
        ></el-input-number>
      </el-form-item>
      <el-form-item>
        最多可一次创建200个，创建完成后，会生成对应数量的10位数的由字母和数字组成的随机数。
      </el-form-item>
      <el-form-item label="兑换码有效期" prop="validity">
        <el-input-number
          @change="toInteger('validity')"
          :min="1"
          :step="1"
          v-model.number="ruleForm.validity"
        ></el-input-number>
      </el-form-item>
      <el-form-item>
        有效期从创建的当天起计算，实际时间过了有效期后，该兑换码失效。
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >保存</el-button
        >
        <el-button type="" @click="$router.back()">取消</el-button>
        <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { saveRedemptionCode } from "@/api/redeemCodeManagement";
export default {
  data() {
    return {
      ruleForm: {
        number: 1,
        validity: 1,
      },
      rules: {
        number: [
          {
            required: true,
          },
        ],
        validity: [
          {
            required: true,
          },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = await saveRedemptionCode({
            number: this.ruleForm.number,
            validityDate: this.ruleForm.validity,
            lessonId: this.$route.query.lessonId,
            hourId: this.$route.query.hourId,
          });
          console.log(res);
          if (res.data.result == "success") {
            this.$message.success(res.data.msg);
            this.$router.go(-1);
          } else {
            this.$message.success("保存失败");
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    toInteger(key) {
      let reg = /^[0-9]+$/;
      if (this.ruleForm[key] > 200 && key == "number") {
        this.$message.warning("兑换码数量最大不超过200");
        this.$nextTick(() => {
          this.ruleForm[key] = 1;
        });
        return;
      }
      if (!reg.test(this.ruleForm[key])) {
        this.$message.warning("只能输入整数");
        // 用以在dom渲染挂载后重新触发dom渲染挂载
        this.$nextTick(() => {
          this.ruleForm[key] = parseInt(this.ruleForm[key]);
        });
      }
    },
  },
};
</script>

<style scoped>
.new-redeem-code {
  width: 50%;
  padding: 50px;
}
</style>
