import { render, staticRenderFns } from "./newAnnouncement.vue?vue&type=template&id=21e2be2d&scoped=true&"
import script from "./newAnnouncement.vue?vue&type=script&lang=js&"
export * from "./newAnnouncement.vue?vue&type=script&lang=js&"
import style0 from "./newAnnouncement.vue?vue&type=style&index=0&id=21e2be2d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21e2be2d",
  null
  
)

export default component.exports