<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span> 公告管理</span>
      </div>
      <div class="text item" style="font-size: 14px">
        对轮播图进行管理的模块。
      </div>
      <!-- <el-button @click="study">查看学习数据</el-button> -->
    </el-card>
    <el-row style="margin: 20px">
      <el-button type="primary" @click="createCarousel">新建公告</el-button>
    </el-row>
    <el-table :data="tableData" border>
      <el-table-column prop="title" label="公告名称"></el-table-column>
      <el-table-column prop="inTime" label="创建时间"> </el-table-column>
      <el-table-column label="是否显示">
        <template slot-scope="scope">
          <el-radio-group
            v-model="scope.row.recovery"
            @change="updateSidershowStatus(scope.row)"
          >
            <el-radio :label="false">显示</el-radio>
            <el-radio :label="true">隐藏</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click="editCarousel(scope.row)"
            >修改</el-button
          >
          <el-button type="danger" @click="deleteCarousel(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  getSlidelList,
  moveSlideshow,
  updateSidershowStatus,
  slideshowDelete,
} from "@/api/slideshowManagement";
import { msToDate } from "@/plugins/msToDate";
export default {
  data() {
    return {
      search: "",
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      srcList: [],
    };
  },
  created() {
    this.getSlidelList();
  },
  methods: {
    async getSlidelList() {
      let res = await getSlidelList({
        name: this.search,
        startNum: this.currentPage,
        pageSize: this.pageSize,
        type: 1,
      });
      if (res.data.result == "success") {
        res.data.list.forEach((element) => {
          element.inTime = msToDate(element.inTime);
        });
        this.tableData = res.data.list;
        this.total = res.data.size;
      }
    },
    // 排序上移下移
    async moveSlideshow(type, index) {
      let id2 = "";
      if (type == "up" && this.tableData[index - 1]) {
        id2 = this.tableData[index - 1].id;
      } else if (type == "down" && this.tableData[index + 1]) {
        id2 = this.tableData[index + 1].id;
      } else {
        this.$message({
          message: type == "up" ? "已经是第一行了" : "已经是最后一行了",
          type: "warning",
        });
        return;
      }
      let res = await moveSlideshow({
        id1: this.tableData[index].id,
        id2: id2,
      });
      if (res.data.result == "success") {
        this.$message({
          message: "修改成功",
          type: "success",
        });
        this.getSlidelList();
      }
    },
    // 轮播图显示/隐藏
    async updateSidershowStatus(row) {
      let res = await updateSidershowStatus({
        id: row.id,
        recovery: row.recovery,
      });
      if (res.data.result == "success") {
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        this.getSlidelList();
      }
    },
    searchCarousel() {
      // Implement search logic here
      this.getSlidelList();
    },
    createCarousel() {
      // Implement create logic here
      this.$router.push({
        path: "/newAnnouncement",
      });
    },
    editCarousel(row) {
      // Implement edit logic here
      this.$router.push({
        path: "/editAnnouncement",
        query: { id: row.id },
      });
    },
    deleteCarousel(row) {
      // Implement delete logic here
      console.log(row);
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await slideshowDelete({
            id: row.id,
          });
          if (res.data.result == "success") {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getSlidelList();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.searchCarousel();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchCarousel();
    },
  },
};
</script>
<style scoped>
.icon {
  font-size: 32px;
  display: flex;
  justify-content: space-around;
}
</style>
