// 平台管理员访问导航 menuLevel: 1, // 菜单级别 1一级菜单 2二级菜单 3三级菜单
export const SUCCESS_DATA = {
  data: [
    {
      menuId: 0,
      menuIndex: "/homePage", //  /homePage
      // el-icon-pie-chart

      menuIcon: "el-icon-s-operation",
      menuName: "首页",
      menuLevel: 1,
      childrens: [],
    },
    {
      menuId: 0.1,
      menuIndex: "/dataCenter", //  /homePage
      // el-icon-pie-chart

      menuIcon: "el-icon-s-operation",
      link: "https://datav.aliyun.com/share/page/1f61b7ca3a6a2d498b28573041831cfc",
      menuName: "数据中心",
      menuLevel: 1,
      childrens: [],
    },
    {
      menuId: 1,
      menuIndex: "/user",
      menuIcon: "el-icon-user",
      menuName: "用户管理",
      menuLevel: 2,
      childrens: [
        {
          menuId: 2,
          menuIndex: "/domesticConsumer",
          menuIcon: "",
          menuName: "普通用户管理",
          childrens: [],
        },
        // {
        //   menuId: 3,
        //   menuIndex: "/adminManagement",
        //   menuIcon: "",
        //   menuName: "管理员管理",
        //   childrens: [],
        // },
        {
          menuId: 2.1,
          menuIndex: "/platformManagement",
          menuIcon: "",
          menuName: "平台管理员管理",
          childrens: [],
        },
        {
          menuId: 2.2,
          menuIndex: "/platformNationalManagement",
          menuIcon: "",
          menuName: "国家级管理员管理",
          childrens: [],
        },
        {
          menuId: 2.3,
          menuIndex: "/platformProviceManagement",
          menuIcon: "",
          menuName: "省级管理员管理",
          childrens: [],
        },
        {
          menuId: 2.4,
          menuIndex: "/platformCityManagement",
          menuIcon: "",
          menuName: "市级管理员管理",
          childrens: [],
        },
        {
          menuId: 2.5,
          menuIndex: "/platformDistrictManagement",
          menuIcon: "",
          menuName: "区（县）级管理员管理",
          childrens: [],
        },
        {
          menuId: 2.6,
          menuIndex: "/platformStreetManagement",
          menuIcon: "",
          menuName: "街道级管理员管理",
          childrens: [],
        },
      ],
    },
    {
      menuId: 4,
      menuIndex: "/organization",
      menuIcon: "el-icon-office-building",
      menuName: "组织机构管理",
      menuLevel: 2,
      childrens: [
        {
          menuId: 4.0,
          menuIndex: "/unitManagement",
          menuIcon: "",
          menuName: "单位管理",
          childrens: [],
        },
        {
          menuId: 4.1,
          menuIndex: "/governmentSectorList",
          menuIcon: "",
          menuName: "政府部门管理",
          childrens: [],
        },
        {
          menuId: 4.2,
          menuIndex: "/trainingInstitutionList",
          menuIcon: "",
          menuName: "培训机构管理",
          childrens: [],
        },
      ],
    },
    // {
    //   menuId: 7,
    //   menuIndex: "/auth",
    //   menuIcon: "el-icon-coordinate",
    //   menuName: "认证管理",
    //   menuLevel: 2,
    //   childrens: [
    //     {
    //       menuId: 8,
    //       menuIndex: "/unitAuthentication",
    //       menuIcon: "",
    //       menuName: "单位认证",
    //       childrens: [],
    //     },
    //   ],
    // },
    {
      menuId: 9,
      menuIndex: "/course",
      menuIcon: "el-icon-collection",
      menuName: "课程管理",
      menuLevel: 2,
      childrens: [
        {
          menuId: 11,
          menuIndex: "/courseList",
          menuIcon: "",
          menuName: "课程列表",
          childrens: [],
        },
        // {
        //   menuId: 12,
        //   menuIndex: "/question",
        //   menuIcon: "",
        //   menuName: "问答管理",
        //   childrens: [],
        // },
      ],
    },
    // {
    //   menuId: 13,
    //   menuIndex: "/sendMessage",
    //   menuIcon: "el-icon-chat-dot-square",
    //   menuName: "发消息",
    //   menuLevel: 1,
    //   childrens: [],
    // },
    {
      menuId: 14,
      menuIndex: "/symSetting",
      menuIcon: "el-icon-s-platform",
      menuName: "系统设置",
      menuLevel: 2,
      childrens: [
        {
          menuId: 14.0,
          menuIndex: "/slideshowList",
          menuIcon: "",
          menuName: "轮播图管理",
          childrens: [],
        },
        // {
        //   menuId: 14.1,
        //   menuIndex: "/announcementList",
        //   menuIcon: "",
        //   menuName: "公告管理",
        //   childrens: [],
        // },
        {
          menuId: 14.1,
          menuIndex: "/PositionManagement",
          menuIcon: "",
          menuName: "岗位管理",
          childrens: [],
        },
        {
          menuId: 14.2,
          menuIndex: "/userAgreementList",
          menuIcon: "",
          menuName: "用户协议管理",
          childrens: [],
        },
        {
          menuId: 14.3,
          menuIndex: "/privacyPolicyList",
          menuIcon: "",
          menuName: "隐私政策管理",
          childrens: [],
        },
        // {
        //   menuId: 14.4,
        //   menuIndex: "/helpDocumentList",
        //   menuIcon: "",
        //   menuName: "帮助文档管理",
        //   childrens: [],
        // },
        // {
        //   menuId: 14.5,
        //   menuIndex: "/feedbackList",
        //   menuIcon: "",
        //   menuName: "反馈管理",
        //   childrens: [],
        // },
        // {
        //   menuId: 14.6,
        //   menuIndex: "/refundPasswordSetting",
        //   menuIcon: "",
        //   menuName: "退款密码设置",
        //   childrens: [],
        // },
      ],
      // childrens: [
      //   {
      //     menuId: 15,
      //     menuIndex: "/example",
      //     menuIcon: "",
      //     menuName: "示例管理",
      //     childrens: [
      //       {
      //         menuId: 16,
      //         menuIndex: "/imageText",
      //         menuIcon: "",
      //         menuName: "图文类型示例",
      //         childrens: [],
      //       },
      //       {
      //         menuId: 17,
      //         menuIndex: "/videoExample",
      //         menuIcon: "",
      //         menuName: "视频类型示例",
      //         childrens: [],
      //       },
      //     ],
      //   },
      //   {
      //     menuId: 18,
      //     menuIndex: "/earnings",
      //     menuIcon: "",
      //     menuName: "收益参数设置",
      //     childrens: [],
      //   },
      // ],
    },
    {
      menuId: 19,
      menuIndex: "/classesManagement",
      menuIcon: "el-icon-notebook-1",
      menuName: "课程类别管理",
      menuLevel: 1,
      childrens: [],
    },
    {
      menuId: 20,
      menuIndex: "/trainingAdministration",
      menuIcon: "el-icon-collection",
      menuName: "培训管理",
      menuLevel: 2,
      childrens: [
        {
          menuId: 21,
          menuIndex: "/trainingSetupList",
          menuIcon: "",
          menuName: "培训设置",
          childrens: [],
        },
        {
          menuId: 22,
          menuIndex: "/trainingInquiryList",
          menuIcon: "",
          menuName: "培训查询情况",
          childrens: [],
        },
        {
          menuId: 22.1,
          menuIndex: "/examinationList",
          menuIcon: "",
          menuName: "考试列表",
          childrens: [],
        },
      ],
    },
    {
      menuId: 23,
      menuIndex: "/invoiceReviewList",
      menuIcon: "el-icon-s-ticket",
      menuName: "发票审核",
      menuLevel: 1,
      childrens: [],
    },
    {
      menuId: 14.1,
      menuIndex: "/certificateManagementList",
      menuIcon: "el-icon-notebook-2",
      menuName: "证书模板管理",
      menuLevel: 1,
      childrens: [],
    },
    {
      menuId: 24,
      menuIndex: "/operationManagementList",
      menuIcon: "el-icon-setting",
      menuName: "运维管理",
      menuLevel: 2, // 菜单级别 1一级菜单 2二级菜单 3三级菜单
      childrens: [
        {
          menuId: 24.1,
          menuIndex: "/orderForGoods",
          menuIcon: "",
          menuName: "订单导出",
          childrens: [],
        },
      ],
    },
  ],
};

// 国家级管理员访问路由
export const NATIONAL_MENU_DATA = {
  data: [
    {
      menuId: 0,
      menuIndex: "/homePage",
      menuIcon: "el-icon-s-operation",
      menuName: "首页",
      menuLevel: 1,
      childrens: [],
    },
    {
      menuId: 1,
      menuIndex: "/user",
      menuIcon: "el-icon-user",
      menuName: "用户管理",
      menuLevel: 2,
      childrens: [
        {
          menuId: 2.2,
          menuIndex: "/platformNationalManagement",
          menuIcon: "",
          menuName: "国家级管理员管理",
          childrens: [],
        },
        {
          menuId: 2.3,
          menuIndex: "/platformProviceManagement",
          menuIcon: "",
          menuName: "省级管理员管理",
          childrens: [],
        },
        {
          menuId: 2.4,
          menuIndex: "/platformCityManagement",
          menuIcon: "",
          menuName: "市级管理员管理",
          childrens: [],
        },
        {
          menuId: 2.5,
          menuIndex: "/platformDistrictManagement",
          menuIcon: "",
          menuName: "区（县）级管理员管理",
          childrens: [],
        },
        {
          menuId: 2.6,
          menuIndex: "/platformStreetManagement",
          menuIcon: "",
          menuName: "街道级管理员管理",
          childrens: [],
        },
      ],
    },
    {
      menuId: 20,
      menuIndex: "/trainingAdministration",
      menuIcon: "el-icon-collection",
      menuName: "培训管理",
      menuLevel: 2,
      childrens: [
        // {
        //   menuId: 21,
        //   menuIndex: "/trainingSetupList",
        //   menuIcon: "",
        //   menuName: "培训设置",
        //   childrens: [],
        // },
        {
          menuId: 22,
          menuIndex: "/trainingInquiryList",
          menuIcon: "",
          menuName: "培训查询情况",
          childrens: [],
        },
      ],
    },
  ],
};
// 省级管理员访问导航
export const PROVICE_MENU_DATA = {
  data: [
    {
      menuId: 0,
      menuIndex: "/homePage", //  /homePage
      // el-icon-pie-chart

      menuIcon: "el-icon-s-operation",
      menuName: "首页",
      menuLevel: 1,
      childrens: [],
    },
    {
      menuId: 1,
      menuIndex: "/user",
      menuIcon: "el-icon-user",
      menuName: "用户管理",
      menuLevel: 2,
      childrens: [
        {
          menuId: 2.3,
          menuIndex: "/platformProviceManagement",
          menuIcon: "",
          menuName: "省级管理员管理",
          childrens: [],
        },
        {
          menuId: 2.4,
          menuIndex: "/platformCityManagement",
          menuIcon: "",
          menuName: "市级管理员管理",
          childrens: [],
        },
        {
          menuId: 2.5,
          menuIndex: "/platformDistrictManagement",
          menuIcon: "",
          menuName: "区（县）级管理员管理",
          childrens: [],
        },
        {
          menuId: 2.6,
          menuIndex: "/platformStreetManagement",
          menuIcon: "",
          menuName: "街道级管理员管理",
          childrens: [],
        },
      ],
    },
    {
      menuId: 20,
      menuIndex: "/trainingAdministration",
      menuIcon: "el-icon-collection",
      menuName: "培训管理",
      menuLevel: 2,
      childrens: [
        {
          menuId: 21,
          menuIndex: "/trainingSetupList",
          menuIcon: "",
          menuName: "培训设置",
          childrens: [],
        },
        {
          menuId: 22,
          menuIndex: "/trainingInquiryList",
          menuIcon: "",
          menuName: "培训查询情况",
          childrens: [],
        },
      ],
    },
  ],
};

// 市级管理员访问导航
export const CITY_MENU_DATA = {
  data: [
    {
      menuId: 0,
      menuIndex: "/homePage", //  /homePage
      // el-icon-pie-chart

      menuIcon: "el-icon-s-operation",
      menuName: "首页",
      menuLevel: 1,
      childrens: [],
    },
    // {
    //   menuId: 1,
    //   menuIndex: "/user",
    //   menuIcon: "el-icon-user",
    //   menuName: "用户管理",
    //   menuLevel: 2,
    //   childrens: [
    //     {
    //       menuId: 2.4,
    //       menuIndex: "/platformCityManagement",
    //       menuIcon: "",
    //       menuName: "市级管理员管理",
    //       childrens: [],
    //     },
    //     {
    //       menuId: 2.5,
    //       menuIndex: "/platformDistrictManagement",
    //       menuIcon: "",
    //       menuName: "区（县）级管理员管理",
    //       childrens: [],
    //     },
    //     {
    //       menuId: 2.6,
    //       menuIndex: "/platformStreetManagement",
    //       menuIcon: "",
    //       menuName: "街道级管理员管理",
    //       childrens: [],
    //     },
    //   ],
    // },
    {
      menuId: 20,
      menuIndex: "/trainingAdministration",
      menuIcon: "el-icon-collection",
      menuName: "培训管理",
      menuLevel: 2,
      childrens: [
        // {
        //   menuId: 21,
        //   menuIndex: "/trainingSetupList",
        //   menuIcon: "",
        //   menuName: "培训设置",
        //   childrens: [],
        // },
        {
          menuId: 22,
          menuIndex: "/trainingInquiryList",
          menuIcon: "",
          menuName: "培训查询情况",
          childrens: [],
        },
      ],
    },
  ],
};
// 区级管理员访问导航
export const DISTRICT_MENU_DATA = {
  data: [
    {
      menuId: 0,
      menuIndex: "/homePage", //  /homePage
      // el-icon-pie-chart

      menuIcon: "el-icon-s-operation",
      menuName: "首页",
      menuLevel: 1,
      childrens: [],
    },
    {
      menuId: 1,
      menuIndex: "/user",
      menuIcon: "el-icon-user",
      menuName: "用户管理",
      menuLevel: 2,
      childrens: [
        {
          menuId: 2.5,
          menuIndex: "/platformDistrictManagement",
          menuIcon: "",
          menuName: "区（县）级管理员管理",
          childrens: [],
        },
        {
          menuId: 2.6,
          menuIndex: "/platformStreetManagement",
          menuIcon: "",
          menuName: "街道级管理员管理",
          childrens: [],
        },
      ],
    },
    {
      menuId: 20,
      menuIndex: "/trainingAdministration",
      menuIcon: "el-icon-collection",
      menuName: "培训管理",
      menuLevel: 2,
      childrens: [
        // {
        //   menuId: 21,
        //   menuIndex: "/trainingSetupList",
        //   menuIcon: "",
        //   menuName: "培训设置",
        //   childrens: [],
        // },
        {
          menuId: 22,
          menuIndex: "/trainingInquiryList",
          menuIcon: "",
          menuName: "培训查询情况",
          childrens: [],
        },
      ],
    },
  ],
};
// 街道级管理员访问导航
export const STREET_MENU_DATA = {
  data: [
    {
      menuId: 0,
      menuIndex: "/homePage", //  /homePage
      // el-icon-pie-chart

      menuIcon: "el-icon-s-operation",
      menuName: "首页",
      menuLevel: 1,
      childrens: [],
    },
    {
      menuId: 1,
      menuIndex: "/user",
      menuIcon: "el-icon-user",
      menuName: "用户管理",
      menuLevel: 2,
      childrens: [
        {
          menuId: 2.6,
          menuIndex: "/platformStreetManagement",
          menuIcon: "",
          menuName: "街道级管理员管理",
          childrens: [],
        },
      ],
    },
    {
      menuId: 20,
      menuIndex: "/trainingAdministration",
      menuIcon: "el-icon-collection",
      menuName: "培训管理",
      menuLevel: 2,
      childrens: [
        // {
        //   menuId: 21,
        //   menuIndex: "/trainingSetupList",
        //   menuIcon: "",
        //   menuName: "培训设置",
        //   childrens: [],
        // },
        {
          menuId: 22,
          menuIndex: "/trainingInquiryList",
          menuIcon: "",
          menuName: "培训查询情况",
          childrens: [],
        },
      ],
    },
  ],
};

// 培训机构管理员
export const TRAINING_ADMINISTRATOR_MENU_DATA = {
  data: [
    {
      menuId: 0,
      menuIndex: "/homePage",
      menuIcon: "el-icon-s-operation",
      menuName: "首页",
      menuLevel: 1,
      childrens: [],
    },
    {
      menuId: 1,
      menuIndex: "/trainingInstitutionCourseList",
      menuIcon: "el-icon-collection",
      menuName: "课程管理",
      menuLevel: 1,
      childrens: [],
    },
    {
      menuId: 2,
      menuIndex: "/trainingAdministration",
      menuIcon: "el-icon-collection",
      menuName: "培训管理",
      menuLevel: 2,
      childrens: [
        {
          menuId: 2.1,
          menuIndex: "/trainingSetupList",
          menuIcon: "",
          menuName: "培训设置",
          childrens: [],
        },
      ],
    },
  ],
};
