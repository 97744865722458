<template>
  <div style="margin-top: 50px; text-align: center">
    <h1>权限无法访问...</h1>
    <router-link to="/">返回首页</router-link>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
