<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span> 退款密码设置</span>
      </div>
      <div class="text item" style="font-size: 14px">
        对进行课程退款操作的验证密码进行设置的模块。
      </div>
    </el-card>
    <div class="refundPasswordSetting">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
      >
        <el-form-item prop="phone">
          <div class="phoneSetting">
            <div>密码修改验证手机号</div>
            <div style="margin-top: 20px">
              <span>手机号：{{ ruleForm.phone }}</span>
              <span style="margin-left: 20px">
                <el-button
                  v-if="!ruleForm.phone"
                  type="text"
                  @click="phoneSetting"
                  >点击设置</el-button
                >
                <el-button v-else type="text" @click="updatePhone"
                  >修改</el-button
                >
              </span>
            </div>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >设置退款密码</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- 设置手机号 -->
    <el-dialog
      title="设置手机号"
      :visible.sync="phoneSettingShow"
      width="30%"
      center
    >
      <template v-if="!ruleForm.phone">
        <el-form
          ref="settingPhoneForm"
          :model="settingPhoneForm"
          :rules="settingPhoneRules"
        >
          <!-- 手机号 -->
          <el-form-item prop="phone">
            <el-input
              prefix-icon="el-icon-phone"
              v-model="settingPhoneForm.phone"
              placeholder="请输入手机号"
              clearable
            ></el-input>
          </el-form-item>
          <!-- 验证码 -->
          <el-form-item prop="smsCode">
            <el-input
              prefix-icon="el-icon-message"
              v-model="settingPhoneForm.smsCode"
              placeholder="请输入验证码"
              style="width: 350px; margin-right: 20px"
            ></el-input>
            <el-button
              type="primary"
              :disabled="smsCodeDisabled"
              @click="sendSmsCode"
              >{{ smsCodeText }}</el-button
            >
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="phoneSettingShow = false">取 消</el-button>
          <el-button
            type="primary"
            @click="phoneSettingInitial('settingPhoneForm')"
            >保 存</el-button
          >
        </span>
      </template>
      <template v-else>
        <div style="margin-bottom: 20px">原手机号：{{ ruleForm.phone }}</div>
        <el-form
          ref="updatePhoneForm"
          :model="updatePhoneForm"
          :rules="settingPhoneRules"
        >
          <!-- 原手机验证码 -->
          <el-form-item prop="smsCode">
            <el-input
              prefix-icon="el-icon-message"
              v-model="updatePhoneForm.newSmsCode"
              placeholder="请输入原手机号验证码"
              style="width: 350px; margin-right: 20px"
            ></el-input>
            <el-button
              type="primary"
              :disabled="smsCodeDisabled"
              @click="sendSmsCode"
              >{{ smsCodeText }}</el-button
            >
          </el-form-item>
          <!-- 新手机号 -->
          <el-form-item prop="phone">
            <el-input
              prefix-icon="el-icon-phone"
              v-model="updatePhoneForm.newPhone"
              placeholder="请输入手机号"
              clearable
            ></el-input>
          </el-form-item>
          <!-- 新手机号验证码 -->
          <el-form-item prop="smsCode">
            <el-input
              prefix-icon="el-icon-message"
              v-model="updatePhoneForm.oldSmsCode"
              placeholder="请输入新手机号验证码"
              style="width: 350px; margin-right: 20px"
            ></el-input>
            <el-button
              type="primary"
              :disabled="newSmsCodeDisabled"
              @click="newSendSmsCode"
              >{{ newSmsCodeText }}</el-button
            >
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="phoneSettingShow = false">取 消</el-button>
          <el-button
            type="primary"
            @click="phoneSettingInitial('updatePhoneForm')"
            >保 存</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- 设置退款密码 -->
    <el-dialog
      title="设置退款密码"
      :visible.sync="setReundPasswordShow"
      width="30%"
      center
    >
      <div style="margin-bottom: 20px">手机号：{{ ruleForm.phone }}</div>
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
        <el-form-item label="设置密码" prop="password">
          <el-input
            show-password
            type="password"
            v-model="ruleForm.password"
            placeholder="请输入账号密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="再次输入密码" prop="verifyPassword">
          <el-input
            show-password
            type="password"
            v-model="ruleForm.verifyPassword"
            placeholder="请输入账号密码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="smsCode">
          <el-input
            prefix-icon="el-icon-message"
            v-model="ruleForm.smsCode"
            placeholder="请输入原手机号验证码"
            style="width: 350px; margin-right: 20px"
          ></el-input>
          <el-button
            type="primary"
            :disabled="smsCodeDisabled"
            @click="sendSmsCode"
            >{{ smsCodeText }}</el-button
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setReundPasswordShow = false">取 消</el-button>
        <el-button type="primary" @click="setReundPassword('ruleForm')"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      governmentData: "",
      phoneSettingShow: false,
      setReundPasswordShow: false,
      smsCodeDisabled: false,
      smsCodeText: "获取验证码",
      ruleForm: {
        password: "",
        verifyPassword: "",
        phone: "",
        smsCode: "",
      },
      rules: {
        password: [
          {
            required: true,
            message: "至少6位密码，区分大小写",
            trigger: "blur",
          },
          {
            // pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}/,
            pattern: /[a-zA-Z\d]{6,}/,
            message: "至少6位密码，区分大小写",
            trigger: "blur",
          },
        ],
        verifyPassword: [
          { required: true, message: "确认密码", trigger: "blur" },
          {
            // pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}/,
            pattern: /[a-zA-Z\d]{6,}/,
            message: "至少6位密码，区分大小写",
            trigger: "blur",
          },
        ],
        smsCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      //   初次设置手机号
      settingPhoneForm: {
        phone: "",
        smsCode: "",
      },
      settingPhoneRules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        smsCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      //   修改手机号
      newSmsCodeDisabled: false,
      newSmsCodeText: "获取验证码",
      updatePhoneForm: {
        oldSmsCode: "",
        newSmsCode: "",
        newPhone: "",
      },
      updatePhoneRules: {
        newSmsCode: [
          { required: true, message: "请输入新手机号验证码", trigger: "blur" },
        ],
        newPhone: [
          { required: true, message: "请输入新手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        oldSmsCode: [
          { required: true, message: "请输入原手机号验证码", trigger: "blur" },
        ],
      },
    };
  },
  created() {},
  methods: {
    //
    submitForm() {
      if (!this.ruleForm.phone) {
        this.$message({
          message: "请设置手机号",
          type: "warning",
        });
        return;
      }
      this.setReundPasswordShow = true;
    },
    setReundPassword(formName) {
      if (this.ruleForm.password != this.ruleForm.verifyPassword) {
        this.$message({
          message: "请确认两次密码输入是否一致",
          type: "warning",
        });
        return;
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          console.log(valid);
        }
      });
    },
    // 初次手机号设置
    phoneSettingInitial(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          console.log(valid);
        } else {
          console.log("validate failed");
          return false;
        }
      });
    },
    // 点击设置手机号
    phoneSetting() {
      this.phoneSettingShow = true;
    },
    // 修改手机号
    updatePhone() {
      this.phoneSettingShow = true;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    onCancel() {
      this.$router.back();
    },
    async sendSmsCode() {
      // 发送短信验证码
      this.smsCodeDisabled = true;
      let count = 60;
      const timer = setInterval(() => {
        count--;
        if (count === 0) {
          clearInterval(timer);
          this.smsCodeText = "获取验证码";
          this.smsCodeDisabled = false;
        } else {
          this.smsCodeText = `${count}秒后重发`;
        }
      }, 1000);
    },
    async newSendSmsCode() {
      // 发送短信验证码
      this.newSmsCodeDisabled = true;
      let count = 60;
      const timer = setInterval(() => {
        count--;
        if (count === 0) {
          clearInterval(timer);
          this.newSmsCodeText = "获取验证码";
          this.newSmsCodeDisabled = false;
        } else {
          this.newSmsCodeText = `${count}秒后重发`;
        }
      }, 1000);
    },
  },
};
</script>

<style scoped>
.refundPasswordSetting {
  width: 50%;
  margin-top: 30px;
}
.refundPasswordSetting .phoneSetting {
  padding: 20px;
  border: 1px solid #dcdfe6;
}
</style>
