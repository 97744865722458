<template>
  <div>
    <el-row style="margin: 20px 0">
      <el-col :span="6">
        <el-input
          v-model="searchText"
          placeholder="请输入用户名/关键字"
          @keyup.enter.native="search"
          clearable
        />
      </el-col>
      <el-col :span="6" style="text-align: center">
        <el-select v-model="selectedUnit" placeholder="请选择" @change="search">
          <el-option
            v-for="status in status"
            :key="status.label"
            :label="status.label"
            :value="status.value"
          />
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="search">查询</el-button>
        <!-- <el-button type="" @click="reset">重置</el-button> -->
      </el-col>
    </el-row>
    <el-row style="margin: 20px 0">
      <el-col :span="6">
        <el-button
          type="primary"
          @click="addUser"
          v-if="$store.state.userIsPrimaryAdmin == 1"
          >添加平台管理员</el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="tableData"
      @selection-change="handleSelectionChange"
      border
    >
      <el-table-column type="selection" align="center" />
      <el-table-column
        prop="userCode"
        label="用户编码"
        width="120"
        align="center"
      />
      <el-table-column label="姓名" align="center">
        <template slot-scope="scope">
          <span
            style="
              padding: 6px 5px 7px 9px;
              background-color: red;
              color: #fff;
              border-radius: 50%;
              font-size: 12px;
              margin-right: 5px;
            "
            v-if="scope.row.isPrimaryAdmin == 1"
          >
            主
          </span>
          <span>
            {{ scope.row.name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="account" label="账号" align="center" />
      <el-table-column label="联系电话" align="center">
        <template slot-scope="scope">
          <span>{{
            scope.row.phone.substring(0, 3) +
            "****" +
            scope.row.phone.substring(7, 11)
          }}</span>
        </template>
      </el-table-column>
      <template v-if="$store.state.userIsPrimaryAdmin == 1">
        <el-table-column label="启用/禁用" width="200" align="center">
          <template slot-scope="scope">
            <el-radio-group
              v-model="scope.row.recovery"
              @change="changeStatus(scope.row)"
            >
              <el-radio :label="false">启用</el-radio>
              <el-radio :label="true">禁用</el-radio>
            </el-radio-group>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="600" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" @click="updateAdminRole(scope.row)"
              >更改管理员角色</el-button
            ><el-button
              type="primary"
              @click="SetAsMasterAdministrator(scope.row)"
              >设置为主管理员</el-button
            >
            <el-button type="primary" @click="editAdmin(scope.row)"
              >编辑</el-button
            >
            <el-button type="danger" @click="deleteUser(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </template>
    </el-table>
    <el-pagination
      :total="total"
      :page-size="pageSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
    />
  </div>
</template>

<script>
import {
  getAdminUserListNew,
  updateUserStatus,
  deleteUser,
  updateIsAdmin,
} from "@/api/userManagement";
export default {
  data() {
    return {
      searchText: "",
      selectedUnit: "",
      messageContent: "",
      tableData: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      status: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "启用",
          value: true,
        },
        {
          label: "禁用",
          value: false,
        },
      ],
      selectedRows: [],
    };
  },
  created() {
    this.getAdminUserListNew();
  },
  methods: {
    // 获取管理员用户列表
    async getAdminUserListNew() {
      let res = await getAdminUserListNew({
        name: this.searchText, //名称
        power: 1,
        proviceId: "",
        cityId: "",
        regionId: "",
        streetId: "",
        status: this.selectedUnit, //状态	true禁用	false禁用
        startNum: this.currentPage, //起始页码
        pageSize: this.pageSize, //每页条数
      });
      console.log(res, "getAdminUserListNew");
      if (res.data.result == "success") {
        this.tableData = res.data.list;
        this.total = res.data.size;
      }
    },
    search() {
      // 发送搜索请求
      console.log("search", this.searchText, this.selectedUnit);
      this.getAdminUserListNew();
    },
    // 重置
    reset() {
      this.searchText = "";
      this.selectedUnit = "";
    },
    handleCurrentChange(currentPage) {
      // 发送分页请求
      console.log("handleCurrentChange", currentPage);
      this.currentPage = currentPage;
      this.search();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search();
    },
    handleSelectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    async changeStatus(row) {
      // 发送修改状态请求
      console.log("changeStatus", row);
      let res = await updateUserStatus({ id: row.id, recovery: row.recovery });
      if (res.data.result == "success") {
        this.$message({
          type: "success",
          message: res.data.msg,
        });
        this.getAdminUserListNew();
      }
      console.log(res, "changeStatus");
    },
    sendMessage(row) {
      console.log("sendMessage", row);
      this.dialogVisible = true;
    },
    // 删除
    deleteUser(row) {
      console.log("deleteUser", row);
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await deleteUser({
            ids: row.id,
          });
          console.log("deleteUser", res);
          if (res.data.result == "success") {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
          }
          this.getAdminUserListNew();
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    // 设为主管理员
    async SetAsMasterAdministrator(row) {
      let res = await updateIsAdmin({ userId: row.id });
      console.log(res, "SetAsMasterAdministrator");
      if (res.data.result == "success") {
        this.$message({
          type: "success",
          message: res.data.msg,
        });
        this.search();
      } else {
        this.$message({
          type: "error",
          message: res.data.msg,
        });
      }
    },

    // 编辑管理员
    editAdmin(row) {
      this.$router.push({ path: "/editPlatFormAdmin", query: { id: row.id } });
    },
    // 跳转到添加平台管理员
    addUser() {
      this.$router.push({
        path: "/addPlatFormAdmin",
      });
    },
    // 跳转到更改管理员角色
    updateAdminRole(row) {
      this.$router.push({
        path: "/updateAdmin",
      });
      sessionStorage.setItem("adminRoleData", JSON.stringify(row));
    },
  },
};
</script>
<style scoped>
.el-button {
  margin: 0 10px;
}
::v-deep.el-table .cell {
  height: 50px;
  line-height: 50px;
}
</style>
