<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span> 编辑课程类别</span>
      </div>
      <div class="text item" style="font-size: 14px">编辑课程类别</div>
    </el-card>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="150px"
      class="demo-ruleForm"
    >
      <el-form-item label="课程类别名称" prop="category">
        <el-input
          v-model="form.category"
          placeholder="请输入课程类别名称"
          style="width: 500px"
          maxlength="30"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item
        v-for="(domain, index) in form.subclassification"
        :label="index > 0 ? '子分类' + index : '子分类'"
        :key="domain.key"
        :prop="'subclassification.' + index + '.name'"
        :rules="{
          required: true,
          message: '请输入子分类的名称',
          trigger: 'blur',
        }"
      >
        <el-input
          v-model="domain.name"
          placeholder="请输入子分类的名称"
          style="width: 500px"
        ></el-input>
        <el-button @click.prevent="subclassificationRemove(domain)"
          >删除</el-button
        >
      </el-form-item>
      <el-form-item>
        <div class="subclassification" @click="addSubclassification">
          <i class="el-icon-plus"></i>
          <span>添加子分类</span>
        </div>
      </el-form-item>
      <el-form-item label="是否公开">
        <el-switch
          v-model="form.isPublic"
          active-value="1"
          inactive-value="0"
        ></el-switch>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('form')">提交</el-button>
        <!-- <el-button @click="resetForm('form')">重置</el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  updateChildrenCourseCategory,
  getCourseCategory,
} from "@/api/courseManage";
export default {
  data() {
    return {
      unitData: "",
      form: {
        category: "",
        isPublic: "1",
        subclassification: [], //子分类
      },
      rules: {
        category: [
          { required: true, message: "请输入课程类别名称", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.unitData = JSON.parse(sessionStorage.getItem("unitData"));
    this.form.category = this.unitData.courseName;
    this.form.isPublic = String(this.unitData.isOpen);
    this.getCourseCategory();
  },
  destroyed() {
    sessionStorage.removeItem("unitData");
  },
  methods: {
    async getCourseCategory() {
      let res = await getCourseCategory({ id: this.unitData.id });
      if (res.data.result == "success") {
        res.data.childrenCategoryList.forEach((element) => {
          this.form.subclassification.push({
            name: element.courseName,
            key: Date.now(),
          });
        });
      }
      console.log(res, "getCourseCategory");
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let childrenCourseName = this.form.subclassification
            .map((item) => item.name)
            .join(",");
          let res = await updateChildrenCourseCategory({
            id: this.unitData.id,
            courseName: this.form.category,
            childrenCourseName: childrenCourseName,
            isOpen: this.form.isPublic,
          });
          if (res.data.result == "success") {
            this.$message.success("修改成功");
            this.$router.go(-1);
          } else {
            this.$message.error("修改失败");
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 添加子分类
    addSubclassification() {
      this.form.subclassification.push({
        name: "",
        key: Date.now(),
      });
    },
    // 删除子分类
    subclassificationRemove(item) {
      let index = this.form.subclassification.indexOf(item);
      if (index !== -1) {
        this.form.subclassification.splice(index, 1);
      }
    },
  },
};
</script>
<style scoped>
.demo-ruleForm {
  margin-top: 20px;
}
.subclassification {
  border: 1px dashed #797979;
  width: 300px;
  height: 30px;
  border-radius: 10px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  margin-top: 10px;
}
</style>
