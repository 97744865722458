import axios from "../plugins/axios";

// 帮助文档管理
// 帮助文档分组列表
export const getEducationHelpDocumentGroupList = (param) =>
  axios.get(
    "/course/educationHelpDocument/getEducationHelpDocumentGroupList?name=" +
      param.name +
      "&startNum=" +
      param.startNum +
      "&pageSize=" +
      param.pageSize
  );

// 帮助文档分组新增 "name":""//姓名
export const saveEducationHelpDocumentGroup = (param) =>
  axios.post(
    "/course/educationHelpDocument/saveEducationHelpDocumentGroup",
    param
  );

// 帮助文档分组修改 "id"://id "name":""//姓名
export const updateEducationHelpDocumentGroup = (param) =>
  axios.post(
    " /course/educationHelpDocument/updateEducationHelpDocumentGroup",
    param
  );

// 帮助文档分组删除
export const deleteEducationHelpDocumentGroup = (param) =>
  axios.get(
    "/course/educationHelpDocument/deleteEducationHelpDocumentGroup?id=" +
      param.id
  );

// 帮助文档分组查看详情
export const selectEducationHelpDocumentGroup = (param) =>
  axios.get(
    "/course/educationHelpDocument/selectEducationHelpDocumentGroup?id=" +
      param.id
  );

// 帮助文档分组上移与下移
export const moveEducationHelpDocumentGroup = (param) =>
  axios.get(
    "/course/educationHelpDocument/moveEducationHelpDocumentGroup?id1=" +
      param.id1 +
      "&id2=" +
      param.id2
  );

// 帮助文档分组内容树获取
export const findAllDocumentList = (param) =>
  axios.get(
    "/course/educationHelpDocument/findAllDocumentList?groupId=" + param.groupId
  );

// 新增帮助文档树枝
/**
 *
 * @param {*} param
 * "name":""//姓名
 * "type"://类型 1-章节 2-文章
 * "content"://当类型是文章时，内容
 * "parentId"://文章下添加子文档时携带，章节id
 * "groupId"://分组id
 * @returns
 */
export const saveEducationHelpDocument = (param) =>
  axios.post("/course/educationHelpDocument/saveEducationHelpDocument", param);

// 编辑帮助文档树枝
/**
 *
 * @param {*} param
 * "name":""//姓名
 * "type"://类型 1-章节 2-文章
 * "content"://当类型是文章时，内容
 * "parentId"://当类型是文章时，章节id
 * "groupId"://分组id
 * @returns
 */
export const updateEducationHelpDocument = (param) =>
  axios.post(
    "/course/educationHelpDocument/updateEducationHelpDocument",
    param
  );

// 删除帮助文档树枝
export const deleteEducationHelpDocument = (param) =>
  axios.get(
    " /course/educationHelpDocument/deleteEducationHelpDocument?id=" + param.id
  );

// 查看详情帮助文档树枝
export const selectEducationHelpDocument = (param) =>
  axios.get(
    " /course/educationHelpDocument/selectEducationHelpDocument?id=" + param.id
  );

// 帮助文档树枝上移与下移
export const moveEducationHelpDocument = (param) =>
  axios.get(
    " /course/educationHelpDocument/moveEducationHelpDocument?id1=" +
      param.id1 +
      "&id2=" +
      param.id2
  );
