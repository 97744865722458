<template>
  <div>
    <el-card class="box-card">
      <div class="clearfix" v-if="governmentData">
        <div>培训机构名称：{{ governmentData ? governmentData.name : "" }}</div>
        <div style="margin-top: 30px">
          所在地：<span v-if="governmentData.power == 1">{{
            governmentData.countyName
          }}</span>
          <span v-else>
            {{ governmentData.provinceName ? governmentData.provinceName : "" }}
            {{ governmentData.cityName ? governmentData.cityName : "" }}
            {{ governmentData.regionName ? governmentData.regionName : "" }}
            {{ governmentData.streetName ? governmentData.streetName : "" }}
          </span>
        </div>
      </div>
    </el-card>

    <el-table
      :data="selectedUnitList"
      max-height="500"
      :show-header="false"
      style="margin: 30px"
    >
      <el-table-column type="index"></el-table-column>
      <el-table-column width="500" prop="trainingName"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <i
            class="el-icon-delete icon-style"
            @click="deleteSelectedUnit(scope)"
          ></i>
        </template>
      </el-table-column>
    </el-table>
    <div class="unit-setting" @click="dialogUnitSetting">
      <i class="el-icon-plus"></i>
      <span>添加培训</span>
    </div>
    <el-button
      style="margin-top: 20px; margin-left: 600px"
      @click="saveButton"
      type="primary"
      >保存</el-button
    >
    <el-button
      style="margin-top: 20px; margin-left: 50px"
      @click="backButton"
      type="primary"
      >返回上一页</el-button
    >
    <dialogTrainings
      :showDialogUnitSetting="showDialogUnitSetting"
      @colseDialog="colseDialog"
      @selectedUnit="selectedUnit"
      :selectedUnitList="selectedUnitList"
    ></dialogTrainings>
  </div>
</template>
<script>
import dialogTrainings from "@/views/trainingInstitutionManagement/dialogTraining/dialogTrainings.vue";
import {
  bindTrainings,
  listBindTrainings,
  unbindTrainingFromInstitution,
} from "@/api/trainingInstitution";
export default {
  components: {
    dialogTrainings,
  },
  data() {
    return {
      governmentData: "",
      showDialogUnitSetting: false,
      selectedUnitList: [],
    };
  },
  watch: {
    showDialogUnitSetting(newVal) {
      if (!newVal) {
        this.selectedUnitList = this.selectedUnitList.filter((obj, index) => {
          let r = this.selectedUnitList.findIndex(
            (item) => item.trainingId === obj.trainingId
          );
          if (r === index) {
            return obj;
          }
        });
      }
    },
  },
  created() {
    this.governmentData = JSON.parse(sessionStorage.getItem("governmentData"));
    this.getTrainingInstitutionsLessonList();
  },
  methods: {
    async getTrainingInstitutionsLessonList() {
      let param = {
        id: this.$route.query.id,
      };
      let res = await listBindTrainings(param);
      console.log("res => ", res);
      if ("200" === res.data.code) {
        // res.data.data.forEach((item) => {
        //   item.trainingName = item.name;
        // });
        this.selectedUnitList = res.data.data;
      }
    },
    colseDialog(val) {
      console.log(val, "fdsa");
      this.showDialogUnitSetting = val;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    dialogUnitSetting() {
      this.showDialogUnitSetting = true;
    },
    // 对话框选中的岗位
    selectedUnit(data) {
      console.log(data, "data");
      this.selectedUnitList = data;
    },
    // 删除功能
    async deleteSelectedUnit(row) {
      console.log(row, row.$index);
      console.log(
        "{\n" +
          "        orgId: this.$route.query.id,\n" +
          "        trainingId: row.trainingId,\n" +
          "      } => ",
        {
          orgId: this.$route.query.id,
          trainingId: this.selectedUnitList[row.$index].trainingId,
        }
      );
      let res = await unbindTrainingFromInstitution({
        orgId: this.$route.query.id,
        trainingId: this.selectedUnitList[row.$index].trainingId,
      });
      if (res.data.code === "200") {
        this.selectedUnitList.splice(row.$index, 1);
        this.getTrainingInstitutionsLessonList();
      }
    },
    async saveButton() {
      let lessonId = this.selectedUnitList
        .map((item) => item.trainingId)
        .join(";");
      console.log(lessonId, "lessonId");
      let param = {
        orgId: this.$route.query.id,
        trainingIds: lessonId,
      };
      let res = await bindTrainings(param);
      if ("200" == res.data.code) {
        this.$message({
          type: "success",
          message: "保存成功",
        });
        this.getTrainingInstitutionsLessonList();
      } else {
        this.$message.error("保存失败");
      }
    },
    backButton() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
.unit-setting {
  border: 1px dashed #797979;
  width: 300px;
  height: 30px;
  border-radius: 10px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  margin: 30px;
}
.mb-20 {
  margin-bottom: 20px;
}
.icon-style {
  font-weight: 700;
  font-size: 24px;
  margin: 0 5px;
  cursor: pointer;
}
.icon-style:hover {
  color: #ccc;
}
.SaveButton {
  margin: 300px 700px;
}
</style>
