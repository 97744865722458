<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span> 订单导出</span>
      </div>
      <div class="text item" style="font-size: 14px">
        对系统所有订单导出的功能。
      </div>
    </el-card>
    <div style="margin: 30px">
      <el-row>
        <el-col>
          <el-button type="primary" @click="DerivedData">导出数据</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- 导出数据弹窗 -->
    <el-dialog
      title="导出设置"
      :visible.sync="dialogVisiv"
      width="30%"
      :before-close="handleClose"
    >
      <template>
        <div style="margin: -20px 0 20px">请勾选需要加密的内容：</div>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="2">手机号</el-checkbox>
        </el-checkbox-group>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiv = false">取 消</el-button>
        <el-button type="primary" @click="examineExportListAll"
          >确定导出</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { examineExportListAll } from "@/api/operationManagement";
export default {
  data() {
    return {
      dialogVisiv: false, //导出数据
      checkList: [],
    };
  },
  methods: {
    // 导出数据
    DerivedData() {
      this.dialogVisiv = true;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    async examineExportListAll() {
      let encryptPhone = this.checkList.indexOf("2") != -1 ? true : false;
      let param = {
        encryptPhone: encryptPhone,
      };
      console.log(param, "param");
      let res = await examineExportListAll(param);
      console.log(res, "121");
      let link = document.createElement("a");
      let body = document.querySelector("body");
      let blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }); // res就是接口返回的文件流了
      console.log(blob, "55");
      link.href = window.URL.createObjectURL(blob);
      link.download = `普通用户名单`;
      // fix Firefox
      link.style.display = "none";
      body.appendChild(link);
      link.click();
      body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
      this.dialogVisiv = false;
    },
  },
};
</script>
