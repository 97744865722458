<template>
  <el-dialog
    title="请选择可查看的课程"
    :visible.sync="normalizedSize"
    :before-close="handleClose"
    width="65%"
  >
    <div class="select-position">
      <el-input
        v-model="unitValue"
        placeholder="请输入单位名称/关键字"
        clearable=""
        style="width: 500px; margin-right: 30px; margin-bottom: 20px"
      ></el-input>
      <el-button type="primary" @click="search">查询</el-button>
      <div style="margin: 10px 0">
        <span>已选择单位：</span>
        <el-tag
          v-for="tag in tags"
          :key="tag.id"
          closable
          @close="handleClosePosition(tag)"
        >
          {{ tag.digest }}
        </el-tag>
      </div>
      <template v-lazy>
        <el-table
          :data="unitList"
          style="width: 100%"
          ref="multipleTable"
          @selection-change="handleSelectionChange"
          @select="select"
        >
          <el-table-column type="selection" label="全选"></el-table-column>
          <el-table-column prop="digest" label="单位名称"></el-table-column>
        </el-table>
      </template>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalItems"
      >
      </el-pagination>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="colse">取 消</el-button>
      <el-button type="primary" @click="certainUnit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getAddLessonList1 } from "@/api/trainingManage";
export default {
  props: {
    showDialogUnitSetting: {
      type: Boolean,
      required: true,
      default: false,
    },
    areaIds: {
      type: Array,
    },
    selectedUnitList: {
      type: Array,
    },
  },
  data() {
    return {
      unitValue: "",
      tags: [],
      unitList: [],
      currentPage: 1,
      pageSize: 10,
      totalItems: 0,
    };
  },
  watch: {
    areaIds(newData) {
      this.getAddCompanyList(newData);
    },
    showDialogUnitSetting(val) {
      if (val) {
        this.tags = this.selectedUnitList;
        console.log(this.tags);
        this.tags.forEach((element) => {
          this.unitList.forEach((item) => {
            if (element.id === item.id) {
              this.$nextTick(() => {
                this.$refs.multipleTable.toggleRowSelection(item);
              });
            }
          });
        });
      }
    },
  },
  computed: {
    normalizedSize: function () {
      return this.showDialogUnitSetting;
    },
  },
  created() {
    this.getAddCompanyList();
  },
  mounted() {},
  destroyed() {
    sessionStorage.removeItem("tags");
  },
  methods: {
    // 获取单位管理列表
    async getAddCompanyList(areaList) {
      let res = areaList
        ? await getAddLessonList1({
            name: this.unitValue,
            areaList: areaList,
            startNum: this.currentPage,
            pageSize: this.pageSize,
            isloading: true,
          })
        : await getAddLessonList1({
            name: this.unitValue,
            areaList: [],
            startNum: this.currentPage,
            pageSize: this.pageSize,
            isloading: true,
          });
      console.log(res, "re2s");
      if (res.data.result == "success") {
        this.unitList = res.data.list;
        console.log(this.unitList);
        this.$store.state.allUnitList = res.data.list;
        this.totalItems = res.data.size;
        if (sessionStorage.getItem("tags")) {
          this.tags.forEach((element) => {
            this.unitList.forEach((item) => {
              if (element.id === item.id) {
                this.$nextTick(() => {
                  this.$refs.multipleTable.toggleRowSelection(item);
                });
              }
            });
          });
        }
      }
    },

    //搜索
    search() {
      this.currentPage = 1;
      this.getAddCompanyList(this.areaIds);
    },
    // 重置
    reset() {},

    // 点击取消选中单位
    handleClosePosition(tag) {
      console.log(tag, "handleClosePosition");
      this.tags.forEach((element, index) => {
        if (element.id == tag.id) {
          this.tags.splice(index, 1);
        }
      });
      this.unitList.forEach((element, index) => {
        if (element.id == tag.id) {
          this.$refs.multipleTable.toggleRowSelection(
            this.unitList[index],
            false
          );
        }
      });
    },
    select(selection, row) {
      console.log(selection, row, "select");
      this.tags.forEach((element, index) => {
        if (element.id == row.id) {
          this.tags.splice(index, 1);
        }
      });
    },
    // 选中的单位
    handleSelectionChange(val) {
      if (val.length > 0) {
        val.forEach((element) => {
          this.tags.push(element);
        });
        this.tags = this.tags.filter(
          (obj, index) =>
            this.tags.findIndex((item) => item.id == obj.id) === index
        );
      }
      console.log(this.tags, "handleSelectionChange");
    },

    handleSizeChange(size) {
      this.pageSize = size;
      sessionStorage.setItem("tags", JSON.stringify(this.tags));
      this.getAddCompanyList(this.areaIds);
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      sessionStorage.setItem("tags", JSON.stringify(this.tags));
      this.getAddCompanyList(this.areaIds);
    },
    // 确定
    certainUnit() {
      this.$emit("selectedUnit", this.tags);
      this.colse();
    },
    // 关闭对话框
    colse() {
      sessionStorage.removeItem("tags");
      this.tags = [];
      this.unitList.forEach((element) => {
        this.$refs.multipleTable.toggleRowSelection(element, false);
      });
      this.$emit("colseDialog", false);
    },
    handleClose() {
      this.$confirm("确认关闭？")
        .then(() => {
          this.colse();
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.ml-20 {
  margin-left: 20px;
}
</style>
