<template>
  <div>
    <el-row style="margin: 20px 0">
      <el-col :span="6">
        <el-input
          v-model="searchText"
          placeholder="请输入证书模板名称"
          @keyup.enter.native="search"
        />
      </el-col>
      <el-col :span="6" style="text-align: center">
        <el-select v-model="selectedUnit" placeholder="请选择" @change="search">
          <el-option
            v-for="status in status"
            :key="status.label"
            :label="status.label"
            :value="status.value"
          />
        </el-select>
      </el-col>
      <el-col :span="8">
        <span>创建时间：</span>
        <el-date-picker
          v-model="createTime"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" @click="search">查询</el-button>
        <!-- <el-button type="" @click="reset">重置</el-button> -->
      </el-col>
    </el-row>
    <el-row style="margin: 20px 0">
      <el-col :span="6">
        <el-button type="primary" @click="skipNewCertificate"
          >创建证书模板</el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      border
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" label="序号" width="120" align="center" />
      <el-table-column
        prop="templateName"
        label="证书模板名称"
        width="300"
        align="center"
      />
      <el-table-column label="证书模板" width="400" align="center">
        <template slot-scope="scope">
          <el-image
            style="width: 100px; height: 100px"
            :src="scope.row.templateUrl"
            :preview-src-list="srcList"
            :initial-index="scope.$index"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
        label="创建时间"
        prop="inTime"
        width="300"
        align="center"
      >
      </el-table-column>
      <el-table-column label="状态" width="200" align="center">
        <template slot-scope="scope">
          <el-radio-group
            v-model="scope.row.recovery"
            @change="changeStatus(scope.row)"
          >
            <el-radio :label="false">启用</el-radio>
            <el-radio :label="true">禁用</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="skipEditCertificate(scope.row)"
            >编辑</el-button
          >
          <el-button type="danger" @click="deleteCertificateTemplate(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="total"
      :page-size="pageSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
    />
  </div>
</template>

<script>
import {
  getCertificateTemplateList,
  deleteCertificateTemplate,
  updateCertificateTemplateStatus,
} from "@/api/certificateTemplate";
import { msToDate } from "@/plugins/msToDate";
export default {
  data() {
    return {
      searchText: "",
      selectedUnit: "",
      createTime: null,
      messageContent: "",
      tableData: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      total: 0,
      pageSize: 10,
      currentPage: 1,
      status: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "启用",
          value: 1,
        },
        {
          label: "禁用",
          value: 0,
        },
      ],
      selectedRows: [],
      srcList: [],
    };
  },
  created() {
    this.getCertificateTemplateList();
  },
  methods: {
    // 获取证书模板列表
    async getCertificateTemplateList() {
      let res = await getCertificateTemplateList({
        name: this.searchText, //名称
        status: this.selectedUnit, //状态	0启用	1禁用
        startTime: this.createTime ? msToDate(this.createTime[0]) : "",
        endTime: this.createTime ? msToDate(this.createTime[1]) : "",
        startNum: this.currentPage, //起始页码
        pageSize: this.pageSize, //每页条数
      });
      if (res.data.result == "success") {
        res.data.educationCertificateTemplateList.forEach((element) => {
          element.inTime = msToDate(element.inTime);
          this.srcList.push(element.templateUrl);
        });
        this.tableData = res.data.educationCertificateTemplateList;
        this.total = res.data.size;
      }
      console.log(this.tableData, "getCertificateTemplateList");
    },

    search() {
      // 发送搜索请求
      console.log("search", this.searchText, this.selectedUnit);
      this.getCertificateTemplateList();
    },
    async changeStatus(row) {
      // 发送修改状态请求
      console.log("changeStatus", row);
      let res = await updateCertificateTemplateStatus({
        templateId: row.id,
        recovery: row.recovery,
      });
      if (res.data.result == "success") {
        this.$message({
          type: "success",
          message: res.data.msg,
        });
        this.getCertificateTemplateList();
      }
      console.log(res, "changeStatus");
    },
    // 重置
    reset() {
      this.searchText = "";
      this.selectedUnit = "";
    },
    // 删除
    async deleteCertificateTemplate(row) {
      let res = await deleteCertificateTemplate({ id: row.id });
      if (res.data.result == "success") {
        this.$message.success("删除成功");
        this.getCertificateTemplateList();
      }
    },
    handleCurrentChange(currentPage) {
      // 发送分页请求
      this.currentPage = currentPage;
      this.search();
    },
    // 每页条数
    handleSizeChange(val) {
      this.pageSize = val;
      this.search();
    },
    // 选中
    handleSelectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    // 跳转到创建证书
    skipNewCertificate() {
      this.$router.push({
        path: "/newCertificate",
      });
    },
    // 跳转到编辑页
    skipEditCertificate(row) {
      console.log(row, "skipEditCertificate");
      this.$router.push({
        path: "/editCertificate",
      });
      sessionStorage.setItem("certificateTemplateData", JSON.stringify(row));
    },
  },
};
</script>
<style scoped>
.el-button {
  margin: 0 10px;
}
</style>
