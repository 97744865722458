import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    navMenuToggle: false, //导航菜单展开关闭切换
    breadListState: [], // 面包屑列表数据
    userInfo: "", // 用户数据
    userPower: "", // 用户权限 1-平台管理员 2国家级 3省级 4市级 5区级 6街道
    userIsPrimaryAdmin: "", // 用户是否为主管理员角色 主管理员：1
    allPositionList: [], // 全部岗位
    allUnitList: [], //全部单位
    allCourseList: [], //全部课程
    selectedTariningCourseList: [], // 选中的培训课程
    allTrainingList: [], // 全部培训
  },
  getters: {},
  mutations: {
    //导航菜单展开关闭切换
    setNavMenuToggle(state) {
      state.navMenuToggle = !state.navMenuToggle;
    },
    // 存储面包屑列表数据
    breadListMutations(state, list) {
      state.breadListState = list;
    },
    // 存储用户数据
    setUserInfo(state, data) {
      state.userInfo = data;
    },
    // 添加培训课程
    addSelectedTariningCourseList(state, param) {
      param.forEach((element) => {
        state.selectedTariningCourseList.push(element);
      });
    },
    // 删除培训课程
    delSelectedTariningCourseList(state, index) {
      state.selectedTariningCourseList =
        state.selectedTariningCourseList.splice(index, 1);
    },
  },
  actions: {},
  modules: {},
});
