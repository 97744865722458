<template>
  <el-dialog
    title="请选择参加培训的岗位"
    :visible.sync="normalizedSize"
    :before-close="handleClose"
  >
    <div class="select-position">
      <el-input
        v-model="searchInput"
        placeholder="请输入岗位名称/关键字"
        style="width: 500px; margin-right: 30px"
      ></el-input>
      <el-button type="primary" @click="search">搜索</el-button>
      <!-- <el-button type="primary" @click="reset">重置</el-button> -->
      <div style="margin: 10px 0">
        <span>已选择岗位：</span>
        <el-tag
          v-for="tag in tags"
          :key="tag.id"
          closable
          @close="handleClosePosition(tag)"
        >
          {{ tag.postionName }}
        </el-tag>
      </div>
      <!-- Table with multi-select checkbox in the first column and job position in the second column -->
      <el-table
        :data="positionList"
        ref="multipleTable"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" label="全选"></el-table-column>
        <el-table-column prop="postionName" label="岗位名称"></el-table-column>
      </el-table>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalItems"
      >
      </el-pagination>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="colse">取 消</el-button>
      <el-button type="primary" @click="certainPostion">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getAddPlatformPositionList } from "@/api/trainingManage";

export default {
  props: {
    showDialogPositionSetting: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      searchInput: "",
      tags: [],
      positionList: [],
      currentPage: 1,
      pageSize: 10,
      totalItems: 0,
    };
  },
  computed: {
    normalizedSize: function () {
      return this.showDialogPositionSetting;
    },
  },
  created() {
    this.getAddPlatformPositionList();
  },
  methods: {
    // 获取岗位
    async getAddPlatformPositionList() {
      let res = await getAddPlatformPositionList();
      if (res.data.result == "success") {
        this.positionList = res.data.msg;
        this.$store.state.allPositionList = res.data.msg;
        this.totalItems = this.positionList.length;
        console.log(res, "getAddPlatformPositionList");
      }
    },
    // 培训岗位搜索
    search() {
      // Perform search based on searchInput and selectedOption
    },
    // 培训岗位搜索重置
    reset() {},

    // 点击取消选中岗位
    handleClosePosition(tag) {
      this.tags.forEach((element, index) => {
        if (element.id == tag.id) {
          this.tags.splice(this.tags.indexOf(index), 1);
        }
      });
      this.positionList.forEach((element, index) => {
        if (element.id == tag.id) {
          this.$refs.multipleTable.toggleRowSelection(
            this.positionList[index],
            false
          );
        }
      });
    },
    // 选中的岗位
    handleSelectionChange(val) {
      console.log(val, "val");
      this.tags = val;
    },

    handleSizeChange(size) {
      this.pageSize = size;
      // Perform actions when page size changes
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      // Perform actions when current page changes
    },
    certainPostion() {
      this.$emit("selectedPosition", this.tags);
      this.colse();
    },
    colse() {
      this.$emit("colseDialog", false);
    },
    handleClose() {
      this.$confirm("确认关闭？")
        .then(() => {
          this.colse();
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
</style>
