import axios from "../plugins/axios";

// 获取课程中的课时信息
export const getLessonHourListByLessonId = (param) =>
  axios.get(
    "/course/platformTraining/getLessonHourListByLessonId?lessonId=" +
      param.lessonId
  );

//课程类别========
//课程类别列表获取
export const getEducationCourseCategoryList = (param) =>
  axios.get(
    "/api/course/getEducationCourseCategoryList?startNum=" +
      param.startNum +
      "&pageSize=" +
      param.pageSize +
      "&name=" +
      param.name +
      "&status=" +
      param.status
  );

//课程类别子分类列表获取
export const getChildrenCourseCategoryList = (param) =>
  axios.get(
    "/api/course/getChildrenCourseCategoryList?startNum=" +
      param.startNum +
      "&pageSize=" +
      param.pageSize +
      "&categoryId=" +
      param.categoryId
  );

//课程类别详情
export const getCourseCategory = (param) =>
  axios.get("/api/course/getCourseCategory?id=" + param.id);
//课程类别基础新增
export const addCourseClasses = (param) =>
  axios.post("/api/course/save", param);

//课程类别子分类新增
export const saveChildrenCategory = (param) =>
  axios.post("/api/course/saveChildrenCategory", param);
// 编辑课程
export const updateChildrenCourseCategory = (param) =>
  axios.post("/api/course/updateChildrenCourseCategory", param);
// 删除普通用户
export const deleteCourseCategory = (param) =>
  axios.get("/api/course/deleteCourseCategory?ids=" + param.ids);

//查看学员
export const listRecordCount = (param) =>
  axios.get(
    "/course/lessonRecord/listRecordCount?lessonId=" +
      param.lessonId +
      "&startNum=" +
      param.startNum +
      "&pageSize=" +
      param.pageSize +
      "&crux=" +
      param.crux
  );

//培训机构课程管理
export const getTrainingInstitutionsLesson = (param) =>
  axios.get(
    "/course/educationtraininginstitutions/getTrainingInstitutionsLesson?userId=" +
      param.userId +
      "&name=" +
      param.name +
      "&startNum=" +
      param.startNum +
      "&pageSize=" +
      param.pageSize
  );
// 导出数据
export const examineExportListAll = (param) => {
  return axios({
    method: "get",
    responseType: "blob",
    url:
      "/course/lessonRecord/exportRecordsByLesson?encryptPhone=" +
      param.encryptPhone +
      "&encryptIdCard=" +
      param.encryptIdCard +
      "&lessonId=" +
      param.lessonId +
      "&crux=" +
      param.crux,
  });
};
// 导入数据
export const importNationUserExcel = (param) => {
  return axios({
    method: "POST",
    responseType: "blob",
    url: "/course/lessonRecord/importLessonRecordsByExcel",
    data: param,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
