<template>
  <div>
    <el-card class="box-card">
      <div class="clearfix" v-if="governmentData">
        <div>政府部门名称：{{ governmentData ? governmentData.name : "" }}</div>
        <div style="margin: 30px 0">
          部门等级：<span v-if="governmentData.power == 2">国家级</span>
          <span v-if="governmentData.power == 3">省级</span>
          <span v-if="governmentData.power == 4">市级</span>
          <span v-if="governmentData.power == 5">区级</span>
          <span v-if="governmentData.power == 6">街道级</span>
        </div>
        <div>
          部门管辖地区：<span v-if="governmentData.power == 1">{{
            governmentData.countyName
          }}</span>
          <span v-else>
            {{ governmentData.provinceName ? governmentData.provinceName : "" }}
            {{ governmentData.cityName ? governmentData.cityName : "" }}
            {{ governmentData.regionName ? governmentData.regionName : "" }}
            {{ governmentData.streetName ? governmentData.streetName : "" }}
          </span>
        </div>
      </div>
    </el-card>
    <el-row style="margin: 20px 0">
      <el-col :span="8">
        <span>搜索内容：</span>
        <el-input
          style="width: 300px"
          v-model="searchValue"
          placeholder="请输入用户名/关键字"
          clearable
        ></el-input>
      </el-col>
      <el-col :span="6" style="text-align: center">
        <span>状态：</span>
        <el-select v-model="selectValue" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="启用" value="false"></el-option>
          <el-option label="禁用" value="true"></el-option>
        </el-select>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="search">搜索</el-button>
        <!-- <el-button @click="reset">重置</el-button> -->
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="6">
        <el-button type="primary" @click="addMember"
          >添加成员（管理员）</el-button
        >
      </el-col>
    </el-row>
    <el-table :data="tableData" style="width: 100%; margin-top: 20px" border>
      <el-table-column type="selection"></el-table-column>
      <el-table-column prop="id" label="uni-id"></el-table-column>
      <el-table-column prop="account" label="账号">
        <template slot-scope="scope">
          <span
            style="
              padding: 6px 5px 7px 9px;
              background-color: red;
              color: #fff;
              border-radius: 50%;
              font-size: 12px;
              margin-right: 5px;
            "
            v-if="scope.row.isPrimaryAdmin == 1"
          >
            主
          </span>
          <span>
            {{ scope.row.account }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="姓名"></el-table-column>
      <el-table-column prop="phone" label="联系电话"></el-table-column>
      <el-table-column label="启用/禁用">
        <template slot-scope="scope">
          <el-radio-group
            v-model="scope.row.recovery"
            @change="changeStatus(scope.row)"
          >
            <el-radio :label="false">启用</el-radio>
            <el-radio :label="true">禁用</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="600">
        <template slot-scope="scope">
          <el-button type="primary" @click="updateAdminRole(scope.row)"
            >更改管理员角色</el-button
          >
          <el-button type="primary" @click="updateIsAdmin(scope.row)"
            >设置为主管理员</el-button
          >
          <el-button type="primary" @click="edit(scope.row)">编辑</el-button>
          <el-button type="danger" @click="deleteUser(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  getEducationGovDepartMentUserList,
  deleteGovDepartMentUser,
  updateGovDepartMentUserStatus,
  updateIsAdmin,
} from "@/api/governmentManagement";
export default {
  data() {
    return {
      searchValue: "", // 搜索内容
      selectValue: "", // 启用状态
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      governmentData: "",
    };
  },
  created() {
    this.governmentData = JSON.parse(sessionStorage.getItem("governmentData"));
    this.getEducationGovDepartMentUserList();
    // window.addEventListener("beforeunload", () => {
    //   sessionStorage.setItem(
    //     "governmentData",
    //     JSON.stringify(this.governmentData)
    //   );
    // });
  },
  destroyed() {
    // sessionStorage.removeItem("governmentData");
  },
  methods: {
    // 获取政府部门成员列表
    async getEducationGovDepartMentUserList() {
      let res = await getEducationGovDepartMentUserList({
        id: this.$route.query.id, //公司id
        name: this.searchValue, //名称
        status: this.selectValue, //状态	true禁用	false禁用
        startNum: this.currentPage, //起始页码
        pageSize: this.pageSize, //每页条数
      });
      if (res.data.result == "success") {
        this.tableData = res.data.govDepartMentList;
        this.total = res.data.size;
      }
      console.log(res, "getEducationGovDepartMentUserList");
    },
    search() {
      // 发送搜索请求
      this.getEducationGovDepartMentUserList();
    },
    reset() {
      this.searchValue = "";
      this.selectValue = "";
      this.search();
    },
    // 启用禁用切换
    async changeStatus(row) {
      console.log(row, "changeStatus");
      let res = await updateGovDepartMentUserStatus({
        id: row.id,
        recovery: row.recovery,
      });
      if (res.data.msg == "success") {
        this.$message({
          type: "success",
          message: "修改状态成功",
        });
        this.getEducationGovDepartMentUserList();
      }
      console.log(res, "changeStatus");
    },
    // 添加政府部门成员
    addMember() {
      this.$router.push({ path: "/newGovernmentMember" });
    },
    deleteUser(row) {
      // 删除用户
      console.log("deleteUser", row);
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await deleteGovDepartMentUser({
            id: row.id,
          });
          if (res.data.result == "success") {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.getEducationGovDepartMentUserList();
          }
          console.log("deleteUser", res);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async updateIsAdmin(row) {
      // 设为政府部门管理员
      console.log("updateIsAdmin", row);
      let res = await updateIsAdmin({
        id: row.id,
        // isAdmin: 1,
      });
      if (res.data.result == "success") {
        this.$message({
          type: "success",
          message: res.data.msg,
        });
        this.getEducationGovDepartMentUserList();
      }
      console.log("setAdmin", res);
    },
    // 编辑
    edit(row) {
      this.$router.push({
        path: "/editGovernmentMember",
        query: { id: row.id },
      });
    },
    updateAdminRole(row) {
      sessionStorage.setItem("adminRoleData", JSON.stringify(row));
      this.$router.push({
        path: "/updateGovernmentMemberRole",
        query: { id: row.id },
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.search();
    },
  },
};
</script>
<style scoped>
.el-button {
  margin: 0 10px;
}
::v-deep.el-table .cell {
  height: 50px;
  line-height: 50px;
}
</style>
