<template>
  <div class="select-position">
    <span>搜索内容：</span>
    <el-input
      v-model="searchInput"
      placeholder="请输入单位名称/关键字"
      style="width: 500px; margin-right: 30px; margin-bottom: 20px"
    ></el-input>
    <el-button type="primary" @click="search">查询</el-button>
    <!-- <el-button type="default" @click="reset">重置</el-button> -->
    <!-- <el-row>
      <el-col :span="6">
        <span class="ml-20">省：</span>
        <el-select
          v-model="provId"
          placeholder="请选择省"
          @change="getFindCityList(provId)"
        >
          <el-option
            v-for="item in provList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <span class="ml-20">市：</span>
        <el-select
          v-model="cityId"
          placeholder="请选择市"
          @change="getFindDistrictList(cityId)"
        >
          <el-option
            v-for="item in cityList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <span class="ml-20">区：</span>
        <el-select
          v-model="regionId"
          placeholder="请选择区"
          @change="getFindStreetList(regionId)"
        >
          <el-option
            v-for="item in regionList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <span class="ml-20">街道：</span>
        <el-select v-model="streetId" placeholder="请选择街道">
          <el-option
            v-for="item in streetList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row> -->
    <!-- Table with multi-select checkbox in the first column and job position in the second column -->
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="index" label="课程名称"></el-table-column>
      <el-table-column prop="position" label="参与培训岗位"></el-table-column>
      <el-table-column prop="position" label="参与培训单位"></el-table-column>
      <el-table-column prop="position" label="单位所属地区"></el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalItems"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchInput: "",
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      totalItems: 0,
    };
  },
  methods: {
    //搜索
    search() {},
    // 重置
    reset() {},
    handleSizeChange(size) {
      this.pageSize = size;
    },
    handleCurrentChange(page) {
      this.currentPage = page;
    },
  },
};
</script>

<style scoped>
.select-position {
  padding: 20px;
}
</style>
