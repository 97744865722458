<template>
  <div class="newSlideshow">
    <el-form ref="form" :model="form" :rules="rules" label-width="200px">
      <el-form-item label="轮播图名称" prop="name">
        <el-input
          style="width: 600px"
          v-model="form.name"
          placeholder="请输入轮播图名称"
          maxlength="50"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="轮播图图片" prop="image">
        <el-upload
          class="avatar-uploader"
          action="/course/oss/uploadFile"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="form.image" :src="form.image" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <div style="color: #999999; font-size: 14px">
          <div>说明：</div>
          <div>1、请上传长宽比为16:9的图片，图片格式为jgp或png。</div>
        </div>
      </el-form-item>
      <el-form-item label="轮播图链接" prop="link">
        <div>
          请选择链接类型：<el-radio v-model="form.radio" label="1"
            >图文</el-radio
          >
        </div>
        <WangEditorVue @getEditorContent="getEditorContent"></WangEditorVue>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit('form')">完成</el-button>
        <el-button @click="onCancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import WangEditorVue from "@/components/WangEditor.vue";
import { updateSlideshow } from "@/api/slideshowManagement";
export default {
  components: { WangEditorVue },
  data() {
    return {
      form: {
        name: "",
        image: "",
        link: "",
        radio: "1",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入轮播图名称",
            trigger: "blur",
          },
        ],
        image: [
          {
            required: true,
            message: "请上传轮播图片",
            trigger: "change",
          },
        ],
        link: [
          {
            required: true,
            message: "请输入轮播图链接",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    // 上传图片成功后
    handleAvatarSuccess(res, file) {
      console.log(res.msg, file);
      // this.imageUrl = URL.createObjectURL(file.raw);
      this.form.image = file.response.msg;
    },
    // 上传图片前
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传模板图片只能是 JPG和PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传模板图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    onSubmit(formName) {
      if (!this.stripHtmlTags(this.form.link)) {
        this.$message({
          message: "请输入轮播图链接",
          type: "warning",
        });
        return false;
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = updateSlideshow({
            id: "",
            title: this.form.name,
            image: this.form.image,
            link: this.form.link,
            recovery: false,
            type: 0,
          });
          res.then((data) => {
            if (data.data.result == "success") {
              this.$message({
                message: data.data.msg,
                type: "success",
              });
              this.$router.back();
            }
            console.log(res, valid);
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getEditorContent(val) {
      console.log(this.stripHtmlTags(val), "val", val);
      this.form.link = val;
    },
    // 过滤字符串中的标签，保留内容部分
    stripHtmlTags(str) {
      if (str === null || str === "") return false;
      else str = str.toString();
      return str.replace(/<[^>]*>/g, "");
    },
    onCancel() {
      console.log("cancel!");
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.newSlideshow {
  width: 80%;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  border: 1px dashed #999;
  border-radius: 10px;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  border: 1px dashed #999;
  border-radius: 10px;
  width: 178px;
  height: 178px;
  display: block;
}
</style>
