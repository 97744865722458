<template>
  <el-dialog
    title="请选择培训课程"
    :visible.sync="normalizedSize"
    :before-close="handleClose"
    width="33%"
  >
    <div class="select-position">
      <!-- <el-input
        v-model="searchInput"
        placeholder="请输入课程名称/关键字"
        style="width: 500px; margin-right: 30px; margin-bottom: 20px"
      ></el-input>
      <el-button type="primary" @click="search">查询</el-button> -->
      <!-- <el-button type="default" @click="reset">重置</el-button> -->
      <div class="select-position-unit-course">
        <!-- 岗位 -->
        <!-- <el-scrollbar>
          <el-checkbox
            class="ml-24"
            v-model="allPosition"
            @change="selectAllPosition"
            >全部</el-checkbox
          >
          <el-tree
            :data="coursePositionList"
            default-expand-all
            node-key="id"
            ref="position"
            show-checkbox
            highlight-current
            :props="positionProps"
            @check="handleCheckChangePosition"
          >
          </el-tree>
        </el-scrollbar> -->
        <!-- 单位 -->
        <!-- <el-scrollbar>
          <el-checkbox class="ml-24" v-model="allUnit" @change="selectAllUnit"
            >全部</el-checkbox
          >
          <el-tree
            :data="courseUnitList"
            default-expand-all
            show-checkbox
            node-key="id"
            ref="unit"
            highlight-current
            :props="unitProps"
            @check="handleCheckChangeUnit"
          >
          </el-tree>
        </el-scrollbar> -->
        <!-- 课程 -->
        <el-scrollbar>
          <el-checkbox
            class="ml-24"
            v-model="allCourse"
            @change="selectAllCourse"
            >全部</el-checkbox
          >
          <el-tree
            :data="lessonList"
            show-checkbox
            default-expand-all
            node-key="id"
            ref="course"
            highlight-current
            :props="lessonProps"
            @check="handleCheckChangeCourse"
          >
          </el-tree>
        </el-scrollbar>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="colse">取 消</el-button>
      <el-button type="primary" @click="certainCourse">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getAddTrainingList } from "@/api/trainingManage";
export default {
  props: {
    showDialogCourseSetting: {
      type: Boolean,
      required: true,
      default: false,
    },
    selectedUnitList: {
      type: Array,
    },
    selectedPositionList: {
      type: Array,
    },
    selectedCourseTableList: {
      type: Array,
    },
    trainingPosition: {
      type: String,
    },
    trainingUnit: {
      type: String,
    },
    courseIds: {
      type: Array,
    },
  },
  data() {
    return {
      searchInput: "",
      allPosition: false,
      allUnit: false,
      allCourse: false,
      selectedLessonList: [],
      coursePositionList: [],
      courseUnitList: [],
      selectedCoursePosition: [],
      selectedCourseUnit: [],
      lessonList: [],
      lessonProps: {
        children: "children",
        label: "name",
      },
      unitProps: {
        children: "children",
        label: "companyName",
      },
      positionProps: {
        children: "children",
        label: "postionName",
      },
    };
  },
  computed: {
    normalizedSize: function () {
      return this.showDialogCourseSetting;
    },
  },
  watch: {
    showDialogCourseSetting(val) {
      if (val) {
        console.log(this.courseIds, "courseIds");
        this.$nextTick(() => {
          this.$refs.course.setCheckedKeys(this.courseIds);
        });
      }
    },
    // trainingPosition(newData) {
    //   if (newData == "all") {
    //     this.coursePositionList = this.$store.state.allPositionList;
    //   } else {
    //     this.coursePositionList = this.selectedPositionList;
    //   }
    // },
    // trainingUnit(newData) {
    //   if (newData == "all") {
    //     this.courseUnitList = this.$store.state.allUnitList;
    //   } else {
    //     this.courseUnitList = this.selectedUnitList;
    //   }
    // },
    // selectedUnitList(newData) {
    //   if (newData.length != 0) {
    //     this.courseUnitList = newData;
    //   } else {
    //     this.courseUnitList = this.$store.state.allUnitList;
    //   }
    // },
    // selectedPositionList(newData) {
    //   if (newData.length != 0) {
    //     this.coursePositionList = newData;
    //   } else {
    //     this.coursePositionList = this.$store.state.allPositionList;
    //   }
    // },
  },
  created() {
    this.getAddLessonList();
  },
  mounted() {
    // if (this.selectedUnitList.length == 0) {
    //   this.courseUnitList = this.$store.state.allUnitList;
    // }
    // if (this.selectedPositionList.length == 0) {
    //   this.coursePositionList = this.$store.state.allPositionList;
    // }
  },
  methods: {
    // 获取课程列表
    async getAddLessonList() {
      let res = await getAddTrainingList();
      if (res.data.result == "success") {
        this.lessonList = res.data.msg;
        this.$store.state.allCourseList = res.data.msg;
        console.log(res, "getAddTrainingList");
      }
    },
    //搜索
    search() {
      // Perform search based on searchInput and selectedOption
    },
    //重置
    reset() {},
    // 选择的岗位
    handleCheckChangePosition(data, checked) {
      console.log(data, checked, "岗位");
      this.selectedCoursePosition = checked.checkedNodes;
      if (checked.checkedNodes.length == this.coursePositionList.length) {
        this.allPosition = true;
      } else {
        this.allPosition = false;
      }
    },
    // 全选岗位
    selectAllPosition() {
      if (this.allPosition) {
        this.$refs.position.setCheckedNodes(this.coursePositionList);
        this.selectedCoursePosition = this.coursePositionList;
      } else {
        this.$refs.position.setCheckedKeys([]);
        this.selectedCoursePosition = [];
      }
    },
    // 选择的单位
    handleCheckChangeUnit(data, checked) {
      console.log(data, checked, "单位");
      this.selectedCourseUnit = checked.checkedNodes;
      if (checked.checkedNodes.length == this.courseUnitList.length) {
        this.allUnit = true;
      } else {
        this.allUnit = false;
      }
    },
    // 全选单位
    selectAllUnit() {
      if (this.allUnit) {
        this.$refs.unit.setCheckedNodes(this.courseUnitList);
        this.selectedCourseUnit = this.courseUnitList;
      } else {
        this.$refs.unit.setCheckedKeys([]);
        this.selectedCourseUnit = [];
      }
    },

    // 选择的课程
    handleCheckChangeCourse(data, checked) {
      console.log(data, checked, "课程");
      this.selectedLessonList = checked.checkedNodes;
      if (checked.checkedNodes.length == this.lessonList.length) {
        this.allCourse = true;
      } else {
        this.allCourse = false;
      }
    },
    // 全选课程
    selectAllCourse() {
      if (this.allCourse) {
        this.$refs.course.setCheckedNodes(this.lessonList);
        this.selectedLessonList = this.lessonList;
      } else {
        this.$refs.course.setCheckedKeys([]);
        this.selectedLessonList = [];
      }
    },
    // 确定
    certainCourse() {
      if (this.selectedLessonList.length == 0) {
        this.$message({
          type: "error",
          message: "请选择课程",
        });
        return;
      }
      // if (this.selectedCourseUnit.length == 0) {
      //   this.$message({
      //     type: "error",
      //     message: "请选择单位",
      //   });
      //   return;
      // }
      // if (this.selectedCoursePosition.length == 0) {
      //   this.$message({
      //     type: "error",
      //     message: "请选择岗位",
      //   });
      //   return;
      // }
      let arr = [];
      let courseArr = [];
      let lessonIds = [];
      this.selectedLessonList.forEach((item) => lessonIds.push(item.id));
      for (let i = 0; i < this.selectedLessonList.length; i++) {
        // for (let j = 0; j < this.selectedCourseUnit.length; j++) {
        // for (let k = 0; k < this.selectedCoursePosition.length; k++) {
        // Code to be executed inside the nested for loop
        let item = {
          lessonId: this.selectedLessonList[i].id,
          // companyId: this.selectedCourseUnit[j].id,
          // positionId: this.selectedCoursePosition[k].id,
          hourId: "",
        };
        let itemObj = {
          lesson: this.selectedLessonList[i],
          // company: this.selectedCourseUnit[j],
          // position: this.selectedCoursePosition[k],
          hourList: [],
        };
        courseArr.push(itemObj);
        arr.push(item);
        // }
        // }
      }
      console.log(
        lessonIds,
        courseArr,
        arr,
        "selectedUnitAndPositionAndCourse"
      );
      // for (let i = 0; i < courseArr.length; i++) {
      //   for (let j = 0; j < this.selectedCourseTableList.length; j++) {
      //     if (
      //       courseArr[i].company.id ==
      //         this.selectedCourseTableList[j].company.id &&
      //       courseArr[i].position.id ==
      //         this.selectedCourseTableList[j].position.id &&
      //       courseArr[i].lesson.id == this.selectedCourseTableList[j].lesson.id
      //     ) {
      //       this.$message({
      //         type: "error",
      //         message: "重复添加,请重新选择",
      //       });
      //       this.$refs.position.setCheckedKeys([]);
      //       this.$refs.unit.setCheckedKeys([]);
      //       this.$refs.course.setCheckedKeys([]);
      //       this.allPosition = false;
      //       this.allUnit = false;
      //       this.allCourse = false;
      //       return;
      //     }
      //   }
      // }
      this.$emit("selectedUnitAndPositionAndCourse", {
        lessonIds: lessonIds,
        ids: arr,
        tableList: courseArr,
      });
      this.$store.commit("addSelectedTariningCourseList", courseArr);
      this.colse();
      // this.$refs.position.setCheckedKeys([]);
      // this.$refs.unit.setCheckedKeys([]);
      this.$refs.course.setCheckedKeys([]);
      // this.allPosition = false;
      // this.allUnit = false;
      this.allCourse = false;
    },
    // 关闭对话框
    colse() {
      this.$emit("colseDialog", false);
    },
    handleClose() {
      this.$confirm("确认关闭？")
        .then(() => {
          this.colse();
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-24 {
  margin-left: 24px;
}
.el-scrollbar {
  height: 500px;
  width: 80%;
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 20px 0 0 0;
}
.select-position-unit-course {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
</style>
