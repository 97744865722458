import axios from "../plugins/axios";

//证书模板列表========
//证书模板列表列表获取
export const getCertificateTemplateList = (param) =>
  axios.get(
    "/course/educationCertificateTemplate/getCertificateTemplateList?name=" +
      param.name +
      "&status=" +
      param.status +
      "&startTime=" +
      param.startTime +
      "&endTime=" +
      param.endTime +
      "&startNum=" +
      param.startNum +
      "&pageSize=" +
      param.pageSize
  );

/**
 * 证书模板新增
 * @param {templateName,templateUrl,templatePdfUrl,isOpen} param
 * templateName-证书名称
 * templateUrl-图片url
 * templatePdfUrl-pdfurl
 * isOpen-是否公开 0-否 1-是
 * @returns
 */
export const saveCertificateTemplate = (param) =>
  axios.post(
    "/course/educationCertificateTemplate/saveCertificateTemplate",
    param
  );

/**
 * 证书模板编辑
 * @param {id,templateName,templateUrl,templatePdfUrl,isOpen} param
 * id-证书模板id
 * templateName-证书名称
 * templateUrl-图片url
 * templatePdfUrl-pdfurl
 * isOpen-是否公开 0-否 1-是
 * @returns
 */
export const updateCertificateTemplate = (param) =>
  axios.post(
    "/course/educationCertificateTemplate/updateCertificateTemplate",
    param
  );

/**
 * 证书模板启用禁用
 * @param {templateId,status} param
 * templateId 证书id
 * status 证书状态 false启用 true禁用
 * @returns
 */
export const updateCertificateTemplateStatus = (param) =>
  axios.post(
    "/course/educationCertificateTemplate/updateCertificateTemplateStatus",
    param
  );

// 删除证书模板
export const deleteCertificateTemplate = (param) =>
  axios.get(
    "/course/educationCertificateTemplate/deleteCertificateTemplate?id=" +
      param.id
  );

// 模板图片上传接口 param:file-文件
export const uploadFile = (param) => {
  return axios({
    url: "/course/oss/uploadFile",
    method: "post",
    headers: { "Content-Type": "multipart/form-data" },
    data: param,
  });
};

// 模板文件上传接口 formData(param):file-文件
export const educationCertificateTemplate = (param) => {
  return axios({
    url: "/course/educationCertificateTemplate/upload",
    method: "post",
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: param,
  });
};
