<template>
  <div id="invoiceReviewList">
    <el-row style="margin: 20px 0" :gutter="40">
      <el-col :span="6">
        <el-input
          v-model="crux"
          placeholder="课程名称/课程关键字"
          @keyup.enter.native="search"
          clearable
        />
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="search">搜索</el-button>
        <!-- <el-button type="" @click="reset">重置</el-button> -->
      </el-col>
    </el-row>
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column prop="id" label="id" width="100" fixed>
      </el-table-column>
      <el-table-column prop="name" label="姓名" fixed> </el-table-column>
      <el-table-column prop="inTime" label="创建时间"> </el-table-column>
      <el-table-column label="学生数量">
        <template slot-scope="scope">
          <span style="margin-right: 20px">{{ scope.row.studyCount }}</span>
          <el-button type="text" @click="handleClick(scope.row)"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalItems"
    ></el-pagination>
  </div>
</template>

<script>
import { getTrainingInstitutionsLesson } from "@/api/courseManage";
import { msToDate } from "@/plugins/msToDate";

export default {
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      currentPage: 1,
      pageSize: 10,
      totalItems: 0,
      crux: "",
    };
  },
  created() {
    this.lessonId = this.$route.query.lessonId
      ? this.$route.query.lessonId
      : "";
    this.getTrainingInstitutionsLesson();
  },
  methods: {
    async getTrainingInstitutionsLesson() {
      let res = await getTrainingInstitutionsLesson({
        startNum: this.currentPage,
        pageSize: this.pageSize,
        userId: this.$store.state.userInfo.id,
        name: this.crux,
      });
      if (res.data.result == "success") {
        this.tableData = res.data.lessonList;
        this.tableData.forEach((item) => (item.inTime = msToDate(item.inTime)));
        this.totalItems = res.data.size;
      }
    },
    search() {
      this.getTrainingInstitutionsLesson();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getTrainingInstitutionsLesson();
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.getTrainingInstitutionsLesson();
    },
    handleClick(row) {
      this.$router.push({
        path: "/viewStudents",
        query: {
          lessonId: row.id,
        },
      });
    },
  },
  mounted() {
    // Fetch address options for cascader
    // Fetch initial data for table
  },
};
</script>

<style scoped>
.ml-20 {
  margin-left: 20px;
}
</style>
