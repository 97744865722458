import axios from "../plugins/axios";

// 政府部门

/**
 * 获取政府部门列表
 * @param {name,status,provinceId,cityId,regionId,streetId,startNum,pageSize} param
 * name-关键字
 * status-状态 true-禁用 false-启用
 * provinceId-省
 * cityId-市
 * regionId-区
 * streetId-街道
 * startNum-页码
 * pageSize-每页条数
 * @returns
 */
export const getEducationGovDepartMentList = (param) =>
  axios.get(
    "/course/educationGovDepartMent/getEducationGovDepartMentList?name=" +
      param.name +
      "&status=" +
      param.status +
      "&provinceId=" +
      param.provinceId +
      "&cityId=" +
      param.cityId +
      "&regionId=" +
      param.regionId +
      "&streetId=" +
      param.streetId +
      "&startNum=" +
      param.startNum +
      "&pageSize=" +
      param.pageSize
  );

/**
 * 新增政府部门
 * @param {*} param
 * name//名称
 * power//等级 1-国家级 2-省级 3-市级 4-区级 5-街道
 * provinceId//省
 * cityId//市
 * regionId//区
 * streetId//街道
 * @returns
 */
export const saveGovDepartMent = (param) =>
  axios.post("/course/educationGovDepartMent/saveGovDepartMent", param);

/**
 * 修改政府部门
 * @param {*} param
 * id-政府部门id
 * name//名称
 * power//等级 1-国家级 2-省级 3-市级 4-区级 5-街道
 * provinceId//省
 * cityId//市
 * regionId//区
 * streetId//街道
 * @returns
 */
export const updateGovDepartMent = (param) =>
  axios.post("/course/educationGovDepartMent/updateGovDepartMent", param);

// 删除政府部门
export const deleteGovDepartMent = (param) =>
  axios.get(
    "/course/educationGovDepartMent/deleteGovDepartMent?id=" + param.id
  );

// 查看详情政府部门
export const selectGovDepartMent = (param) =>
  axios.get(
    "/course/educationGovDepartMent/selectGovDepartMent?id=" + param.id
  );

/**
 * 政府部门启用/禁用
 * @param {*} param
 * id-政府部门id
 * recovery true-禁用 false-启用
 * @returns
 */
export const updateGovDepartMentStatus = (param) =>
  axios.post("/course/educationGovDepartMent/updateGovDepartMentStatus", param);

/**
 * 导入政府部门信息
 * @param {*} param
 * file-文件
 * @returns
 */
export const importGovDepartMentExcel = (param) => {
  return axios({
    url: "/course/educationGovDepartMent/importGovDepartMentExcel",
    method: "post",
    data: param,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// 政府部门成员管理========================================
/**
 * 获取政府部门成员列表
 * @param {name,status,startNum,pageSize} param
 * id-政府部门成员id
 * name-关键字
 * status-状态 true-禁用 false-启用
 * startNum-页码
 * pageSize-每页条数
 * @returns
 */
export const getEducationGovDepartMentUserList = (param) =>
  axios.get(
    "/course/educationGovDepartMent/getEducationGovDepartMentUserList?name=" +
      param.name +
      "&id=" +
      param.id +
      "&status=" +
      param.status +
      "&startNum=" +
      param.startNum +
      "&pageSize=" +
      param.pageSize
  );

/**
 * 新增政府部门成员
 * @param {*} param
 * account//账号
 * name//名称
 * phone//手机
 * password//密码
 * confirmPassword//确认密码
 * departmentId//政府部门id
 * power 1-平台管理员 2国家级 3省级 4市级 5区级 6街道
 * @returns
 */
export const saveGovDepartMentUser = (param) =>
  axios.post("/course/educationGovDepartMent/saveGovDepartMentUser", param);

/**
 * 修改政府部门成员
 * @param {*} param
 * id-政府部门成员id
 * account//账号
 * name//名称
 * phone//手机
 * password//密码
 * confirmPassword//确认密码
 * departmentId//政府部门id
 * power 1-平台管理员 2国家级 3省级 4市级 5区级 6街道
 * @returns
 */
export const updateGovDepartMentUser = (param) =>
  axios.post("/course/educationGovDepartMent/updateGovDepartMentUser", param);

// 删除政府部门成员
export const deleteGovDepartMentUser = (param) =>
  axios.get(
    "/course/educationGovDepartMent/deleteGovDepartMentUser?id=" + param.id
  );

// 查看详情政府部门成员
export const selectGovDepartMentUser = (param) =>
  axios.get(
    "/course/educationGovDepartMent/selectGovDepartMentUser?id=" + param.id
  );

/**
 * 政府部门成员启用/禁用
 * @param {*} param
 * id-政府部门成员id
 * recovery true-禁用 false-启用
 * @returns
 */
export const updateGovDepartMentUserStatus = (param) =>
  axios.post(
    "/course/educationGovDepartMent/updateGovDepartMentUserStatus",
    param
  );

/**
 * 政府部门成员更改管理员角色
 * @param {*} param
 * id-政府部门成员id
 * provinceId//省
 * cityId//市
 * regionId//区
 * streetId//街道
 * @returns
 */
export const updateAdminRole = (param) =>
  axios.post("/course/educationGovDepartMent/updateAdminRole", param);

/**
 * 政府部门成员设为主管理员
 * @param {*} param
 * id-政府部门成员id
 * @returns
 */
export const updateIsAdmin = (param) =>
  axios.get("/course/educationGovDepartMent/updateIsAdmin?id=" + param.id);
