<template>
  <div class="add-plat-form-admin">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="150px"
      class="demo-ruleForm"
    >
      <el-form-item label="设置账号" prop="accountNumber">
        <el-input
          v-model="ruleForm.accountNumber"
          placeholder="请输入国家级管理员账号"
          maxlength="30"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="设置密码" prop="password">
        <el-input
          type="password"
          show-password
          v-model="ruleForm.password"
          placeholder="请输入国家级管理员账号密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="再次输入密码" prop="verifyPassword">
        <el-input
          type="password"
          show-password
          v-model="ruleForm.verifyPassword"
          placeholder="请输入国家级管理员账号密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="管理员姓名" prop="adminName">
        <el-input
          v-model="ruleForm.adminName"
          placeholder="请输入国家级管理员姓名"
          maxlength="30"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone">
        <el-input
          v-model="ruleForm.phone"
          placeholder="请输入国家级管理员联系电话"
        ></el-input>
      </el-form-item>
      <el-form-item label="设置所在政府部门" prop="governmentId">
        <el-select
          v-model="ruleForm.governmentId"
          placeholder="请选择所在政府部门"
          clearable
        >
          <el-option
            v-for="item in governmentSectorList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >保存</el-button
        >
        <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getAdminUserInfo, updateAdmin } from "@/api/userManagement";
import { queryNationEducationGovDepartmentByPower } from "@/api/common";
export default {
  data() {
    return {
      governmentSectorList: [],
      ruleForm: {
        accountNumber: "",
        password: "",
        verifyPassword: "",
        adminName: "",
        phone: "",
        governmentId: "",
      },
      rules: {
        accountNumber: [
          {
            required: true,
            message: "账号可为字母、数字或符号",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "至少6位密码，区分大小写",
            trigger: "blur",
          },
          {
            // pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}/,
            pattern: /[a-zA-Z\d]{6,}/,
            message: "至少6位密码，区分大小写",
            trigger: "blur",
          },
        ],
        verifyPassword: [
          { required: true, message: "确认密码", trigger: "blur" },
          {
            // pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}/,
            pattern: /[a-zA-Z\d]{6,}/,
            message: "至少6位密码，区分大小写",
            trigger: "blur",
          },
        ],
        adminName: [
          {
            required: true,
            message: "姓名只可为汉字、字母、数字或符号",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        // governmentId: [
        //   {
        //     required: true,
        //     message: "请选择所在政府部门",
        //     trigger: "change",
        //   },
        // ],
      },
    };
  },
  created() {
    this.queryNationEducationGovDepartmentByPower();
    this.getAdminUserInfo();
  },
  methods: {
    // 获取管理员信息
    async getAdminUserInfo() {
      let res = await getAdminUserInfo({ id: this.$route.query.id });
      if (res.data.result == "success") {
        this.ruleForm = {
          accountNumber: res.data.educationCloudUserInfo.account,
          password: "",
          verifyPassword: "",
          adminName: res.data.educationCloudUserInfo.name,
          phone: res.data.educationCloudUserInfo.phone,
          governmentId: res.data.educationCloudUserInfo.departmentId
            ? res.data.educationCloudUserInfo.departmentId
            : "",
        };
      }
    },
    // 获取国家级政府部门
    async queryNationEducationGovDepartmentByPower() {
      let res = await queryNationEducationGovDepartmentByPower();
      if (res.data.length > 0) {
        this.governmentSectorList = res.data;
      }
    },
    submitForm(formName) {
      if (this.ruleForm.password != this.ruleForm.verifyPassword) {
        this.$message({
          message: "请确认两次密码输入是否一致",
          type: "warning",
        });
        return;
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = await updateAdmin({
            id: this.$route.query.id,
            account: this.ruleForm.accountNumber,
            password: this.ruleForm.password,
            confirmPassword: this.ruleForm.verifyPassword,
            name: this.ruleForm.adminName,
            phone: this.ruleForm.phone,
            isAdmin: 1,
            power: 2,
            province: "",
            city: "",
            region: "",
            street: "",
            departmentId: this.ruleForm.governmentId,
          });
          console.log(res, "res");
          if (res.data.result == "success") {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
            this.$router.go(-1);
          } else {
            this.$message({
              type: "error",
              message: res.data.msg,
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
.add-plat-form-admin {
  width: 50%;
  padding: 50px;
}
</style>
