import axios from "axios";
import { Loading } from "element-ui";
// let time = 500; //防抖时间
// let timer = null;
let loadingInstance = null;
const instance = axios.create({
  baseURL: "",
  isLoading: true,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    // 读取数据前显示 loading
    if (config.isLoading) {
      console.log(config, "config+++config");
      loadingInstance = Loading.service({ text: "正在加载中..." });
    }
    config.headers["Accept"] = "application/json;chartset=UTF-8;text-plain,*/*"; // 接收哪些类型的参数，前后台定，可不设置，默认是json
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    // Do something with response data
    if (response.config.isLoading) {
      loadingInstance.close();
    }
    return response;
  },
  (error) => {
    // Do something with response error
    loadingInstance.close();
    return Promise.reject(error);
  }
);

export default instance;
