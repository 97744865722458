<template>
  <div>
    <el-card class="box-card">
      <div class="clearfix">
        <span>{{ unitData.companyName }}</span>
      </div>
    </el-card>
    <el-row style="margin: 20px 0">
      <el-col :span="6">
        <el-input
          v-model="searchValue"
          placeholder="请输入用户名/关键字"
          clearable
        ></el-input>
      </el-col>
      <el-col :span="6" style="text-align: center">
        <el-select v-model="selectedValue" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="启用" value="true"></el-option>
          <el-option label="禁用" value="false"></el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="search">搜索</el-button>
        <!-- <el-button @click="reset">重置</el-button> -->
      </el-col>
    </el-row>
    <el-row>
      <el-button type="primary" @click="showDialog">新建</el-button>
    </el-row>
    <el-dialog
      title="新建职位"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="职位名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm">确认</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-table :data="tableData" style="width: 100%; margin-top: 20px" border>
      <el-table-column type="selection"></el-table-column>
      <el-table-column prop="zhiwei.id" label="uni-id"></el-table-column>
      <el-table-column
        prop="zhiwei.companyPosition"
        label="职位"
      ></el-table-column>
      <el-table-column prop="user" label="创建人"></el-table-column>
      <el-table-column label="身份">
        <template slot-scope="scope">
          <span>{{
            scope.row.zhiwei.isAdmin == 1 ? "管理员" : "普通用户"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="zhiwei.inTime" label="操作时间"></el-table-column>
      <el-table-column label="启用/禁用">
        <template slot-scope="scope">
          <el-radio-group
            v-model="scope.row.zhiwei.recovery"
            @change="changeStatus(scope.row)"
          >
            <el-radio :label="true">启用</el-radio>
            <el-radio :label="false">禁用</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="danger" @click="deleteRow(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="currentPage"
      :page-size="pageSize"
      :total="total"
      layout="total, prev, pager, next, jumper"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></el-pagination>
  </div>
</template>

<script>
import {
  deleteCompanyPosition,
  getCompanyPositionList,
  saveCompanyPosition,
  updateCompanyPositionStatus,
} from "@/api/unitManagement";
export default {
  data() {
    return {
      searchValue: "",
      selectedValue: "",
      dialogVisible: false,
      form: {
        name: "",
      },
      rules: {
        name: [{ required: true, message: "请输入职位名称", trigger: "blur" }],
      },
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 3,
      unitData: "",
    };
  },
  created() {
    this.unitData = JSON.parse(sessionStorage.getItem("unitData"));
    this.getCompanyPositionList();
  },
  destroyed() {
    sessionStorage.removeItem("unitData");
  },
  methods: {
    renderTime(date) {
      let dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
    },

    // 获取职位列表
    async getCompanyPositionList() {
      let res = await getCompanyPositionList({
        companyId: this.unitData.id, //公司id
        name: this.searchValue, //名称
        status: this.selectedValue, //状态	true禁用	false禁用
        startNum: this.currentPage, //起始页码
        pageSize: this.pageSize, //每页条数
      });
      if (res.data.result == "success") {
        res.data.list.forEach((element) => {
          element.zhiwei.inTime = this.renderTime(element.zhiwei.inTime);
        });
        this.tableData = res.data.list;
        this.total = res.data.size;
      }
      console.log("getCompanyPositionList:", this.tableData);
    },
    search() {
      console.log("searchValue:", this.searchValue);
      console.log("selectedValue:", this.selectedValue);
      this.getCompanyPositionList();
    },
    reset() {
      this.searchValue = "";
      this.selectedValue = "";
    },
    showDialog() {
      this.dialogVisible = true;
    },
    // 新建职位
    submitForm() {
      console.log(this.$store.state.userInfo, this.unitData);
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let res = await saveCompanyPosition({
            educationCloudCompanyId: this.unitData.id, //所属公司id
            name: this.$store.state.userInfo.id, //创建人id
            companyPosition: this.form.name, //职位名称
          });
          if (res.data.result == "success") {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
            this.form.name = "";
            this.getCompanyPositionList();
          }
          console.log("form:", this.form, res);
          this.dialogVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //删除职位
    deleteRow(row) {
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          console.log("deleteUser", row);
          let res = await deleteCompanyPosition({
            id: row.zhiwei.id,
          });
          if (res.data.result == "success") {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
            this.getCompanyPositionList();
          }
          console.log("deleteUser", res);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 修改职位状态
    async changeStatus(row) {
      console.log("changeStatus", row);
      let res = await updateCompanyPositionStatus({
        id: row.zhiwei.id,
        state: row.zhiwei.recovery,
      });
      if (res.data.result == "success") {
        this.$message({
          type: "success",
          message: res.data.msg,
        });
        this.getCompanyPositionList();
      }
    },
    handleCurrentChange(val) {
      console.log(`currentPage: ${val}`);
      this.currentPage = val;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search();
    },
  },
};
</script>

<style scoped>
.el-row {
  margin-bottom: 20px;
}
</style>
