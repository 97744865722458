<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="200px">
      <el-form-item label="培训机构名称" prop="name">
        <el-input
          style="width: 600px"
          v-model="form.name"
          placeholder="请输入培训机构名称"
          maxlength="50"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="培训机构所在地" prop="provId">
        <el-row class="mb-20">
          <el-col :span="24">
            <el-select
              v-model="form.provId"
              placeholder="请选择省"
              clearable
              @change="getFindCityList(form.provId)"
              @clear="clearProvId"
              :disabled="
                $store.state.userPower == 3 ||
                $store.state.userPower == 4 ||
                $store.state.userPower == 5
              "
            >
              <el-option
                v-for="item in provList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="form.cityId"
              placeholder="请选择市"
              @change="getFindDistrictList(form.cityId)"
              @clear="clearCityId"
              clearable
              :disabled="
                $store.state.userPower == 4 || $store.state.userPower == 5
              "
            >
              <el-option
                v-for="item in cityList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="form.regionId"
              placeholder="请选择区"
              @change="getFindStreetList(form.regionId)"
              @clear="clearRegionId"
              clearable
              :disabled="$store.state.userPower == 5"
            >
              <el-option
                v-for="item in regionList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="form.streetId"
              placeholder="请选择街道"
              clearable
            >
              <el-option
                v-for="item in streetList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item>
        <span>说明：至少设置到省一级。</span>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit('form')">完成</el-button>
        <el-button @click="onCancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  findCityList,
  findDistrictList,
  findProviceList,
  findStreetList,
} from "@/api/userLogin";
import { saveTraininginstitutions } from "@/api/trainingInstitution";
export default {
  data() {
    return {
      provList: [], //省级
      cityList: [], //市级列表
      regionList: [], // 区级
      streetList: [], // 街道
      form: {
        name: "",
        provId: "",
        cityId: "",
        regionId: "",
        streetId: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入培训机构名称", trigger: "blur" },
        ],
        provId: [
          { required: true, message: "请选择所在地", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.getFindProviceList();
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = await saveTraininginstitutions({
            name: this.form.name,
            provinceId: this.form.provId,
            cityId: this.form.cityId,
            regionId: this.form.regionId,
            streetId: this.form.streetId,
          });
          if (res.data.result == "success") {
            this.$message.success("添加成功");
            this.$router.back();
          }
        } else {
          return false;
        }
      });
    },
    onCancel() {
      this.$router.back();
    },
    // 获取省
    async getFindProviceList() {
      let res = await findProviceList();
      if (res.data.result == "success") {
        this.provList = res.data.msg;
      }
    },
    // 获取市
    async getFindCityList(id) {
      let res = await findCityList({ id: id });
      if (res.data.result == "success") {
        this.cityList = res.data.msg;
      }
    },
    // 获取区
    async getFindDistrictList(id) {
      let res = await findDistrictList({ id: id });
      if (res.data.result == "success") {
        this.regionList = res.data.msg;
      }
    },
    // 获取街道
    async getFindStreetList(id) {
      let res = await findStreetList({ id: id });
      if (res.data.result == "success") {
        this.streetList = res.data.msg;
      }
    },
    // 清空省触发
    clearProvId() {
      this.cityId = "";
      this.regionId = "";
      this.streetId = "";
      this.cityList = [];
      this.regionList = [];
      this.streetList = [];
    },
    // 清空市触发
    clearCityId() {
      this.regionId = "";
      this.streetId = "";
      this.regionList = [];
      this.streetList = [];
    },
    // 清空区触发
    clearRegionId() {
      this.streetId = "";
      this.streetList = [];
    },
  },
};
</script>

<style></style>
