<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span> 轮播图管理</span>
      </div>
      <div class="text item" style="font-size: 14px">
        对轮播图进行管理的模块。
      </div>
      <!-- <el-button @click="study">查看学习数据</el-button> -->
    </el-card>
    <el-row style="margin: 20px">
      <el-col :span="10">
        <span>搜索内容：</span
        ><el-input
          style="width: 500px"
          v-model="search"
          placeholder="请输入页面名称"
          clearable
        ></el-input
      ></el-col>
      <el-col :span="4"
        ><el-button type="primary" @click="searchCarousel"
          >查询</el-button
        ></el-col
      >
    </el-row>
    <el-row style="margin: 20px">
      <el-button type="primary" @click="createCarousel">新建轮播图</el-button>
    </el-row>
    <el-table :data="tableData" border>
      <el-table-column prop="title" label="轮播图名称"></el-table-column>
      <el-table-column label="图片预览">
        <template slot-scope="scope">
          <el-image
            style="width: 100px; height: 100px"
            :src="scope.row.image"
            :preview-src-list="srcList"
            :initial-index="scope.$index"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="排序" width="150">
        <template slot-scope="scope">
          <div class="icon">
            <i
              style="color: red; cursor: pointer"
              class="el-icon-top"
              @click="moveSlideshow('up', scope.$index)"
            ></i>
            <i
              style="color: green; cursor: pointer"
              class="el-icon-bottom"
              @click="moveSlideshow('down', scope.$index)"
            ></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="是否显示">
        <template slot-scope="scope">
          <el-radio-group
            v-model="scope.row.recovery"
            @change="updateSidershowStatus(scope.row)"
          >
            <el-radio :label="false">显示</el-radio>
            <el-radio :label="true">隐藏</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click="editCarousel(scope.row)"
            >修改</el-button
          >
          <el-button type="danger" @click="deleteCarousel(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import {
  getSlidelList,
  moveSlideshow,
  updateSidershowStatus,
  slideshowDelete,
} from "@/api/slideshowManagement";
export default {
  data() {
    return {
      search: "",
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      srcList: [],
    };
  },
  created() {
    this.getSlidelList();
  },
  methods: {
    async getSlidelList() {
      let res = await getSlidelList({
        name: this.search,
        startNum: this.currentPage,
        pageSize: this.pageSize,
        type: 0,
      });
      if (res.data.result == "success") {
        this.tableData = res.data.list;
        res.data.list.forEach((element) => {
          this.srcList.push(element.image);
        });
        this.total = res.data.size;
      }
    },
    // 排序上移下移
    async moveSlideshow(type, index) {
      let id2 = "";
      if (type == "up" && this.tableData[index - 1]) {
        id2 = this.tableData[index - 1].id;
      } else if (type == "down" && this.tableData[index + 1]) {
        id2 = this.tableData[index + 1].id;
      } else {
        this.$message({
          message: type == "up" ? "已经是第一行了" : "已经是最后一行了",
          type: "warning",
        });
        return;
      }
      let res = await moveSlideshow({
        id1: this.tableData[index].id,
        id2: id2,
      });
      if (res.data.result == "success") {
        this.$message({
          message: "修改成功",
          type: "success",
        });
        this.getSlidelList();
      }
    },
    // 轮播图显示/隐藏
    async updateSidershowStatus(row) {
      let res = await updateSidershowStatus({
        id: row.id,
        recovery: row.recovery,
        type: 0,
      });
      if (res.data.result == "success") {
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        this.getSlidelList();
      }
    },
    searchCarousel() {
      // Implement search logic here
      this.getSlidelList();
    },
    createCarousel() {
      // Implement create logic here
      this.$router.push({
        path: "/newSlideshow",
      });
    },
    editCarousel(row) {
      // Implement edit logic here
      this.$router.push({
        path: "/editSlideshow",
        query: { id: row.id },
      });
    },
    deleteCarousel(row) {
      // Implement delete logic here
      console.log(row);
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await slideshowDelete({
            id: row.id,
          });
          if (res.data.result == "success") {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getSlidelList();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.searchCarousel();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchCarousel();
    },
  },
};
</script>
<style scoped>
.icon {
  font-size: 32px;
  display: flex;
  justify-content: space-around;
}
</style>
