<template>
  <div id="invoiceReviewList">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="3" style="line-height: 50px">
          <el-button type="primary" @click="back">返回上一页</el-button>
        </el-col>
        <el-col
          :span="10"
          style="
            height: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
          "
          v-if="lessonName"
        >
          <div>课程名称：{{ lessonName.name }}</div>
          <div v-if="hourId">课时名称：</div>
        </el-col>
      </el-row>
    </el-card>
    <el-row :gutter="20" style="margin-top: 30px">
      <el-col :span="8">
        <span>创建时间：</span>
        <el-date-picker
          v-model="createTime"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="6" style="text-align: center">
        <el-select v-model="status" placeholder="请选择">
          <el-option
            v-for="status in statusList"
            :key="status.value"
            :label="status.label"
            :value="status.value"
          />
        </el-select>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="search">查询</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin: 20px 0">
      <el-col :span="2">
        <el-button type="primary" @click="newRedeemCode">新增</el-button>
      </el-col>
      <el-col :span="2">
        <el-button type="danger" @click="deleteInBatches">批量删除</el-button>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="redemptionCodeExport"
          >批量导出</el-button
        >
      </el-col>
    </el-row>
    <el-table
      ref="multipleTable"
      :data="tableData"
      style="width: 100%"
      border
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" fixed></el-table-column>
      <el-table-column
        prop="redemptionCode"
        label="兑换码"
        fixed
      ></el-table-column>
      <el-table-column prop="inTime" label="创建时间"> </el-table-column>
      <el-table-column prop="validityDate" label="有效期限">
        <template slot-scope="scope"> {{ scope.row.validityDate }}天 </template>
      </el-table-column>
      <el-table-column prop="remainingValidity" label="剩余有效期">
        <template slot-scope="scope">
          {{ scope.row.remainingValidity }}天
        </template>
      </el-table-column>

      <el-table-column prop="status" label="兑换码状态">
        <template slot-scope="scope">
          <div v-if="scope.row.status == 0">未使用</div>
          <div v-if="scope.row.status == 1">已使用</div>
          <div v-if="scope.row.status == 2">已失效</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <el-button type="danger" @click="deleteCode(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalItems"
    ></el-pagination>
  </div>
</template>

<script>
import {
  listRedemptionCode,
  deleteRedemptionCode,
  redemptionCodeExport,
} from "@/api/redeemCodeManagement";
import { msToDate } from "@/plugins/msToDate";
export default {
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      status: "",
      statusList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "未使用",
          value: 0,
        },
        {
          label: "已使用",
          value: 1,
        },
        {
          label: "已失效",
          value: 2,
        },
      ],
      createTime: "",
      lessonId: "",
      hourId: "",
      tableData: [],
      multipleSelection: [],
      lessonName: "",
      currentPage: 1,
      pageSize: 10,
      totalItems: 0,
    };
  },
  created() {
    this.lessonId = this.$route.query.lessonId
      ? this.$route.query.lessonId
      : "";
    this.hourId = this.$route.query.hourId ? this.$route.query.hourId : "";
    this.listRedemptionCode();
  },
  methods: {
    async listRedemptionCode() {
      let res = await listRedemptionCode({
        feedbackTime: this.createTime[0] ? msToDate(this.createTime[0]) : "",
        feedbackEndTime: this.createTime[1] ? msToDate(this.createTime[1]) : "",
        startNum: this.currentPage,
        pageSize: this.pageSize,
        status: this.status,
        lessonId: this.lessonId,
        hourId: this.hourId,
      });
      if (res.data.list) {
        this.tableData = res.data.list;
        this.lessonName = res.data.lessonName;
        this.tableData.forEach((item) => (item.inTime = msToDate(item.inTime)));
        this.totalItems = res.data.listSize;
      }
    },
    // 审核批量导出
    async redemptionCodeExport() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          type: "warning",
          message: "请勾选需要导出的数据",
        });
        return;
      }
      let param = {
        ids: this.multipleSelection.join(","),
        feedbackTime: this.applyTime ? msToDate(this.applyTime[0]) : "",
        feedbackEndTime: this.applyTime ? msToDate(this.applyTime[1]) : "",
        status: this.status,
      };
      console.log(param, "param");
      let res = await redemptionCodeExport(param);
      console.log(res.data);

      let link = document.createElement("a");
      let body = document.querySelector("body");

      // let blob = new Blob([res.data], {type: "application/vnd.ms-excel"});  // res就是接口返回的文件流了
      let blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }); // res就是接口返回的文件流了
      link.href = window.URL.createObjectURL(blob);
      link.download = `兑换码`;
      // fix Firefox
      link.style.display = "none";
      body.appendChild(link);

      link.click();
      body.removeChild(link);

      window.URL.revokeObjectURL(link.href);
    },
    newRedeemCode() {
      this.$router.push({
        path: "/newRedeemCode",
        query: { lessonId: this.lessonId, hourId: this.hourId },
      });
    },
    deleteCode(row) {
      this.$confirm("确认删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await deleteRedemptionCode({ ids: row.id });
          if (res.data.result === "success") {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.listRedemptionCode();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    deleteInBatches() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          type: "warning",
          message: "请勾选需要删除的数据",
        });
        return;
      }
      this.$confirm("确认删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await deleteRedemptionCode({
            ids: this.multipleSelection.join(","),
          });
          if (res.data.result === "success") {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.listRedemptionCode();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    search() {
      // Implement search functionality
    },
    reset() {
      // Implement reset functionality
    },
    back() {
      this.$router.back();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.listRedemptionCode();
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.listRedemptionCode();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val.map((item) => item.id);
      // val.forEach((item) => this.multipleSelection.push(item.id));
      console.log(val, "handleSelectionChange", this.multipleSelection);
    },
  },
};
</script>

<style scoped>
.ml-20 {
  margin-left: 20px;
}
</style>
