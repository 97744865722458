<template>
  <div id="invoiceReviewList">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="3" style="line-height: 50px">
          <el-button type="primary" @click="back">返回上一页</el-button>
        </el-col>
        <el-col
          :span="10"
          style="
            height: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
          "
          v-if="lessonName"
        >
          <div>课程名称：{{ lessonName.name }}</div>
          <div>
            收费模式：{{ lessonName.chargeMode == "2" ? "一次性收费" : "免费" }}
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-row style="margin: 20px 0" :gutter="40">
      <el-col :span="6">
        <el-input
          v-model="crux"
          placeholder="请输入用户名/手机号"
          @keyup.enter.native="search"
          clearable
        />
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="search">搜索</el-button>
      </el-col>
    </el-row>
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column type="index" label="序号" width="100" fixed>
      </el-table-column>
      <el-table-column prop="name" label="学员姓名" fixed> </el-table-column>
      <el-table-column prop="phone" label="学员手机号"> </el-table-column>
      <el-table-column prop="" label="支付方式"> </el-table-column>
      <el-table-column prop="" label="商户订单号"> </el-table-column>
      <el-table-column prop="" label="退款时间"> </el-table-column>
      <el-table-column prop="" label="退款操作者"> </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalItems"
    ></el-pagination>
  </div>
</template>

<script>
import { listRecordCount } from "@/api/courseManage";
import { msToDate } from "@/plugins/msToDate";

export default {
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      fileList: [],
      currentPage: 1,
      pageSize: 10,
      totalItems: 0,
      lessonName: "",
      crux: "",
    };
  },
  created() {
    this.lessonId = this.$route.query.lessonId
      ? this.$route.query.lessonId
      : "";
    this.listRecordCount();
  },
  methods: {
    async listRecordCount() {
      let res = await listRecordCount({
        startNum: this.currentPage,
        pageSize: this.pageSize,
        lessonId: this.lessonId,
        crux: this.crux,
      });
      if (res.data.list) {
        this.tableData = res.data.list;
        this.lessonName = res.data.lessonName;
        this.tableData.forEach((item) => (item.inTime = msToDate(item.inTime)));
        this.totalItems = res.data.recordCount;
      }
    },
    back() {
      this.$router.back();
    },
    search() {
      this.listRecordCount();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.listRecordCount();
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.listRecordCount();
    },
  },
};
</script>

<style scoped>
.ml-20 {
  margin-left: 20px;
}
</style>
