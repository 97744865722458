import { render, staticRenderFns } from "./unitManagement.vue?vue&type=template&id=c5a21532&scoped=true&"
import script from "./unitManagement.vue?vue&type=script&lang=js&"
export * from "./unitManagement.vue?vue&type=script&lang=js&"
import style0 from "./unitManagement.vue?vue&type=style&index=0&id=c5a21532&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5a21532",
  null
  
)

export default component.exports