<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span> {{ trainingObj.name }}</span>
      </div>
      <div class="text item" style="font-size: 14px">
        {{ trainingObj.content }}
      </div>
    </el-card>
    <div id="trainingInquiryList">
      <h4>
        <span>{{ company ? company.companyName : user.companyName }} </span>
        <span class="ml-20">
          {{ company ? company.provinceName : user.provinceName }}
          {{ company ? company.cityName : user.cityName }}
          {{ company ? company.regionName : user.regionName }}
          {{ company ? company.streetName : user.streetName }}
        </span>
        <span class="ml-20">{{ user.name ? user.name : user.userName }} </span>
        <span class="ml-20"
          >{{ user.postionName ? user.postionName : user.positionName }}
        </span>
      </h4>
      <el-row :gutter="20">
        <el-col :span="7">
          <span>搜索内容：</span>
          <el-input
            style="width: 300px"
            v-model="searchInput"
            placeholder="请输入课程名称/关键字"
          ></el-input>
        </el-col>

        <el-col :span="5">
          <span>课程：</span>
          <el-select v-model="lessonId" placeholder="请选择课程" clearable>
            <el-option
              v-for="item in lessonList"
              :key="item.id"
              :label="item.lessonName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-button type="primary" @click="search">查询</el-button>
          <!-- <el-button type="" @click="reset">重置</el-button> -->
        </el-col>
      </el-row>
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column type="index" label="序号" fixed></el-table-column>
        <el-table-column prop="userName" label="学习记录时间" fixed>
          <template slot-scope="scope">
            {{ scope.row.beginTime }}-{{ scope.row.endTime }}
          </template>
        </el-table-column>
        <el-table-column
          prop="effectiveLearningDuration"
          label="有效学习时长"
          fixed
        >
          <template slot-scope="scope">
            {{ scope.row.effectiveLearningDuration | timeCycle }}
          </template>
        </el-table-column>
        <el-table-column prop="studyDuration" label="学习总时长">
          <template slot-scope="scope">
            {{
              scope.row.studyDuration ? scope.row.studyDuration : 0 | timeCycle
            }}
          </template>
        </el-table-column>
        <el-table-column prop="totalDuration" label="课时时长">
          <template slot-scope="scope">
            {{ scope.row.totalDuration | timeCycle }}
          </template>
        </el-table-column>
        <el-table-column prop="lessonName" label="学习课程"> </el-table-column>
        <el-table-column prop="chapterName" label="所属章节"> </el-table-column>
        <el-table-column prop="hourName" label="学习课时"> </el-table-column>
        <el-table-column prop="studyProgress" label="课时进度">
          <template slot-scope="scope">
            {{ scope.row.studyProgress }}%
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalItems"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getUserSchedule, getAddLessonList } from "@/api/trainingManage";
export default {
  data() {
    return {
      searchInput: "",
      tableData: [],
      lessonList: [],
      currentPage: 1,
      pageSize: 10,
      totalItems: 0,
      company: "",
      user: "",
      trainingObj: "",
      lessonId: "",
    };
  },
  filters: {
    // 过滤时间
    timeCycle(value) {
      const remainder = value % 60 < 10 ? "0" + (value % 60) : value % 60;
      const numStr = String((value / 60).toFixed(2));
      if (value > 60 && remainder == 0) {
        return (
          Math.floor(value / 60) +
          "分钟" +
          numStr.slice(numStr.indexOf(".") + 1) +
          "秒"
        );
      } else if (value > 60 && remainder != 0) {
        return Math.floor(value / 60) + "分钟" + remainder + "秒";
      } else {
        return value + "秒";
      }
    },
  },
  created() {
    console.log(this.$route.query.trainingId, "555");
    this.trainingObj = JSON.parse(sessionStorage.getItem("unitData"));
    if (JSON.parse(sessionStorage.getItem("studyRecord"))) {
      this.company = JSON.parse(sessionStorage.getItem("studyRecord")).company;
      this.user = JSON.parse(sessionStorage.getItem("studyRecord")).user;
    }
    this.getUserSchedule(this.user.userId);
    this.getAddLessonList(this.trainingObj.id);
    //
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem(
        "studyRecord",
        JSON.stringify({
          user: this.user,
          company: this.company,
        })
      );
    });
  },
  beforeDestroy() {
    sessionStorage.removeItem("studyRecord");
  },
  methods: {
    // 获取行为记录列表
    async getUserSchedule(id) {
      let res = await getUserSchedule({
        userId: id,
        trainingId: this.$route.query.trainingId,
        name: this.searchInput,
        lessonId: this.lessonId,
        lessonId1: this.user.lessonId ? this.user.lessonId : "",
        pageSize: this.pageSize,
        startNum: this.currentPage,
      });
      if (res.data.result == "success") {
        this.tableData = res.data.platformTrainingCompanyDataList;
        this.totalItems = res.data.size;
      }
      console.log(res);
    },
    // 获取课程列表
    async getAddLessonList(id) {
      let res = await getAddLessonList({
        traingId: id,
      });
      if (res.data.result == "success") {
        this.lessonList = res.data.msg;
      }
    },
    // 搜索
    search() {
      // Implement search functionality
      this.getUserSchedule(this.user.userId);
    },
    // 重置
    reset() {
      // Implement reset functionality
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getUserSchedule(this.user.userId);
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.getUserSchedule(this.user.userId);
    },
  },
  mounted() {
    // Fetch address options for cascader
    // Fetch initial data for table
  },
};
</script>

<style scoped>
/* Add custom styles here */
#trainingInquiryList {
  padding: 20px;
}
.el-row {
  margin-bottom: 20px;
}
.ml-20 {
  margin-left: 20px;
}
</style>
