import axios from "../plugins/axios";

// 用户注册
export const userRegisterApi = (param) =>
  axios.post("/api/user/register", param);

// 用户登录(手机号)
export const userLoginApi = (param) => axios.post("/api/user/backLogin", param);

// 用户登录(账号)
export const adminBackLogin = (param) =>
  axios.post("/api/user/adminBackLogin", param);

// 用户找回密码
export const userRetrieveApi = (param) => axios.post("/", param);

/**
 * 修改密码
 * @param {id,oldPassword,password,confirmPassword} param
 * id-管理员ID
 * oldPassword-原密码
 * password-新密码
 * confirmPassword-确认密码
 * @returns
 */
export const updateBackAdminPassword = (param) =>
  axios.post("/api/user/updateBackAdminPassword", param);

// 获取验证码
export const sendSms = (param) =>
  axios.get("/api/captcha/sendSms?phone=" + param.phone);

// 获取行政地区
export const findCityAllList = () => axios.get("api/county/findCityAllList"); // 所有行政地区
export const findProviceList = () => {
  return axios({
    url: "api/county/findProviceList",
    methods: "get",
    isLoading: false,
  });
};
export const findCityList = (param) => {
  return axios({
    url: "api/county/findCityList?parentId=" + param.id,
    methods: "get",
    isLoading: false,
  });
};
export const findDistrictList = (param) => {
  return axios({
    url: "api/county/findDistrictList?parentId=" + param.id,
    methods: "get",
    isLoading: false,
  });
};
export const findStreetList = (param) => {
  return axios({
    url: "api/county/findStreetList?parentId=" + param.id,
    methods: "get",
    isLoading: false,
  });
};
