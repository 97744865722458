<template>
  <div id="view_container">
    <el-container>
      <NavMenu
        :navMenuList="navMenuList"
        :activeIndex="activeIndex"
        @changeMenuIndex="changeMenuIndex"
      ></NavMenu>

      <el-container>
        <el-header style="padding: 0 5px 10px; height: 80px"
          ><HeaderContent :navigateItem="navigateItem"></HeaderContent
        ></el-header>
        <el-main>
          <MainContent></MainContent>
        </el-main>
        <!-- <el-footer>Footer</el-footer> -->
      </el-container>
    </el-container>
  </div>
</template>

<script>
import NavMenu from "@/components/NavMenu.vue";
import HeaderContent from "@/components/HeaderContent.vue";
import MainContent from "@/components/MainContent.vue";
import {
  SUCCESS_DATA,
  NATIONAL_MENU_DATA,
  PROVICE_MENU_DATA,
  CITY_MENU_DATA,
  DISTRICT_MENU_DATA,
  STREET_MENU_DATA,
  TRAINING_ADMINISTRATOR_MENU_DATA,
} from "../plugins/menu.mock";
export default {
  components: {
    NavMenu,
    HeaderContent,
    MainContent,
  },
  data() {
    return {
      navMenuList: [],
      activeIndex: "/domesticConsumer",
      navigateItem: [],
    };
  },
  watch: {},
  computed: {
    // 用户管理员权限
    adminLevel() {
      return JSON.parse(sessionStorage.getItem("userInfo")).power;
    },
    // 用户管理员权限
    userIsPrimaryAdmin() {
      return JSON.parse(sessionStorage.getItem("userInfo")).isPrimaryAdmin;
    },
  },
  mounted() {
    this.$store.state.userPower = this.adminLevel;
    this.$store.state.userIsPrimaryAdmin = this.userIsPrimaryAdmin;
  },
  methods: {
    getMenuList() {
      const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      if (userInfo.flag == 1 || userInfo.companyAdmin == true) {
        this.navMenuList = TRAINING_ADMINISTRATOR_MENU_DATA.data;
        return;
      }
      switch (this.adminLevel) {
        case 2:
          this.navMenuList = NATIONAL_MENU_DATA.data;
          break;
        case 3:
          this.navMenuList = PROVICE_MENU_DATA.data;
          break;
        case 4:
          this.navMenuList = CITY_MENU_DATA.data;
          break;
        case 5:
          this.navMenuList = DISTRICT_MENU_DATA.data;
          break;
        case 6:
          this.navMenuList = STREET_MENU_DATA.data;
          break;
        default:
          this.navMenuList = SUCCESS_DATA.data;
      }
    },
    changeMenuIndex(indexPah) {
      this.activeIndex = indexPah[indexPah.length - 1];
      this.navigateItem = [];
      for (let i = 0; i < this.navMenuList.length; i++) {
        for (let j = 0; j < indexPah.length; j++) {
          if (
            this.navMenuList[i].menuIndex == indexPah[j] &&
            this.navMenuList[i].childrens.length != 0
          ) {
            for (let c = 0; c < this.navMenuList[i].childrens.length; c++) {
              for (let y = 0; y < indexPah.length; y++) {
                if (this.navMenuList[i].childrens[c].menuIndex == indexPah[y]) {
                  // console.log(this.navMenuList[i].childrens[c]);
                  this.navigateItem.push(this.navMenuList[i].childrens[c]);
                  sessionStorage.setItem(
                    "navigateIndex",
                    JSON.stringify(this.navMenuList[i].childrens[c])
                  );
                }
              }
            }
          } else if (this.navMenuList[i].menuIndex == indexPah[j]) {
            // console.log(this.navMenuList[i]);
            this.navigateItem.push(this.navMenuList[i]);

            sessionStorage.setItem(
              "navigateIndex",
              JSON.stringify(this.navMenuList[i])
            );
          }
        }
      }
    },
  },
  created() {
    if (sessionStorage.getItem("umeetCloudVuex")) {
      this.activeIndex = JSON.parse(sessionStorage.getItem("umeetCloudVuex"))
        .breadListState[0]
        ? JSON.parse(sessionStorage.getItem("umeetCloudVuex")).breadListState[0]
            .path
        : "";
    } else {
      if (sessionStorage.getItem("navigateIndex")) {
        this.activeIndex = JSON.parse(
          sessionStorage.getItem("navigateIndex")
        ).menuIndex;
      }
    }
    this.getMenuList();
  },
};
</script>
<style scoped>
#view_container {
  width: 100%;
  height: 100vh;
}
.el-container {
  width: 100%;
  height: 100%;
}
.el-header {
  /* background-color: #b3c0d1; */
  color: #333;
  text-align: center;
}

.el-main {
  /* background-color: #e9eef3; */
  color: #333;
}
.el-card__body,
.el-main {
  padding: 20px 5px;
}
body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
</style>
