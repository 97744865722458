<template>
  <div class="update-admin">
    <el-row class="mb-20">
      <el-col>管理员账号：{{ adminRoleData.account }}</el-col>
    </el-row>
    <el-row class="mb-20">
      <el-col>管理员姓名：{{ adminRoleData.name }}</el-col>
    </el-row>
    <el-row class="mb-20">
      <el-col>管理员联系电话：{{ adminRoleData.phone }}</el-col>
    </el-row>
    <el-row class="mb-20">
      <el-col :span="3">选择管理员角色</el-col>
      <el-col :span="18">
        <el-radio-group v-model="radio">
          <el-radio
            v-if="$store.state.userPower == 1 || $store.state.userPower == 2"
            :label="2"
            >国家级管理员</el-radio
          >
          <el-radio
            v-if="$store.state.userPower == 1 || $store.state.userPower == 2"
            :label="3"
            >省级管理员</el-radio
          >
          <el-radio
            v-if="
              $store.state.userPower == 1 ||
              $store.state.userPower == 2 ||
              $store.state.userPower == 3
            "
            :label="4"
            >市级管理员</el-radio
          >
          <el-radio
            v-if="
              $store.state.userPower == 1 ||
              $store.state.userPower == 2 ||
              $store.state.userPower == 3 ||
              $store.state.userPower == 4
            "
            :label="5"
            >区(县)级管理员</el-radio
          >
          <el-radio
            v-if="
              $store.state.userPower == 1 ||
              $store.state.userPower == 2 ||
              $store.state.userPower == 3 ||
              $store.state.userPower == 4 ||
              $store.state.userPower == 5
            "
            :label="6"
            >街道级管理员</el-radio
          >
        </el-radio-group>
      </el-col>
    </el-row>
    <template v-if="radio == 2">
      <el-row class="mb-20">
        <el-col :span="24">
          <span>设置所在政府部门：</span>
          <el-select
            v-model="governmentId"
            placeholder="请选择所在政府部门"
            clearable
          >
            <el-option
              v-for="item in governmentSectorList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </template>
    <template v-if="radio == 3">
      <el-row class="mb-20">
        <el-col :span="24">
          <span class="ml-20">省：</span>
          <el-select
            v-model="provId"
            placeholder="请选择省"
            @clear="clearProvId"
            clearable
            @change="getFindCityList(provId)"
          >
            <el-option
              v-for="item in provList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row class="mb-20">
        <el-col :span="24">
          <span>设置所在政府部门：</span>
          <el-select
            v-model="governmentId"
            placeholder="请选择所在政府部门"
            clearable
          >
            <el-option
              v-for="item in governmentSectorList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </template>
    <template v-if="radio == 4">
      <el-row class="mb-20">
        <el-col :span="24">
          <span class="ml-20">省：</span>
          <el-select
            v-model="provId"
            placeholder="请选择省"
            clearable
            @clear="clearProvId"
            @change="getFindCityList(provId)"
            :disabled="$store.state.userPower == 3"
          >
            <el-option
              v-for="item in provList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <span class="ml-20">市：</span>
          <el-select
            v-model="cityId"
            placeholder="请选择市"
            @clear="clearCityId"
            @change="getFindDistrictList(cityId)"
            clearable
          >
            <el-option
              v-for="item in cityList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row class="mb-20">
        <el-col :span="24">
          <span>设置所在政府部门：</span>
          <el-select
            v-model="governmentId"
            placeholder="请选择所在政府部门"
            clearable
          >
            <el-option
              v-for="item in governmentSectorList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </template>
    <template v-if="radio == 5">
      <el-row class="mb-20">
        <el-col :span="24">
          <span class="ml-20">省：</span>
          <el-select
            v-model="provId"
            placeholder="请选择省"
            @clear="clearProvId"
            clearable
            @change="getFindCityList(provId)"
            :disabled="
              $store.state.userPower == 3 || $store.state.userPower == 4
            "
          >
            <el-option
              v-for="item in provList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <span class="ml-20">市：</span>
          <el-select
            v-model="cityId"
            placeholder="请选择市"
            @change="getFindDistrictList(cityId)"
            @clear="clearCityId"
            clearable
            :disabled="$store.state.userPower == 4"
          >
            <el-option
              v-for="item in cityList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <span class="ml-20">区：</span>
          <el-select
            v-model="regionId"
            placeholder="请选择区"
            @change="getFindStreetList(regionId)"
            @clear="clearRegionId"
            clearable
          >
            <el-option
              v-for="item in regionList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row class="mb-20">
        <el-col :span="24">
          <span>设置所在政府部门：</span>
          <el-select
            v-model="governmentId"
            placeholder="请选择所在政府部门"
            clearable
          >
            <el-option
              v-for="item in governmentSectorList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </template>
    <template v-if="radio == 6">
      <el-row class="mb-20">
        <el-col :span="24">
          <span class="ml-20">省：</span>
          <el-select
            v-model="provId"
            placeholder="请选择省"
            @clear="clearProvId"
            clearable
            @change="getFindCityList(provId)"
            :disabled="
              $store.state.userPower == 3 ||
              $store.state.userPower == 4 ||
              $store.state.userPower == 5
            "
          >
            <el-option
              v-for="item in provList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <span class="ml-20">市：</span>
          <el-select
            v-model="cityId"
            placeholder="请选择市"
            @change="getFindDistrictList(cityId)"
            @clear="clearCityId"
            clearable
            :disabled="
              $store.state.userPower == 4 || $store.state.userPower == 5
            "
          >
            <el-option
              v-for="item in cityList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <span class="ml-20">区：</span>
          <el-select
            v-model="regionId"
            placeholder="请选择区"
            @change="getFindStreetList(regionId)"
            @clear="clearRegionId"
            clearable
            :disabled="$store.state.userPower == 5"
          >
            <el-option
              v-for="item in regionList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <span class="ml-20">街道：</span>
          <el-select v-model="streetId" placeholder="请选择街道" clearable>
            <el-option
              v-for="item in streetList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row class="mb-20">
        <el-col :span="24">
          <span>设置所在政府部门：</span>
          <el-select
            v-model="governmentId"
            placeholder="请选择所在政府部门"
            clearable
          >
            <el-option
              v-for="item in governmentSectorList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </template>
    <el-row class="mb-20">
      <el-col :span="6">&nbsp;</el-col>
      <el-col :span="18"
        ><el-button type="primary" @click="updateAdminRole"
          >保存</el-button
        ></el-col
      >
    </el-row>
  </div>
</template>

<script>
import {
  findCityList,
  findDistrictList,
  findProviceList,
  findStreetList,
} from "@/api/userLogin";
import { updateAdminRole } from "@/api/governmentManagement";
import {
  queryNationEducationGovDepartmentByPower,
  queryEducationGovDepartmentById,
} from "@/api/common";
export default {
  data() {
    return {
      radio: 2,
      provList: [], //省级
      cityList: [], //市级列表
      regionList: [], // 区级
      streetList: [], // 街道
      governmentSectorList: [],
      governmentId: "",
      provId: "",
      cityId: "",
      regionId: "",
      streetId: "",
      adminRoleData: "",
    };
  },
  watch: {
    provId(val) {
      this.governmentSectorList = [];
      if (this.radio == 3 && val) {
        this.queryEducationGovDepartmentById(val);
      }
    },
    cityId(val) {
      this.governmentSectorList = [];
      if (this.radio == 4 && val) {
        this.queryEducationGovDepartmentById(val);
      }
    },
    regionId(val) {
      this.governmentSectorList = [];
      if (this.radio == 5 && val) {
        this.queryEducationGovDepartmentById(val);
      }
    },
    streetId(val) {
      this.governmentSectorList = [];
      if (this.radio == 6 && val) {
        this.queryEducationGovDepartmentById(val);
      }
    },
    radio(val) {
      this.governmentSectorList = [];
      if (val == 2) {
        this.queryNationEducationGovDepartmentByPower();
      }
      if (val == 3 && this.provId) {
        this.queryEducationGovDepartmentById(this.provId);
      }
      if (val == 4 && this.cityId) {
        this.queryEducationGovDepartmentById(this.cityId);
      }
      if (val == 5 && this.regionId) {
        this.queryEducationGovDepartmentById(this.regionId);
      }
      if (val == 6 && this.streetId) {
        this.queryEducationGovDepartmentById(this.streetId);
      }
    },
  },
  created() {
    this.getFindProviceList();
    switch (this.$store.state.userPower) {
      case 3:
        this.provId = this.$store.state.userInfo.province;
        this.radio = 4;
        break;
      case 4:
        this.provId = this.$store.state.userInfo.province;
        this.cityId = this.$store.state.userInfo.city;
        this.radio = 5;
        break;
      case 5:
        this.provId = this.$store.state.userInfo.province;
        this.cityId = this.$store.state.userInfo.city;
        this.regionId = this.$store.state.userInfo.region;
        this.radio = 6;
        break;
      case 6:
        this.provId = this.$store.state.userInfo.province;
        this.cityId = this.$store.state.userInfo.city;
        this.regionId = this.$store.state.userInfo.region;
        this.streetId = this.$store.state.userInfo.street;
        break;
    }
    this.adminRoleData = JSON.parse(sessionStorage.getItem("adminRoleData"));
    this.getFindProviceList();
    this.queryNationEducationGovDepartmentByPower();
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem(
        "adminRoleData",
        JSON.stringify(this.adminRoleData)
      );
    });
  },
  beforeDestroy() {
    sessionStorage.removeItem("adminRoleData");
  },
  mounted() {
    this.getFindCityList(this.provId);
    this.getFindDistrictList(this.cityId);
    this.getFindStreetList(this.regionId);
  },
  methods: {
    // 获取国家级政府部门
    async queryNationEducationGovDepartmentByPower() {
      let res = await queryNationEducationGovDepartmentByPower();
      if (res.data.length > 0) {
        this.governmentSectorList = res.data;
      }
    },
    // 获取省市区街道政府部门
    async queryEducationGovDepartmentById(id) {
      let res = await queryEducationGovDepartmentById({ id: id });
      if (res.data.length > 0) {
        this.governmentSectorList = res.data;
      }
    },
    // 更改管理员角色
    async updateAdminRole() {
      let res = await updateAdminRole({
        id: this.$route.query.id,
        power: this.radio,
        province: this.provId,
        city: this.cityId,
        region: this.regionId,
        street: this.streetId,
        departmentId: this.governmentId,
      });
      if (res.data.result == "success") {
        this.$message({
          type: "success",
          message: res.data.msg,
        });
        this.$router.go(-1);
      } else {
        this.$message({
          type: "error",
          message: res.data.msg,
        });
      }
    },
    // 获取省
    async getFindProviceList() {
      let res = await findProviceList();
      if (res.data.result == "success") {
        this.provList = res.data.msg;
      }
    },
    // 获取市
    async getFindCityList(id) {
      let res = await findCityList({ id: id });
      if (res.data.result == "success") {
        this.cityList = res.data.msg;
      }
    },
    // 获取区
    async getFindDistrictList(id) {
      let res = await findDistrictList({ id: id });
      if (res.data.result == "success") {
        this.regionList = res.data.msg;
      }
    },
    // 获取街道
    async getFindStreetList(id) {
      let res = await findStreetList({ id: id });
      if (res.data.result == "success") {
        this.streetList = res.data.msg;
      }
    },
    // 清空省触发
    clearProvId() {
      this.cityId = "";
      this.regionId = "";
      this.streetId = "";
      this.cityList = [];
      this.regionList = [];
      this.streetList = [];
    },
    // 清空市触发
    clearCityId() {
      this.regionId = "";
      this.streetId = "";
      this.regionList = [];
      this.streetList = [];
    },
    // 清空区触发
    clearRegionId() {
      this.streetId = "";
      this.streetList = [];
    },
  },
};
</script>

<style scoped>
.update-admin {
  padding: 50px 100px;
}
</style>
