<template>
  <div class="form-container">
    <el-tabs
      v-model="activeTab"
      class="login-tabs"
      :before-leave="beforeTabLeave"
    >
      <el-tab-pane label="培训基本设置" name="trainSetting">
        <el-form
          ref="trainingForm"
          :rules="trainingFormRules"
          :model="trainingForm"
          label-width="200px"
        >
          <el-form-item label="培训名称" prop="name">
            <el-input
              placeholder="请输入培训名称"
              v-model="trainingForm.name"
              maxlength="30"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="封面图" prop="cover">
            <el-upload
              class="avatar-uploader"
              action="/course/oss/uploadFile"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img
                v-if="trainingForm.cover"
                :src="trainingForm.cover"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="培训简介" prop="summary">
            <WangEditorVue @getEditorContent="getEditorContent"></WangEditorVue>
          </el-form-item>
          <el-form-item label="培训时间" prop="trainingTime">
            <el-date-picker
              v-model="trainingForm.trainingTime"
              type="datetimerange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00']"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="地区设置" prop="area">
            <div class="select-area">
              <el-scrollbar>
                <el-tree
                  :data="areaList"
                  show-checkbox
                  node-key="id"
                  ref="tree"
                  highlight-current
                  :props="defaultProps"
                  @check-change="handleCheckChangeArea"
                  @check="currentChangeArea"
                >
                </el-tree>
              </el-scrollbar>
            </div>
          </el-form-item>
          <el-form-item label="参与培训的岗位">
            <template>
              <!-- <el-radio-group v-model="trainingForm.trainingPosition">
            <el-radio label="all">全部</el-radio>
            <el-radio label="custom">自定义</el-radio>
          </el-radio-group> -->
              <el-table
                v-if="
                  selectedPositionList.length != 0 &&
                  trainingForm.trainingPosition == 'custom'
                "
                :data="selectedPositionList"
                max-height="200"
                :show-header="false"
              >
                <el-table-column type="index"></el-table-column>
                <el-table-column prop="postionName"></el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <!-- <i class="el-icon-edit-outline icon-style"></i> -->
                    <i
                      class="el-icon-delete icon-style"
                      @click="deleteSelectedPosition(scope)"
                    ></i>
                  </template>
                </el-table-column>
              </el-table>
              <div v-if="trainingForm.trainingPosition == 'custom'">
                <!-- <div style="color: #d6d6d6" v-if="selectedPositionList.length == 0">
              未设置则默认全部岗位
            </div> -->
                <div class="position-setting" @click="dialogPositionSetting">
                  <i class="el-icon-plus"></i>
                  <span>添加岗位</span>
                </div>

                <el-alert
                  v-if="this.trainingForm.area.length == 0"
                  title="请先选择培训地区"
                  type="warning"
                  show-icon
                  :closable="false"
                  style="width: 500px; height: 30px; margin-top: 10px"
                >
                </el-alert>
              </div>
              <dialogPositionVue
                :showDialogPositionSetting="showDialogPositionSetting"
                @colseDialog="colseDialog"
                @selectedPosition="selectedPosition"
              ></dialogPositionVue>
            </template>
          </el-form-item>
          <!-- <el-form-item label="参与培训的单位">
            <template>
              <el-table
                v-if="
                  selectedUnitList.length != 0 &&
                  trainingForm.trainingUnit == 'custom'
                "
                :data="selectedUnitList"
                max-height="200"
                :show-header="false"
              >
                <el-table-column type="index"></el-table-column>
                <el-table-column prop="companyName"></el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <i
                      class="el-icon-delete icon-style"
                      @click="deleteSelectedUnit(scope)"
                    ></i>
                  </template>
                </el-table-column>
              </el-table>
              <div v-if="trainingForm.trainingUnit == 'custom'">
                <div class="unit-setting" @click="dialogUnitSetting">
                  <i class="el-icon-plus"></i>
                  <span>添加单位</span>
                </div>
                <el-alert
                  v-if="this.trainingForm.area.length == 0"
                  title="请先选择培训地区"
                  type="warning"
                  show-icon
                  :closable="false"
                  style="width: 500px; height: 30px; margin-top: 10px"
                >
                </el-alert>
              </div>
              <dialogUnitVue
                :showDialogUnitSetting="showDialogUnitSetting"
                :areaIds="trainingForm.area"
                @colseDialog="colseDialog"
                @selectedUnit="selectedUnit"
                :selectedUnitList="selectedUnitList"
              ></dialogUnitVue>
            </template>
          </el-form-item> -->
          <el-form-item label="选择课程" prop="course">
            <el-table
              v-if="selectedCourseTableList.length != 0"
              :data="selectedCourseTableList"
              max-height="200"
              :show-header="false"
            >
              <el-table-column type="index"></el-table-column>
              <!-- <el-table-column prop="position.postionName"></el-table-column>
              <el-table-column prop="company.companyName"></el-table-column> -->
              <el-table-column prop="lesson.name"></el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <!-- <i class="el-icon-edit-outline icon-style"></i> -->
                  <i
                    class="el-icon-delete icon-style"
                    @click="deleteSelectedCourse(scope)"
                  ></i>
                  <el-button
                    type="text"
                    @click="dialogHoursSetting(scope.row, scope.$index)"
                    >设置必修课时</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <!-- <el-button
          type="text"
          v-if="selectedCourseTableList.length > 2"
          @click="$router.push({ path: '/moreTrainingCourse' })"
          >查看更多培训课程</el-button
        > -->
            <template>
              <div class="select-course" @click="dialogCourseSetting">
                <i class="el-icon-plus"></i>
                <span>课程设置</span>
              </div>
            </template>
            <el-alert
              v-if="
                // this.selectedUnitList.length == 0 ||
                this.selectedPositionList.length == 0
              "
              title="请先选择岗位"
              type="warning"
              show-icon
              :closable="false"
              style="width: 500px; height: 30px; margin-top: 10px"
            >
            </el-alert>
            <dialogCourseVue
              :showDialogCourseSetting="showDialogCourseSetting"
              @colseDialog="colseDialog"
              @selectedUnitAndPositionAndCourse="
                selectedUnitAndPositionAndCourse
              "
              :selectedUnitList="
                selectedUnitList.length == 0
                  ? $store.state.allUnitList
                  : selectedUnitList
              "
              :selectedPositionList="
                selectedPositionList.length == 0
                  ? $store.state.allPositionList
                  : selectedPositionList
              "
              :trainingPosition="trainingForm.trainingPosition"
              :trainingUnit="trainingForm.trainingUnit"
              :selectedCourseTableList="selectedCourseTableList"
              :courseIds="trainingForm.courseIds"
            ></dialogCourseVue>
          </el-form-item>
          <el-form-item label="是否公开" prop="status">
            <el-switch v-model="trainingForm.status"> </el-switch>
          </el-form-item>
          <el-form-item label="报名人数限制" prop="trainingCount">
            <el-radio-group v-model="trainingForm.trainingCountState">
              <el-radio :label="false">不限</el-radio>
              <el-radio :label="true">限制人数</el-radio>
              <el-input-number
                v-if="trainingForm.trainingCountState"
                @change="toInteger"
                :min="1"
                :step="1"
                v-model.number="trainingForm.trainingCount"
              ></el-input-number>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否开启申请证书操作" prop="isCertificateApply">
            <el-radio-group v-model="trainingForm.isCertificateApply">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="收费模式" prop="chargeMode">
            <el-radio-group v-model="trainingForm.chargeMode">
              <el-radio :label="0">免费</el-radio>
              <el-radio :label="1">收费</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item
            label="价格"
            prop="price"
            v-if="trainingForm.chargeMode === 1"
          >
            <el-input
              type="number"
              min="0"
              step="0.01"
              placeholder="价格"
              v-model="trainingForm.price"
              show-word-limit
              style="width: 200px"
            ></el-input>
          </el-form-item>

          <el-form-item label="证书上的课程学时" prop="classHour">
            <el-input
              placeholder="请输入证书上的课程学时"
              v-model="trainingForm.classHour"
              show-word-limit
              style="width: 200px"
            ></el-input
            >个
          </el-form-item>
          <el-form-item label="证书上的课程类型">
            <el-input
              placeholder="请输入证书上的课程类型"
              v-model="trainingForm.classType"
              maxlength="10"
              show-word-limit
              style="width: 500px"
            ></el-input>
          </el-form-item>
          <el-form-item label="专业类别">
            <el-input
              placeholder="请输入专业类别:"
              v-model="trainingForm.ProfessionalCategory"
              maxlength="50"
              type="textarea"
              show-word-limit
              style="width: 500px; height: 50px"
            ></el-input>
          </el-form-item>
          <el-form-item label="培训年份设置" prop="trainingPeriod">
            <template>
              <el-input
                v-model.number="trainingForm.trainingPeriod"
                show-word-limit
                style="width: 100px"
              ></el-input>
              年
              <div style="font-size: 14px; color: #999999">
                说明：此处设置决定证书编码上的年份编码显示的内容。
              </div>
            </template>
          </el-form-item>
          <el-form-item label="培训地区编码设置" required>
            <template>
              <el-radio v-model="encoding" label="1" @change="encodingChange"
                >按照培训行政区赋值编码</el-radio
              >
              <el-radio v-model="encoding" label="2" @change="encodingChange"
                >按照用户所在地赋值编码</el-radio
              >
              <div v-if="this.encoding == 1">
                <el-form-item prop="trainingArea">
                  设置培训地区编码:
                  <el-input
                    v-model="trainingForm.trainingArea"
                    show-word-limit
                    style="width: 150px"
                  ></el-input>
                  <div style="font-size: 14px; color: #999999">
                    说明：此处设置的编码将赋值于本培训所颁发的所有证书的地区编码上，此处必须设置6位数。
                  </div>
                </el-form-item>
              </div>
              <div v-if="this.encoding == 2">
                <el-form-item prop="UserregionArea">
                  默认培训地区编码:
                  <el-input
                    v-model="trainingForm.UserregionArea"
                    show-word-limit
                    style="width: 150px"
                  ></el-input>
                </el-form-item>
                <div style="margin-top: 8px">
                  用户所在地编码调取层级:
                  <el-radio-group v-model="CodingHierarchy">
                    <el-radio :label="1">省级</el-radio>
                    <el-radio :label="2">市级</el-radio>
                    <el-radio :label="3">区县级</el-radio>
                  </el-radio-group>
                </div>
                <div style="font-size: 14px; color: #999999">
                  <p>说明：</p>
                  <p style="margin: -25px 0 -25px 0">
                    1、如果用户没有设置所在地的话，则赋予用户默认行政区编码，此处必须设置6位数。
                  </p>
                  <p>
                    2、调取层级表示系统将调取用户所在地编码的哪一层级，例如选择省级，则只调取省级编码，这样给用户颁发的证书的地区编码则为“xx0000”
                  </p>
                </div>
              </div>
            </template>
          </el-form-item>
          <el-form-item class="button-container">
            <el-button
              style="width: 150px"
              type="primary"
              @click="trainingNextStep('trainingForm')"
              >下一页</el-button
            >
            <el-button style="width: 150px" type="test" @click="cancel"
              >取消</el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="所用章设置" name="chapterUsed">
        <el-form
          :model="dynamicValidateForm"
          ref="dynamicValidateForm"
          label-width="100px"
          class="demo-dynamic"
        >
          <h3>考试机构章设置</h3>
          <!-- :prop="'domains.' + index + '.examineSealName'"
            :rules="{
              required: true,
              message: '请输入章的名称',
              trigger: 'blur',
            }" -->
          <el-form-item
            v-for="(domain, index) in dynamicValidateForm.domains"
            :label="index > 0 ? '章的名称' + index : '章的名称'"
            :key="domain.key"
          >
            <el-input
              v-model="domain.examineSealName"
              placeholder="请输入章的名称"
            ></el-input>

            <span>{{
              index > 0 ? "章" + index + "(500X500)" : "章(500X500)"
            }}</span>
            <el-upload
              class="avatar-uploader"
              :show-file-list="false"
              action="none"
              :auto-upload="false"
              :on-change="templateFileChange"
            >
              <img
                @click="templateFileClick(index)"
                v-if="domain.examineSealUrl"
                :src="domain.examineSealUrl"
                class="avatar"
              />

              <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
                @click="templateFileClick(index)"
              ></i>
            </el-upload>
            <el-button @click.prevent="removeDomain(domain)">删除</el-button>
          </el-form-item>
          <el-form-item>
            <div class="position-setting" @click="addDomain">
              <i class="el-icon-plus"></i>
              <span>章</span>
            </div>
          </el-form-item>
          <h3>培训机构章设置</h3>
          <!-- :prop="'trainingDomains.' + index + '.trainingSealName'"
            :rules="{
              required: true,
              message: '请输入章的名称',
              trigger: 'blur',
            }" -->
          <el-form-item
            v-for="(domain, index) in dynamicValidateForm.trainingDomains"
            :label="index > 0 ? '章的名称' + index : '章的名称'"
            :key="domain.key"
          >
            <el-input
              v-model="domain.trainingSealName"
              placeholder="请输入章的名称"
            ></el-input>

            <span>章(500X500)</span>
            <el-upload
              class="avatar-uploader"
              :show-file-list="false"
              action="none"
              :auto-upload="false"
              :on-change="trainingFileChange"
            >
              <img
                @click="trainingFileClick(index)"
                v-if="domain.trainingSealUrl"
                :src="domain.trainingSealUrl"
                class="avatar"
              />

              <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
                @click="trainingFileClick(index)"
              ></i>
            </el-upload>
            <el-button @click.prevent="trainingRemoveDomain(domain)"
              >删除</el-button
            >
          </el-form-item>
          <el-form-item>
            <div class="position-setting" @click="trainingAddDomain">
              <i class="el-icon-plus"></i>
              <span>章</span>
            </div>
          </el-form-item>
          <el-form-item style="text-align: center; margin-top: 100px">
            <el-button style="width: 150px" type="primary" @click="previousPage"
              >上一页</el-button
            >
            <el-button
              style="width: 150px"
              type="primary"
              @click="dynamicNextStep('dynamicValidateForm')"
              >下一步</el-button
            >
            <el-button style="width: 150px" type="test" @click="cancel"
              >取消</el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="考试及直播设置" name="examination">
        <el-form
          ref="examinationForm"
          :rules="examinationFormRules"
          :model="examinationForm"
          label-width="200px"
        >
          <el-form-item label="考试GUID" prop="id">
            <el-input
              placeholder="请输入考试GUID"
              v-model="examinationForm.id"
            ></el-input>
            <span style="color: #999999"
              >说明：考试GUID可到“考试列表”当中进行查看。</span
            >
          </el-form-item>
          <!-- <el-form-item label="考试链接" prop="url">
            <el-input
              placeholder="请输入考试链接"
              v-model="examinationForm.url"
            ></el-input>
            <span style="color: #999999"
              >说明：输入时请带着“http://”或“https://”。</span
            >
          </el-form-item> -->
          <el-form-item label="直播链接">
            <el-input
              placeholder="请输入直播链接"
              v-model="examinationForm.liveUrl"
            ></el-input>
            <span style="color: #999999"
              >说明：输入时请带着“http://”或“https://”。</span
            >
          </el-form-item>
          <el-form-item style="text-align: center; margin-top: 100px">
            <el-button style="width: 150px" type="primary" @click="previousPage"
              >上一页</el-button
            >
            <el-button
              style="width: 150px"
              type="primary"
              @click="examinationNextStep('examinationForm')"
              >完成</el-button
            >
            <el-button style="width: 150px" type="test" @click="cancel"
              >取消</el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <dialogHoursVue
      :selectedHourList="selectedHourList"
      :showDialogHoursSetting="showDialogHoursSetting"
      :currentSetHours="currentSetHours"
      :currentSetCourseIndex="currentSetCourseIndex"
      @colseDialog="colseDialog"
      @selectedCourseHours="selectedCourseHours"
    ></dialogHoursVue>
  </div>
</template>

<script>
import { findCityAllList } from "@/api/userLogin";
import { savePlatformTraining, saveTrainingSeal } from "@/api/trainingManage";
import { saveTrainingExam } from "@/api/examinationApi";
import { msToDate } from "@/plugins/msToDate";
import { uploadFile } from "@/api/certificateTemplate";
import dialogPositionVue from "./dialogTraining/dialogPosition.vue";
// import dialogUnitVue from "./dialogTraining/dialogUnit.vue";
import dialogCourseVue from "./dialogTraining/dialogCourse.vue";
import dialogHoursVue from "./dialogTraining/dialogHours.vue";
import WangEditorVue from "@/components/WangEditor.vue";

export default {
  components: {
    dialogPositionVue,
    // dialogUnitVue,
    dialogCourseVue,
    dialogHoursVue,
    WangEditorVue,
  },
  data() {
    var checkAge = (rule, value, callback) => {
      var timeformat = /^(20[1-2][0-9])$/;
      if (!value) {
        return callback(new Error("年份不能为空"));
      } else if (!timeformat.test(value)) {
        callback(new Error("请输入类似于 2020-2022 格式的内容"));
      } else {
        callback();
      }
    };
    return {
      radio: "1",
      encoding: "1",
      userAreaCodestatus: "",
      CodingHierarchy: "", //编码层级
      activeTab: "trainSetting",
      newActiveTab: "",
      oldActiveTab: "",
      // 培训设置数据=====
      trainingForm: {
        name: "",
        summary: "",
        trainingTime: "",
        area: [],
        trainingPosition: "custom",
        trainingUnit: "custom",
        courseIds: [],
        status: true,
        trainingCountState: false,
        trainingCount: "1",
        isCertificateApply: false,
        classHour: "",
        classType: "",
        ProfessionalCategory: "",
        trainingPeriod: "",
        trainingArea: "",
        UserregionArea: "",
        chargeMode: 0,
        price: 0.0,
        cover: "",
      },

      trainingFormRules: {
        name: [{ required: true, message: "请输入培训名称", trigger: "blur" }],
        cover: [{ required: true, message: "请上传培训封面", trigger: "blur" }],
        summary: [
          { required: true, message: "请输入培训简介", trigger: "blur" },
        ],
        trainingTime: [
          { required: true, message: "请选择培训时间", trigger: "change" },
        ],
        area: [{ required: true, message: "请选择地区", trigger: "click" }],
        courseIds: [
          { required: true, message: "请选择课程", trigger: "click" },
        ],
        trainingPeriod: [
          { required: true, validator: checkAge, trigger: "blur" },
        ],
        trainingArea: [
          {
            required: true,
            message: "设置培训地区编码不能为空",
            trigger: "blur",
          },
          { min: 6, max: 6, message: "长度必须整数", trigger: "blur" },
        ],
        UserregionArea: [
          {
            required: true,
            message: "默认培训地区编码不能为空",
            trigger: "blur",
          },
          { min: 6, max: 6, message: "长度必须整数", trigger: "blur" },
        ],
      },
      showDialogPositionSetting: false,
      showDialogUnitSetting: false,
      showDialogCourseSetting: false,
      showDialogHoursSetting: false,
      areaList: [],
      provList: [],
      cityList: [],
      districtList: [],
      streetList: [],
      selectedAreaList: [], // 选中的地区
      selectedUnitList: [], // 选中的单位
      selectedPositionList: [], // 选中的岗位
      selectedCourseList: [], // 选中的课程单位岗位id
      selectedHourList: [], // 当前课程中的必修课时id
      selectedCourseTableList: [], // 选中的课程对象
      defaultProps: {
        children: "children",
        label: "name",
      },
      trainingSettingData: {},
      // 所用章设置数据=====
      dynamicValidateForm: {
        // 考试机构
        domains: [
          {
            examineSealName: "",
            examineSealUrl: "",
          },
        ],
        // 培训机构
        trainingDomains: [
          {
            trainingSealName: "",
            trainingSealUrl: "",
          },
        ],
      },
      // 考试设置数据=====
      examinationForm: {
        id: "",
        url: "",
        liveUrl: "",
      },
      examinationFormRules: {
        id: [{ required: true, message: "请输入培训名称", trigger: "blur" }],
        url: [{ required: true, message: "请输入培训简介", trigger: "blur" }],
      },
      dynamicUploadIndex: 0,
      trainingUploadIndex: 0,
      currentSetHours: {},
      currentSetCourseIndex: 0,
    };
  },
  created() {
    this.getFindCityAllList();
  },
  computed: {},
  watch: {
    showDialogUnitSetting(newVal) {
      if (!newVal) {
        this.selectedUnitList = this.selectedUnitList.filter(
          (obj, index) =>
            this.selectedUnitList.findIndex((item) => item.id === obj.id) ===
            index
        );
      }
    },
    showDialogHoursSetting(val) {
      if (!val) {
        this.selectedHourList = [];
      }
    },
  },
  methods: {
    radioChange(e) {
      console.log(e, this.radio, "sdd");
    },
    encodingChange(e) {
      console.log(e, this.encoding, "sdd");
      if (e == 1) {
        this.userAreaCodestatus = false;
      } else {
        this.userAreaCodestatus = true;
      }
    },
    // 获取富文本中的内容
    getEditorContent(val) {
      console.log("val", val);
      this.trainingForm.summary = val;
    },
    // tab栏切换时触发
    beforeTabLeave(newActiveName, oldActiveName) {
      let flag = true;
      this.$refs["trainingForm"].validate((valid) => {
        if (valid) {
          this.newActiveTab = newActiveName;
          this.oldActiveTab = oldActiveName;
          flag = true;
        } else {
          flag = false;
        }
      });
      return flag;
    },
    toInteger() {
      let reg = /^[0-9]+$/;
      if (!reg.test(this.trainingForm.trainingCount)) {
        this.$message.warning("只能输入整数");
        // 用以在dom渲染挂载后重新触发dom渲染挂载
        this.$nextTick(() => {
          this.trainingForm.trainingCount = parseInt(
            this.trainingForm.trainingCount
          );
        });
      }
    },
    // 获取地区
    async getFindCityAllList() {
      let cache = localStorage.getItem("findCityAllList");
      let cacheObj = JSON.parse(cache);
      let res;
      if (cacheObj !== null && cacheObj !== undefined) {
        console.log("res => ", cacheObj);
        res = cacheObj;
      } else {
        res = await findCityAllList();
        localStorage.setItem("findCityAllList", JSON.stringify(res));
      }
      // console.log(res, "res");
      if (res.data.result == "success") {
        this.areaList = res.data.msg;
      }
    },
    handleAvatarSuccess(res, file) {
      console.log(res.msg, file);
      // this.imageUrl = URL.createObjectURL(file.raw);
      this.trainingForm.cover = file.response.msg;
      console.log(this.trainingForm);
    },
    // 上传图片前
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传模板图片只能是 JPG和PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传模板图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    // 培训基础设置下一步
    trainingNextStep(formName) {
      console.log(formName, "1");
      if (this.encoding == 2) {
        if (!this.CodingHierarchy) {
          this.$message.error("请选择编码层级");
        }
      }
      let positionIds = [];
      // let companyIds = [];
      let lessonList = [];
      if (this.selectedPositionList.length != 0) {
        this.selectedPositionList.forEach((item) => positionIds.push(item.id));
      }
      // if (this.selectedUnitList.length != 0) {
      //   this.selectedUnitList.forEach((item) => companyIds.push(item.id));
      // }
      for (let i = 0; i < this.selectedCourseList.length; i++) {
        // for (let j = 0; j < this.selectedUnitList.length; j++) {
        for (let k = 0; k < this.selectedPositionList.length; k++) {
          // Code to be executed inside the nested for loop
          let item = {
            lessonId: this.selectedCourseList[i].lessonId,
            // companyId: this.selectedUnitList[j].id,
            positionId: this.selectedPositionList[k].id,
            hourId: this.selectedCourseList[i].hourId,
          };
          lessonList.push(item);
        }
        // }
      }
      // if (this.trainingForm.courseIds.length == 0) {
      //   this.$message.error("请选择课程");
      //   return;
      // }
      if (!this.trainingForm.trainingCountState) {
        this.trainingForm.trainingCount = "";
      }
      let param = {
        name: this.trainingForm.name,
        content: this.trainingForm.summary,
        areaList: this.trainingForm.area,
        lessonList: lessonList,
        positionIds: positionIds.join(","),
        // companyIds: companyIds.join(","),
        lessonIds: this.trainingForm.courseIds.join(","),
        userId: this.$store.state.userInfo.id,
        trainingBeginTime: msToDate(this.trainingForm.trainingTime[0]),
        trainingEndTime: msToDate(this.trainingForm.trainingTime[1]),
        status: this.trainingForm.status ? "1" : "0",
        trainingCount: this.trainingForm.trainingCount,
        isApplicationCert: this.trainingForm.isCertificateApply ? 1 : 0,
        lessonHourNumber: this.trainingForm.classHour,
        lessonType: this.trainingForm.classType,
        professionalCategory: this.trainingForm.ProfessionalCategory,
        otherYear: this.trainingForm.trainingPeriod,
        userAreaCodeStatus: this.userAreaCodestatus,
        areaCode: this.trainingForm.trainingArea
          ? this.trainingForm.trainingArea
          : this.trainingForm.UserregionArea,
        userAreaCodeLevel: this.userAreaCodestatus
          ? this.CodingHierarchy
          : null,
        price: this.trainingForm.price,
        chargeMode: this.trainingForm.chargeMode,
        cover: this.trainingForm.cover,
      };
      console.log(param, "param");
      this.$refs[formName].validate(async (valid) => {
        console.log("1");
        console.log(valid, "5");

        if (valid) {
          // 表单验证通过，提交注册
          console.log(valid, "2");

          let res = await savePlatformTraining(param);
          console.log("3");

          if (res.data.result == "success") {
            console.log(res, "res");
            // this.$message({
            //   type: "success",
            //   message: res.data.msg,
            // });
            this.activeTab = "chapterUsed";
            this.trainingSettingData = res.data.tPlatformTraining;
          }
        } else {
          // 表单验证不通过
          console.log("validate failed");
          return false;
        }
      });
    },
    // 所用章设置下一步
    dynamicNextStep(formName) {
      let param = {
        userId: this.$store.state.userInfo.id,
        traingId: this.trainingSettingData.id,
        exaimList: this.dynamicValidateForm.domains,
        trainingList: this.dynamicValidateForm.trainingDomains,
      };
      console.log(param, "param");
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // 表单验证通过，提交
          let res = await saveTrainingSeal(param);
          if (res.data.result == "success") {
            // console.log(res, "res");
            // this.$message({
            //   type: "success",
            //   message: res.data.msg,
            // });
            // this.$router.go(-1);
            this.activeTab = "examination";
          }
        } else {
          // 表单验证不通过
          console.log("validate failed");
          this.activeTab = "examination";
          return false;
        }
      });
    },
    // 考试设置下一步
    examinationNextStep(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // 表单验证通过，提交
          let res = await saveTrainingExam({
            pid: this.examinationForm.id,
            traingId: this.trainingSettingData.id,
            liveUrl: this.examinationForm.liveUrl,
          });
          if (res.data.result == "success") {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
            this.$router.go(-1);
          } else {
            this.$message({
              type: "error",
              message: res.data.msg,
            });
          }
        } else {
          // 表单验证不通过
          this.$router.go(-1);
          console.log("validate failed");
          return false;
        }
      });
    },
    // 上一页
    previousPage() {
      this.activeTab = this.oldActiveTab;
    },
    // 取消
    cancel() {
      // Cancel logic
      this.$router.go(-1);
    },
    // 当前选中的地区节点发生变化
    handleCheckChangeArea(data, checked) {
      // console.log(data, checked, indeterminate, "地区");
      if (checked) {
        if (data.level == 3) {
          this.streetList.push(data.id);
        }
      }
      if (!checked) {
        if (data.level == 3) {
          this.streetList = this.streetList.filter(
            (item) => item.id !== data.id
          );
        }
      }
      // console.log(this.streetList, "this.streetList");
    },
    currentChangeArea(checkedNodes, checkedKeys) {
      console.log(checkedNodes, checkedKeys, "checkedNodes, checkedKeys");
      // 获取选中节点的node对象
      this.nodes = checkedKeys.checkedNodes;
      let areaArr = [];
      for (let i = 0; i < this.nodes.length; i++) {
        let node = this.$refs.tree.getNode(this.nodes[i].id);
        if (node.level == 4) {
          let item = {
            street: node.data.id,
            district: node.parent.data.id,
            city: node.parent.parent.data.id,
            provice: node.parent.parent.parent.data.id,
          };
          areaArr.push(item);
        }
      }
      this.trainingForm.area = areaArr;
      console.log(this.trainingForm.area);
    },
    // 选择培训岗位弹出
    dialogPositionSetting() {
      if (this.trainingForm.area.length != 0) {
        this.showDialogPositionSetting = true;
      }
    },
    // 对话框选中的岗位
    selectedPosition(data) {
      this.selectedPositionList = data;
      this.selectedCourseTableList = [];
      this.trainingForm.courseIds = [];
    },
    // 删除选中的岗位
    deleteSelectedPosition(row) {
      console.log(row, "row", row.$index);
      this.selectedPositionList.splice(row.$index, 1);
      this.selectedCourseTableList = [];
      this.trainingForm.courseIds = [];
    },
    // 选择培训单位弹出
    dialogUnitSetting() {
      if (this.trainingForm.area.length != 0) {
        this.showDialogUnitSetting = true;
      }
    },
    // 对话框选中的单位
    selectedUnit(data) {
      this.selectedUnitList = data;
      this.selectedCourseTableList = [];
      this.trainingForm.courseIds = [];
    },
    // 删除选中的单位
    deleteSelectedUnit(row) {
      this.selectedUnitList.splice(row.$index, 1);
      this.selectedCourseTableList = [];
      this.trainingForm.courseIds = [];
    },
    // 选择课程设置弹出
    dialogCourseSetting() {
      if (
        // this.selectedUnitList.length != 0 &&
        this.selectedPositionList.length != 0
      ) {
        this.showDialogCourseSetting = true;
      }
    },
    // 对话框选中的课程
    selectedUnitAndPositionAndCourse(param) {
      this.trainingForm.courseIds = param.lessonIds;
      this.selectedCourseTableList = param.tableList;
      this.selectedCourseList = param.ids;
    },
    // 删除选中的课程
    deleteSelectedCourse(row) {
      console.log(row, "row", row.$index);
      this.selectedCourseTableList.splice(row.$index, 1);
    },
    // 设置必修课时
    dialogHoursSetting(row, index) {
      this.showDialogHoursSetting = true;
      this.currentSetHours = row;
      this.currentSetCourseIndex = index;
      if (this.currentSetHours.hourList) {
        this.selectedHourList = this.currentSetHours.hourList;
      }
      console.log(
        this.currentSetHours,
        "dialogHoursSetting",
        this.currentSetCourseIndex
      );
    },
    selectedCourseHours(val) {
      this.selectedCourseList[val.index].hourId = val.checkList.join(",");
      this.selectedCourseTableList[val.index].hourList = val.checkList;
      console.log(val, "selectedCourseHours", this.selectedCourseList);
    },
    colseDialog(val) {
      this.showDialogPositionSetting = val;
      this.showDialogUnitSetting = val;
      this.showDialogCourseSetting = val;
      this.showDialogHoursSetting = val;
    },
    // 所用章操作部分===================
    // 上传考试机构所用章
    async templateFileChange(file, fileList) {
      console.log(file, fileList, "file,fileList");
      const isJPG =
        file.raw.type === "image/jpeg" || file.raw.type === "image/png";
      const isLt2M = file.raw.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传所用章只能是 JPG和PNG 格式!");
        return;
      }
      if (!isLt2M) {
        this.$message.error("上传所用章大小不能超过 2MB!");
        return;
      }
      let formData = new FormData();
      formData.append("file", file.raw);
      let res = await uploadFile(formData);
      if (res.data.result == "success") {
        this.dynamicValidateForm.domains[
          this.dynamicUploadIndex
        ].examineSealUrl = res.data.msg;
        console.log(res, "templateFileChange");
      }
    },
    // 上传培训机构所用章
    async trainingFileChange(file, fileList) {
      console.log(file, fileList, "file,fileList");
      const isJPG =
        file.raw.type === "image/jpeg" || file.raw.type === "image/png";
      const isLt2M = file.raw.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传所用章只能是 JPG和PNG 格式!");
        return;
      }
      if (!isLt2M) {
        this.$message.error("上传所用章大小不能超过 2MB!");
        return;
      }
      let formData = new FormData();
      formData.append("file", file.raw);
      let res = await uploadFile(formData);
      if (res.data.result == "success") {
        this.dynamicValidateForm.trainingDomains[
          this.trainingUploadIndex
        ].trainingSealUrl = res.data.msg;
      }
      console.log(res, "trainingFileChange");
    },
    // 当前上传考试机构所用章索引
    templateFileClick(index) {
      console.log(index, "templateFileClick");
      this.dynamicUploadIndex = index;
    },
    // 添加考试设置
    addDomain() {
      this.dynamicValidateForm.domains.push({
        examineSealName: "",
        examineSealUrl: "",
        key: Date.now(),
      });
    },
    // 当前上传培训机构所用章索引
    trainingFileClick(index) {
      console.log(index, "templateFileClick");
      this.trainingUploadIndex = index;
    },
    // 添加培训所用章设置
    trainingAddDomain() {
      this.dynamicValidateForm.trainingDomains.push({
        trainingSealName: "",
        trainingSealUrl: "",
        key: Date.now(),
      });
    },

    // 删除考试所用章设置
    removeDomain(item) {
      let index = this.dynamicValidateForm.domains.indexOf(item);
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1);
      }
    },

    // 删除培训所用章设置
    trainingRemoveDomain(item) {
      let index = this.dynamicValidateForm.trainingDomains.indexOf(item);
      if (index !== -1) {
        this.dynamicValidateForm.trainingDomains.splice(index, 1);
      }
    },
    // 关闭对话框
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.form-container {
  margin: 50px 100px;
  max-width: 1200px;
}

.button-container {
  margin-top: 20px;
  text-align: center;
}
.area-setting,
.select-course,
.position-setting,
.unit-setting {
  border: 1px dashed #797979;
  width: 300px;
  height: 30px;
  border-radius: 10px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  margin-top: 10px;
}
.el-scrollbar {
  height: 500px;
  width: 300px;
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 20px 0 0 0;
}
.mb-20 {
  margin-bottom: 20px;
}
.icon-style {
  font-weight: 700;
  font-size: 24px;
  margin: 0 5px;
  cursor: pointer;
}
.icon-style:hover {
  color: #ccc;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  border: 1px dashed #d9d9d9;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  border: 1px dashed #d9d9d9;
  width: 178px;
  height: 178px;
  display: block;
}
</style>
