import axios from "../plugins/axios";
// 反馈列表
export const getFeedbackList = (params) =>
  axios.get(
    "/course/feedback/list?name=" +
      params.name +
      "&feedbackTime=" +
      params.feedbackTime +
      "&feedbackEndTime=" +
      params.feedbackEndTime +
      "&startNum=" +
      params.startNum +
      "&pageSize=" +
      params.pageSize
  );

//保存反馈信息 post /course/feedback/saveFeedback
