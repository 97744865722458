<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="200px">
      <el-form-item label="政府部门名称" prop="name">
        <el-input
          style="width: 600px"
          v-model="form.name"
          placeholder="请输入公告名称"
          maxlength="50"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="政府部门等级" prop="level">
        <el-radio-group v-model="form.level">
          <el-radio label="2">国家</el-radio>
          <el-radio label="3">省级</el-radio>
          <el-radio label="4">市级</el-radio>
          <el-radio label="5">区级</el-radio>
          <el-radio label="6">镇街级</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item prop="department">
        <template v-if="form.level == 3">
          <el-row class="mb-20">
            <el-col :span="24">
              <span class="ml-20">省：</span>
              <el-select
                v-model="provId"
                placeholder="请选择省"
                clearable
                @change="getFindCityList(provId)"
                @clear="clearProvId"
              >
                <el-option
                  v-for="item in provList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </template>
        <template v-if="form.level == 4">
          <el-row class="mb-20">
            <el-col :span="24">
              <span class="ml-20">省：</span>
              <el-select
                v-model="provId"
                placeholder="请选择省"
                clearable
                @change="getFindCityList(provId)"
                @clear="clearProvId"
                :disabled="$store.state.userPower == 3"
              >
                <el-option
                  v-for="item in provList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>

              <span class="ml-20">市：</span>
              <el-select
                v-model="cityId"
                placeholder="请选择市"
                @change="getFindDistrictList(cityId)"
                @clear="clearCityId"
                clearable
              >
                <el-option
                  v-for="item in cityList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </template>
        <template v-if="form.level == 5">
          <el-row class="mb-20">
            <el-col :span="24">
              <span class="ml-20">省：</span>
              <el-select
                v-model="provId"
                placeholder="请选择省"
                clearable
                @change="getFindCityList(provId)"
                @clear="clearProvId"
                :disabled="
                  $store.state.userPower == 3 || $store.state.userPower == 4
                "
              >
                <el-option
                  v-for="item in provList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>

              <span class="ml-20">市：</span>
              <el-select
                v-model="cityId"
                placeholder="请选择市"
                @change="getFindDistrictList(cityId)"
                @clear="clearCityId"
                clearable
                :disabled="$store.state.userPower == 4"
              >
                <el-option
                  v-for="item in cityList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>

              <span class="ml-20">区：</span>
              <el-select
                v-model="regionId"
                placeholder="请选择区"
                @change="getFindStreetList(regionId)"
                @clear="clearRegionId"
                clearable
              >
                <el-option
                  v-for="item in regionList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </template>
        <template v-if="form.level == 6">
          <el-row class="mb-20">
            <el-col :span="24">
              <span class="ml-20">省：</span>
              <el-select
                v-model="provId"
                placeholder="请选择省"
                clearable
                @change="getFindCityList(provId)"
                @clear="clearProvId"
                :disabled="
                  $store.state.userPower == 3 ||
                  $store.state.userPower == 4 ||
                  $store.state.userPower == 5
                "
              >
                <el-option
                  v-for="item in provList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>

              <span class="ml-20">市：</span>
              <el-select
                v-model="cityId"
                placeholder="请选择市"
                @change="getFindDistrictList(cityId)"
                @clear="clearCityId"
                clearable
                :disabled="
                  $store.state.userPower == 4 || $store.state.userPower == 5
                "
              >
                <el-option
                  v-for="item in cityList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>

              <span class="ml-20">区：</span>
              <el-select
                v-model="regionId"
                placeholder="请选择区"
                @change="getFindStreetList(regionId)"
                @clear="clearRegionId"
                clearable
                :disabled="$store.state.userPower == 5"
              >
                <el-option
                  v-for="item in regionList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>

              <span class="ml-20">街道：</span>
              <el-select v-model="streetId" placeholder="请选择街道" clearable>
                <el-option
                  v-for="item in streetList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </template>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit('form')">完成</el-button>
        <el-button @click="onCancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  findCityList,
  findDistrictList,
  findProviceList,
  findStreetList,
} from "@/api/userLogin";
import {
  updateGovDepartMent,
  selectGovDepartMent,
} from "@/api/governmentManagement";
export default {
  data() {
    return {
      provList: [], //省级
      cityList: [], //市级列表
      regionList: [], // 区级
      streetList: [], // 街道
      provId: "",
      cityId: "",
      regionId: "",
      streetId: "",
      form: {
        name: "",
        level: "2",
      },
      rules: {
        name: [
          { required: true, message: "请输入政府部门名称", trigger: "blur" },
        ],
        level: [{ required: true }],
      },
    };
  },
  created() {
    this.selectGovDepartMent();
    this.getFindProviceList();
  },
  methods: {
    async selectGovDepartMent() {
      let res = await selectGovDepartMent({
        id: this.$route.query.id,
      });
      let { data, msg } = res.data;
      console.log(data, msg);
      if (msg == "success") {
        this.form.name = data.name;
        this.form.level = data.power;
        this.provId = data.provinceId ? data.provinceId : "";
        this.cityId = data.cityId ? data.cityId : "";
        this.regionId = data.regionId ? data.regionId : "";
        this.streetId = data.streetId ? data.streetId : "";
        this.getFindCityList(this.provId);
        this.getFindDistrictList(this.cityId);
        this.getFindStreetList(this.regionId);
      }
    },
    onSubmit(formName) {
      switch (this.form.level) {
        case "2":
          this.provId = "";
          this.cityId = "";
          this.regionId = "";
          this.streetId = "";
          break;
        case "3":
          if (!this.provId) {
            this.$message.warning("请选择省份");
            return;
          }
          this.cityId = "";
          this.regionId = "";
          this.streetId = "";
          break;
        case "4":
          if (!this.cityId) {
            this.$message.warning("请选择市");
            return;
          }
          this.regionId = "";
          this.streetId = "";
          break;
        case "5":
          if (!this.regionId) {
            this.$message.warning("请选择区");
            return;
          }
          this.streetId = "";
          break;
        case "6":
          if (!this.streetId) {
            this.$message.warning("请选择街道");
            return;
          }
          break;
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = await updateGovDepartMent({
            id: this.$route.query.id,
            name: this.form.name,
            power: this.form.level,
            provinceId: this.provId,
            cityId: this.cityId,
            regionId: this.regionId,
            streetId: this.streetId,
          });
          if (res.data.result == "success") {
            this.$message.success("修改成功");
            this.$router.back();
          }
        } else {
          return false;
        }
      });
    },
    onCancel() {
      this.$router.back();
    },
    // 获取省
    async getFindProviceList() {
      let res = await findProviceList();
      if (res.data.result == "success") {
        this.provList = res.data.msg;
      }
    },
    // 获取市
    async getFindCityList(id) {
      let res = await findCityList({ id: id });
      if (res.data.result == "success") {
        this.cityList = res.data.msg;
      }
    },
    // 获取区
    async getFindDistrictList(id) {
      let res = await findDistrictList({ id: id });
      if (res.data.result == "success") {
        this.regionList = res.data.msg;
      }
    },
    // 获取街道
    async getFindStreetList(id) {
      let res = await findStreetList({ id: id });
      if (res.data.result == "success") {
        this.streetList = res.data.msg;
      }
    },
    // 清空省触发
    clearProvId() {
      this.cityId = "";
      this.regionId = "";
      this.streetId = "";
      this.cityList = [];
      this.regionList = [];
      this.streetList = [];
    },
    // 清空市触发
    clearCityId() {
      this.regionId = "";
      this.streetId = "";
      this.regionList = [];
      this.streetList = [];
    },
    // 清空区触发
    clearRegionId() {
      this.streetId = "";
      this.streetList = [];
    },
  },
};
</script>

<style></style>
