import axios from "../plugins/axios";
// 发票审核
// 获取发票审核列表
export const examineInvoiceList = (param) =>
  axios.get(
    "/course/invoice/examineInvoiceList?pageSize=" +
      param.pageSize +
      "&startNum=" +
      param.startNum +
      "&name=" +
      param.name +
      "&status=" +
      param.status +
      "&applyStartTime=" +
      param.applyStartTime +
      "&applyEndTime=" +
      param.applyEndTime +
      "&examineStartTime=" +
      param.examineStartTime +
      "&examineEndTime=" +
      param.examineEndTime
  );

// 后台发票审核详情
export const examineInvoiceDetail = (param) =>
  axios.get("/course/invoice/examineInvoiceDetail?id=" + param.id);

// 后台发票审核通过和驳回
export const examineInvoice = (param) =>
  axios.post("/course/invoice/examineInvoice", param);

// 后台发票审核批量导出
export const examineExportInvoiceList = (param) => {
  return axios({
    url:
      "/course/invoice/examineExportInvoiceList?name=" +
      param.name +
      "&status=" +
      param.status +
      "&applyStartTime=" +
      param.applyStartTime +
      "&applyEndTime=" +
      param.applyEndTime +
      "&examineStartTime=" +
      param.examineStartTime +
      "&examineEndTime=" +
      param.examineEndTime +
      "&ids=" +
      param.ids,
    method: "get",
    responseType: "blob",
  });
};
// 后台发票审核全部导出
export const examineExportInvoiceListAll = (param) => {
  return axios({
    url:
      "/course/invoice/examineExportInvoiceList?name=" +
      param.name +
      "&status=" +
      param.status +
      "&applyStartTime=" +
      param.applyStartTime +
      "&applyEndTime=" +
      param.applyEndTime +
      "&examineStartTime=" +
      param.examineStartTime +
      "&examineEndTime=" +
      param.examineEndTime,

    method: "get",
    responseType: "blob",
  });
};
