<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span> 文档分组管理</span>
      </div>
      <div class="text item" style="font-size: 14px">
        对文档的分组进行管理。
      </div>
    </el-card>
    <el-row style="margin: 20px">
      <el-col :span="10">
        <span>搜索内容：</span
        ><el-input
          style="width: 500px"
          v-model="search"
          placeholder="请输入分组名称"
          clearable
        ></el-input
      ></el-col>
      <el-col :span="4"
        ><el-button type="primary" @click="searchDocumentGroup"
          >查询</el-button
        ></el-col
      >
    </el-row>
    <el-row style="margin: 20px">
      <el-button type="primary" @click="createDocumentGroup"
        >新建文档分组</el-button
      >
    </el-row>
    <el-table :data="tableData" border>
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="name" label="分组名称"></el-table-column>
      <el-table-column prop="number" label="已有文档"></el-table-column>
      <el-table-column label="排序">
        <template slot-scope="scope">
          <div class="icon">
            <i
              style="color: red; cursor: pointer"
              class="el-icon-top"
              @click="moveEducationHelpDocumentGroup('up', scope.$index)"
            ></i>
            <i
              style="color: green; cursor: pointer"
              class="el-icon-bottom"
              @click="moveEducationHelpDocumentGroup('down', scope.$index)"
            ></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click="editDocumentGroup(scope.row)"
            >修改</el-button
          >
          <el-button type="danger" @click="deleteDocumentGroup(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-dialog
      width="33%"
      title="新建文档分组"
      center
      :visible.sync="newDialogFormVisible"
    >
      <el-form
        ref="formRules"
        label-width="150px"
        :model="form"
        :rules="formRules"
      >
        <el-form-item label="文档分组名称" prop="name">
          <el-input
            v-model="form.name"
            autocomplete="off"
            maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="newDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('formRules')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      width="33%"
      title="修改文档分组"
      center
      :visible.sync="editDialogFormVisible"
    >
      <el-form
        ref="editFormRules"
        label-width="150px"
        :model="editForm"
        :rules="formRules"
      >
        <el-form-item label="文档分组名称" prop="name">
          <el-input
            v-model="editForm.name"
            autocomplete="off"
            maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('editFormRules')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getEducationHelpDocumentGroupList,
  saveEducationHelpDocumentGroup,
  updateEducationHelpDocumentGroup,
  deleteEducationHelpDocumentGroup,
  moveEducationHelpDocumentGroup,
} from "@/api/helpDocumentManagement";
export default {
  data() {
    return {
      search: "",
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      newDialogFormVisible: false,
      editDialogFormVisible: false,
      form: {
        name: "",
      },
      editForm: {
        id: "",
        name: "",
      },
      formRules: {
        name: [
          { required: true, message: "请输入文档分组名称", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getEducationHelpDocumentGroupList();
  },
  methods: {
    async getEducationHelpDocumentGroupList() {
      let res = await getEducationHelpDocumentGroupList({
        name: this.search,
        startNum: this.currentPage,
        pageSize: this.pageSize,
      });
      if (res.data.result == "success") {
        this.tableData = res.data.educationHelpDocumentGroupList;
        this.total = res.data.size;
      }
    },
    submitForm(formName) {
      // 新增文档分组
      if (formName == "formRules") {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            let res = await saveEducationHelpDocumentGroup({
              name: this.form.name,
            });
            if (res.data.msg == "success") {
              this.$message.success("新增成功");
              this.newDialogFormVisible = false;
              this.getEducationHelpDocumentGroupList();
            } else {
              this.$message.error("新增失败");
            }
          } else {
            return false;
          }
        });
      }
      // 修改文档分组
      if (formName == "editFormRules") {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            let res = await updateEducationHelpDocumentGroup({
              id: this.editForm.id,
              name: this.editForm.name,
            });
            if (res.data.msg == "success") {
              this.$message.success("修改成功");
              this.editDialogFormVisible = false;
              this.getEducationHelpDocumentGroupList();
            } else {
              this.$message.error("修改失败");
            }
          } else {
            return false;
          }
        });
      }
    },
    // 排序上移下移
    async moveEducationHelpDocumentGroup(type, index) {
      let id2 = "";
      if (type == "up" && this.tableData[index - 1]) {
        id2 = this.tableData[index - 1].id;
      } else if (type == "down" && this.tableData[index + 1]) {
        id2 = this.tableData[index + 1].id;
      } else {
        this.$message({
          message: type == "up" ? "已经是第一行了" : "已经是最后一行了",
          type: "warning",
        });
        return;
      }
      let res = await moveEducationHelpDocumentGroup({
        id1: this.tableData[index].id,
        id2: id2,
      });
      if (res.data.result == "success") {
        this.$message({
          message: "修改成功",
          type: "success",
        });
        this.getEducationHelpDocumentGroupList();
      }
    },
    searchDocumentGroup() {
      this.getEducationHelpDocumentGroupList();
    },
    createDocumentGroup() {
      this.newDialogFormVisible = true;
    },
    editDocumentGroup(row) {
      console.log(row);
      this.editForm = {
        id: row.id,
        name: row.name,
      };
      this.editDialogFormVisible = true;
    },
    deleteDocumentGroup(row) {
      console.log(row);
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await deleteEducationHelpDocumentGroup({
            id: row.id,
          });
          if (res.data.result == "success") {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getEducationHelpDocumentGroupList();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.searchDocumentGroup();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.searchDocumentGroup();
    },
  },
};
</script>
<style scoped>
.icon {
  font-size: 32px;
  display: flex;
  justify-content: space-around;
}
</style>
