<template>
  <section class="app-main clearBoth">
    <router-view></router-view>
  </section>
</template>

<script>
export default {};
</script>

<style>
.clearBoth {
  clear: both;
}
</style>
