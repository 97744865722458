<template>
  <el-dialog
    title="设置必修课时"
    :visible.sync="normalizedSize"
    :before-close="handleClose"
    width="33%"
  >
    <h3>
      课程名称：{{ currentSetHours.lesson ? currentSetHours.lesson.name : "" }}
    </h3>
    <el-scrollbar>
      <template v-if="hoursList.length != 0">
        <div v-for="item in hoursList" :key="item.id">
          <div class="chapter">{{ item.name }}</div>
          <el-checkbox-group v-model="checkList">
            <div
              class="hourItem"
              v-for="hour in item.lessonHourList"
              :key="hour.hourId"
            >
              <el-checkbox :label="hour.hourId">{{
                hour.hourName
              }}</el-checkbox>
            </div>
          </el-checkbox-group>
        </div>
      </template>
    </el-scrollbar>
    <div class="footer-btn">
      <el-button type="primary" @click="accomplish">完成</el-button>
      <el-button type="" @click="colse">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getLessonHourListByLessonId } from "@/api/courseManage";
export default {
  props: {
    showDialogHoursSetting: {
      type: Boolean,
      default: false,
    },
    currentSetHours: {
      type: Object,
    },
    currentSetCourseIndex: {
      type: Number,
    },
    selectedHourList: {
      type: Array,
    },
  },
  data() {
    return {
      hoursList: [],
      checkList: [],
    };
  },
  computed: {
    normalizedSize: function () {
      return this.showDialogHoursSetting;
    },
  },
  watch: {
    currentSetHours(val) {
      console.log(val, "currentSetHours");
      this.getLessonHourListByLessonId(val.lesson.id);
    },
    selectedHourList(val) {
      if (val) {
        this.checkList = val;
      }
    },
  },
  created() {
    // this.getLessonHourListByLessonId("110751");
  },
  methods: {
    // 获取课时
    async getLessonHourListByLessonId(lessonId) {
      let res = await getLessonHourListByLessonId({
        lessonId: lessonId,
      });
      if (res.data.result == "success" && res.data.msg.chapterList) {
        this.hoursList = res.data.msg.chapterList;
        console.log(this.hoursList, "getLessonHourListByLessonId");
      }
    },
    accomplish() {
      let selectedCourseHours = {
        checkList: this.checkList,
        index: this.currentSetCourseIndex,
        currentSetHours: this.currentSetHours,
      };
      this.$emit("selectedCourseHours", selectedCourseHours);
      this.colse();
      console.log(selectedCourseHours, "checkList");
    },
    colse() {
      this.checkList = [];
      this.$emit("colseDialog", false);
    },
    handleClose() {
      this.$confirm("确认关闭？")
        .then(() => {
          this.colse();
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.el-scrollbar {
  height: 500px;
  width: 330px;
  border: 1px solid #eee;
  border-radius: 10px;
  margin: auto;
}
.el-scrollbar .chapter {
  padding: 10px 20px;
  background-color: #f2f2f2;
}
.el-scrollbar .hourItem {
  padding: 10px 20px;
  border-bottom: 1px solid #eee;
}
.footer-btn {
  margin-top: 30px;
  text-align: center;
}
</style>
