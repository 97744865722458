import axios from "../plugins/axios";

// 普通用户管理列表
export const getUserList = (param) =>
  axios.get(
    "/api/user/getUserList?startNum=" +
      param.startNum +
      "&pageSize=" +
      param.pageSize +
      "&name=" +
      param.name +
      "&status=" +
      param.status
  );
// 导出数据
export const examineExportListAll = (param) => {
  return axios({
    method: "get",
    responseType: "blob",
    url:
      "/api/user/exportUserList?encryptPhone=" +
      param.encryptPhone +
      "&encryptIdCard=" +
      param.encryptIdCard +
      "&name=" +
      param.name +
      "&status=" +
      param.status +
      "&ids=" +
      param.ids,
  });
};

// 普通用户与管理员用户禁用与启用
export const updateUserStatus = (param) =>
  axios.post("/api/user/updateUserStatus", param);
//   axios.get(
//     "/api/user/updateUserStatus?id=" + param.id + "&recovery=" + param.recovery
//   );

// 删除普通用户与管理员用户
export const deleteUser = (param) =>
  axios.get("/api/user/deleteUser?ids=" + param.ids);
// export const deleteUser = (param) => axios.post("/api/user/deleteUser", param);

// 普通用户设为管理员与管理员解绑为普通用户
export const setAdminOrBindAdminUser = (param) =>
  axios.get(
    "/api/user/setAdminOrBindAdminUser?id=" +
      param.id +
      "&isAdmin=" +
      param.isAdmin
  );

// 管理员用户管理列表
export const getAdminUserList = (param) =>
  axios.get(
    "/api/user/getAdminUserList?startNum=" +
      param.startNum +
      "&pageSize=" +
      param.pageSize +
      "&name=" +
      param.name +
      "&status=" +
      param.status
  );
export const templateLink = () => {
  return axios({
    method: "get",
    responseType: "blob",
    url: "/api/user/downloadImportUserExcelTemplate",
  });
};

/**
 * 添加管理员
 * @param {*} param
 * 参数 account-账号
 * 密码：password
 * 确认密码：confirmPassword
 * 管理员姓名：name
 * 联系电话：phone
 * 1是管理员-isAdmin
 * 级别-power 1-平台管理员 2国家级 3省级 4市级 5区级 6街道
 * 是否是主管理员- isPrimaryAdmin 0 否 1 是
 * 当为省级、市级、区级以及街道级管理员时，需要传province、city、region、street对应级别的id
 * @returns
 */
export const saveAdmin = (param) => axios.post("/api/user/saveAdmin", param);

/**
 * 编辑管理员
 * @param {*} param
 * 管理员id: id
 * 账号: account
 * 密码：password
 * 确认密码：confirmPassword
 * 管理员姓名：name
 * 联系电话：phone
 * 1是管理员-isAdmin
 * 级别-power 1-平台管理员 2国家级 3省级 4市级 5区级 6街道
 * 是否是主管理员- isPrimaryAdmin 0 否 1 是
 * 当为省级、市级、区级以及街道级管理员时，需要传province、city、region、street对应级别的id
 * @returns
 */
export const updateAdmin = (param) =>
  axios.post("/api/user/updateAdmin", param);

// 管理员列表 power 1-平台管理员 2国家级 3省级 4市级 5区级 6街道
export const getAdminUserListNew = (param) =>
  axios.get(
    "/api/user/getAdminUserListNew?name=" +
      param.name +
      "&power=" +
      param.power +
      "&proviceId=" +
      param.proviceId +
      "&cityId=" +
      param.cityId +
      "&status=" +
      param.status +
      "&regionId=" +
      param.regionId +
      "&streetId=" +
      param.streetId +
      "&startNum=" +
      param.startNum +
      "&pageSize=" +
      param.pageSize
  );

// 管理员启动和禁用
// export const updateUserStatus = (param) =>
//   axios.post("/api/user/updateUserStatus", param);

// 设置主管理员
export const updateIsAdmin = (param) =>
  axios.get("/api/user/updateIsAdmin?userId=" + param.userId);

// 删除管理员
export const unBindAdminUser = (param) =>
  axios.get(
    "/api/user/unBindAdminUser?userId=" +
      param.userId +
      "&isAdmin=" +
      param.isAdmin
  );
// 管理员详情
export const getAdminUserInfo = (param) =>
  axios.get("/api/user/getAdminUserInfo?id=" + param.id);

/**
 * 更改管理员角色
 * @param {id,power,province,city,region,street} param
 * id-管理员id
 * power-2国家级 3省级 4市级 5区级 6街道
 * province-省id
 * city-市id
 * region-区id
 * street-街道id
 * @returns
 */
export const updateAdminRole = (param) =>
  axios.post("/api/user/updateAdminRole", param);
// 普通用户导入导入
export const commonYonghua = (param) => {
  return axios({
    url: "/api/user/importUserByExcel",
    method: "post",
    data: param,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// 国家级管理员导入
export const importNationUserExcel = (param) => {
  return axios({
    url: "/api/user/importNationUserExcel",
    method: "post",
    data: param,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// 省市区街道管理员导入
export const importFiveLevelUserExcel = (param) => {
  return axios({
    url: "/api/user/importFiveLevelUserExcel",
    method: "post",
    data: param,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
