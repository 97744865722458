<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span> 单位认证</span>
      </div>
      <div class="text item" style="font-size: 14px">对认证单位进行管理。</div>
    </el-card>
    <el-row style="margin-top: 20px">
      <el-col :span="2" v-for="(item, index) in statusToggleList" :key="index">
        <el-button
          :type="currentStatus == item.status ? 'primary' : ''"
          @click="unitStatusCut(item.status)"
          >{{ item.name }}</el-button
        >
      </el-col>
    </el-row>
    <el-row style="margin: 20px 0">
      <el-col :span="24">
        <el-input
          v-model="searchText"
          placeholder="请输入搜索内容"
          style="width: 200px; margin-right: 30px"
        ></el-input>
        <el-button type="primary" @click="search">搜索</el-button>
        <!-- <el-button @click="reset">重置</el-button> -->
      </el-col>
    </el-row>
    <el-row style="margin: 20px 0">
      <el-button type="primary" @click="addUnit">添加单位</el-button>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-table :data="tableData" style="width: 100%" border>
          <el-table-column type="selection"></el-table-column>
          <el-table-column prop="id" label="ID" width="50"></el-table-column>
          <el-table-column prop="name" label="单位名称"></el-table-column>
          <el-table-column prop="detail" label="单位详情">
            <template slot-scope="scope">
              <el-button type="text" @click="showDetail(scope.row)"
                >查看详情</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="applyTime"
            label="申请时间"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="applyUserUniId"
            label="申请用户uni-id"
          ></el-table-column>
          <el-table-column
            prop="applyUserName"
            label="申请用户姓名"
          ></el-table-column>
          <el-table-column
            prop="applyUserPhone"
            label="申请用户联系电话"
          ></el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 0" style="color: #ff6600"
                >未审核</span
              >
              <span v-if="scope.row.status == 1" style="color: #008000"
                >审核通过
              </span>
              <span v-if="scope.row.status == 2" style="color: #ff0000"
                >审核未通过</span
              >
            </template>
          </el-table-column>
          <el-table-column label="操作" width="400">
            <template slot-scope="scope">
              <!-- <el-button type="info" @click="sendMessage(scope.row)"
                >发送消息</el-button
              > -->
              <el-button
                v-if="scope.row.status === 0"
                type="success"
                @click="pass(scope.row)"
                >审核通过</el-button
              >
              <el-button
                v-if="scope.row.status === 0"
                type="danger"
                @click="reject(scope.row)"
                >拒绝审核</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-pagination
          :current-page="currentPage"
          :page-size="pageSize"
          :total="total"
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next, jumper"
        ></el-pagination>
      </el-col>
    </el-row>
    <el-dialog
      :visible.sync="newUnitDialogVisible"
      title="添加单位"
      :close-on-click-modal="false"
    >
      <el-form :model="newUnitForm" :rules="rules" ref="newUnitForm">
        <el-form-item label="单位名称" prop="name">
          <el-input v-model="newUnitForm.name"></el-input>
        </el-form-item>
        <el-form-item label="单位介绍" prop="detail">
          <el-input
            type="textarea"
            v-model="newUnitForm.detail"
            :maxlength="120"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="newUnitDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitForm">添加</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="cheakDetailDialogVisible"
      title="查看详情"
      :close-on-click-modal="false"
    >
      <div>
        <p>单位名称：{{ cheakDetailItem.name }}</p>
        <p>资质证明：</p>
        <!-- :src="cheakDetailItem.certificate" -->
        <!-- v-if="showImagePreview" -->

        <el-image
          style="width: 100px; height: 100px"
          :src="url"
          :preview-src-list="srcList"
        >
        </el-image>
        <!-- <el-button type="text" @click="showImage(cheakDetailItem.certificate)"
          >查看详情</el-button
        > -->
        <p>单位简介：{{ cheakDetailItem.detail }}</p>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cheakDetailDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="pass(cheakDetailItem)"
          >审核通过</el-button
        >
        <el-button type="danger" @click="reject(cheakDetailItem)"
          >审核不通过</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchText: "",
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      srcList: [
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      ],
      showImagePreview: false,
      tableData: [
        {
          id: 1,
          name: "单位1",
          detail: "这是单位1的介绍",
          applyTime: "2021-01-01 12:00:00",
          applyUserUniId: "123456",
          applyUserName: "张三",
          applyUserPhone: "12345678901",
          status: 0,
        },
        {
          id: 2,
          name: "单位2",
          detail: "这是单位2的介绍",
          applyTime: "2021-01-02 12:00:00",
          applyUserUniId: "234567",
          applyUserName: "李四",
          applyUserPhone: "12345678902",
          status: 1,
        },
        {
          id: 3,
          name: "单位3",
          detail: "这是单位3的介绍",
          applyTime: "2021-01-03 12:00:00",
          applyUserUniId: "345678",
          applyUserName: "王五",
          applyUserPhone: "12345678903",
          status: 2,
        },
        {
          id: 4,
          name: "单位4",
          detail: "这是单位4的介绍",
          applyTime: "2021-01-04 12:00:00",
          applyUserUniId: "456789",
          applyUserName: "赵六",
          applyUserPhone: "12345678904",
          status: 0,
        },
        {
          id: 5,
          name: "单位5",
          detail: "这是单位5的介绍",
          applyTime: "2021-01-05 12:00:00",
          applyUserUniId: "567890",
          applyUserName: "钱七",
          applyUserPhone: "12345678905",
          status: 1,
        },
        {
          id: 6,
          name: "单位6",
          detail: "这是单位6的介绍",
          applyTime: "2021-01-06 12:00:00",
          applyUserUniId: "678901",
          applyUserName: "孙八",
          applyUserPhone: "12345678906",
          status: 2,
        },
        {
          id: 7,
          name: "单位7",
          detail: "这是单位7的介绍",
          applyTime: "2021-01-07 12:00:00",
          applyUserUniId: "789012",
          applyUserName: "周九",
          applyUserPhone: "12345678907",
          status: 0,
        },
        {
          id: 8,
          name: "单位8",
          detail: "这是单位8的介绍",
          applyTime: "2021-01-08 12:00:00",
          applyUserUniId: "890123",
          applyUserName: "吴十",
          applyUserPhone: "12345678908",
          status: 1,
        },
        {
          id: 9,
          name: "单位9",
          detail: "这是单位9的介绍",
          applyTime: "2021-01-09 12:00:00",
          applyUserUniId: "901234",
          applyUserName: "郑十一",
          applyUserPhone: "12345678909",
          status: 2,
        },
        {
          id: 10,
          name: "单位10",
          detail: "这是单位10的介绍",
          applyTime: "2021-01-10 12:00:00",
          applyUserUniId: "012345",
          applyUserName: "冯十二",
          applyUserPhone: "12345678910",
          status: 0,
        },
      ],
      statusToggleList: [
        {
          name: "全部",
          status: -1,
        },
        {
          name: "未审核",
          status: 0,
        },
        {
          name: "审核通过",
          status: 1,
        },
        {
          name: "审核不通过",
          status: 2,
        },
      ],
      currentStatus: -1, //当前选中的审核状态
      currentPage: 1,
      pageSize: 10,
      total: 0,
      newUnitDialogVisible: false, // 添加单位弹出框
      cheakDetailDialogVisible: false, // 查看详细弹出框
      newUnitForm: {
        name: "",
        detail: "",
      }, // 存放添加单位填写的数据
      cheakDetailItem: "", // 当前查看的单位认证申请
      rules: {
        name: [{ required: true, message: "请输入单位名称", trigger: "blur" }],
        detail: [
          { required: true, message: "请输入单位介绍", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 审核状态切换
    unitStatusCut(status) {
      this.currentStatus = status;
    },
    showImage() {
      this.showImagePreview = true;
    },
    search() {
      // 搜索功能
    },
    reset() {
      // 重置功能
    },
    addUnit() {
      // 添加单位功能
      this.newUnitDialogVisible = true;
    },
    showDetail(row) {
      // 查看详情功能
      console.log("showDetail", row);
      this.cheakDetailDialogVisible = true;
      this.cheakDetailItem = row;
    },
    statusText(status) {
      // 状态文本
      console.log("statusText", status);
    },
    statusTagType(status) {
      // 状态标签类型
      console.log("statusTagType", status);
    },
    pass(row) {
      // 审核通过功能
      console.log("pass", row);
    },
    reject(row) {
      // 拒绝审核功能
      console.log("reject", row);
    },
    sendMessage(row) {
      // 发送消息功能
      console.log("sendMessage", row);
    },
    handleCurrentChange(val) {
      // 分页器功能
      console.log("handleCurrentChange", val);
    },
    // 确认添加单位
    submitForm() {
      // 提交表单功能
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 提交表单
          this.dialogVisible = false;
        }
      });
    },
  },
};
</script>
