import axios from "../plugins/axios";
// 单位===============
//单位管理列表
export const getEducationCompanyList = (param) =>
  axios.get(
    "/api/company/getEducationCompanyList?startNum=" +
      param.startNum +
      "&pageSize=" +
      param.pageSize +
      "&name=" +
      param.name +
      "&status=" +
      param.status +
      "&isPass=" +
      param.isPass +
      "&provinceId=" +
      param.provinceId +
      "&cityId=" +
      param.cityId +
      "&regionId=" +
      param.regionId +
      "&streetId=" +
      param.streetId
  );

//单位管理保存
export const saveCompany = (param) => axios.post("/api/company/save", param);

//单位管理修改
export const updateCompany = (param) =>
  axios.post("/api/company/update", param);

// 单位导入
export const importExcel = (param) => {
  return axios({
    method: "post",
    url: "/api/company/importExcel",
    data: param,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    // responseType: "blob",
  });
};

//单位详情
export const getEducationCompanyById = (param) =>
  axios.get("/api/company/getEducationCompanyById?companyId=" + param.id);

//单位管理状态修改
export const updateCompanyStatus = (param) =>
  axios.post("/api/company/updateCompanyStatus", param);

/**
 * 单位管理审核 通过/驳回
 * @param {companyId,userId,examineStatus} param
 * "companyId": "",//单位id
 * "userId": "123456",//当前用户id
 * "examineStatus": ""//状态 1-审核通过 2-审核不通过
 * @returns
 */
export const examineInvoice = (param) =>
  axios.post("/api/company/examineInvoice", param);

//单位管理删除
export const deleteCompany = (param) =>
  axios.get("/api/company/deleteCompany?ids=" + param.ids);
// axios.post("/api/company/deleteCompany", { educationCloudCompany: param });

// 成员===============
//单位成员列表
export const getCompanyUserList = (param) =>
  axios.get(
    "/api/company/getCompanyUserList?companyId=" +
      param.companyId +
      "&startNum=" +
      param.startNum +
      "&pageSize=" +
      param.pageSize +
      "&name=" +
      param.name +
      "&status=" +
      param.status +
      "&sex=" +
      param.sex
  );

// 单位成员导入
export const importCompanyUserExcel = (param) => {
  return axios({
    method: "post",
    url: "/api/company/importCompanyUserExcel",
    data: param,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    // responseType: "blob",
  });
};

//成员管理状态修改
export const updateCompanyUserStatus = (param) =>
  axios.post("/api/company/updateCompanyUserStatus", param);

//成员管理删除
export const deleteCompanyUser = (param) =>
  axios.get("/api/company/deleteCompanyUser?ids=" + param.ids);

//成员管理设为管理员
export const updateCompanyUserAdmin = (param) =>
  axios.get("/api/company/updateCompanyUserAdmin?ids=" + param.ids);

// 职位===============
//单位职位列表
export const getCompanyPositionList = (param) =>
  axios.get(
    "/api/company/getpostionlistbyid?id=" +
      param.companyId +
      "&startNum=" +
      param.startNum +
      "&pageSize=" +
      param.pageSize +
      "&name=" +
      param.name +
      "&state=" +
      param.status
  );

//职位管理状态修改
export const updateCompanyPositionStatus = (param) =>
  // axios.post("/api/company/updateoptsionpostionlistbyid", param);
  axios.get(
    "/api/company/updateoptsionpostionlistbyid?id=" +
      param.id +
      "&state=" +
      param.state
  );

//职位管理删除
export const deleteCompanyPosition = (param) =>
  axios.get("/api/company/deleteoptsionpostionlistbyid?id=" + param.id);
// axios.post("/api/company/deleteoptsionpostionlistbyid", param);

//职位管理新增
export const saveCompanyPosition = (param) =>
  // axios.post("/api/company/addoptsionpostionlistbyid", param);
  axios.get(
    "/api/company/addoptsionpostionlistbyid?educationCloudCompanyId=" +
      param.educationCloudCompanyId +
      "&companyPosition=" +
      param.companyPosition +
      "&name=" +
      param.name
  );
