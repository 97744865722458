import axios from "../plugins/axios";

// 单位支付套餐管理列表
export const listLessonPackageBack = (params) =>
  axios.get(
    "/course/lessonPackage/listLessonPackageBack?lessonId=" +
      params.lessonId +
      "&hourId=" +
      params.hourId +
      "&startNum=" +
      params.startNum +
      "&pageSize=" +
      params.pageSize
  );

// 单位支付套餐新建
/**
 *
 * @param {totalFee,lessonId,hourId,redemptionCodeNumber} params
 * "redemptionCodeNumber"://兑换码数量
 * "totalFee"://套餐金额
 * "lessonId"://课程id
 * "hourId"://课时id
 * @returns
 */
export const saveLessonPackage = (params) =>
  axios.post("/course/lessonPackage/saveLessonPackage", params);

// 单位支付套餐修改
/**
 *
 * @param {id,totalFee,lessonId,hourId,redemptionCodeNumber} params
 * "id"://套餐id
 * "redemptionCodeNumber"://兑换码数量
 * "totalFee"://套餐金额
 * "lessonId"://课程id
 * "hourId"://课时id
 * @returns
 */
export const updateLessonPackage = (params) =>
  axios.post("/course/lessonPackage/updateLessonPackage", params);

// 单位支付套餐删除
export const deleteLessonPackage = (params) =>
  axios.get("/course/lessonPackage/deleteLessonPackage?ids=" + params.ids);
