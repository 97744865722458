<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>反馈管理</span>
      </div>
      <div class="text item" style="font-size: 14px">
        对用户在前台的反馈进行查看的模块。
      </div>
    </el-card>
    <el-row style="margin: 20px 0" :gutter="40">
      <el-col :span="6">
        <el-input
          v-model="searchText"
          placeholder="请输入关键字"
          clearable
        ></el-input>
      </el-col>
      <el-col :span="8">
        <span>创建时间：</span>
        <el-date-picker
          v-model="createTime"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="search">搜索</el-button>
        <!-- <el-button @click="reset">重置</el-button> -->
      </el-col>
    </el-row>
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column prop="id" label="反馈者"></el-table-column>
      <el-table-column prop="name" label="手机号"></el-table-column>
      <el-table-column prop="id" label="反馈时间"></el-table-column>
      <el-table-column prop="id" label="反馈详情"></el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getFeedbackList } from "@/api/feedback";
import { msToDate } from "@/plugins/msToDate";
export default {
  data() {
    return {
      createTime: null,
      searchText: "",
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  created() {
    this.getFeedbackList();
  },
  watch: {},
  methods: {
    // 获取培训机构管理列表
    async getFeedbackList() {
      let res = await getFeedbackList({
        name: this.searchText, //名称
        feedbackTime: this.createTime ? msToDate(this.createTime[0]) : "",
        feedbackEndTime: this.createTime ? msToDate(this.createTime[1]) : "",
        startNum: this.currentPage, //起始页码
        pageSize: this.pageSize, //每页条数
      });
      if (res.data.result == "success") {
        this.tableData = res.data.educationTrainingInstitutionsList;
        this.total = res.data.size;
      }
    },
    search() {
      // 发送搜索请求
      this.getFeedbackList();
    },
    // 重置
    reset() {
      this.searchText = "";
      // this.search();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.search();
    },
  },
};
</script>

<style scoped>
::v-deep .el-dialog__footer {
  text-align: center;
}
</style>
