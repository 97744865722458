<template>
  <div id="trainingSetupList">
    <el-row :gutter="20">
      <el-col :span="8">
        <span>搜索内容：</span>
        <el-input
          style="width: 400px"
          v-model="searchInput"
          placeholder="输入框搜索功能"
          clearable
        ></el-input>
      </el-col>
      <el-col :span="6" :offset="1">
        <span>状态：</span>
        <el-select v-model="dropdownValue" placeholder="">
          <el-option label="全部" value=""></el-option>
          <el-option label="公开" value="1"></el-option>
          <el-option label="隐藏" value="2"></el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <span>创建时间：</span>
        <el-date-picker
          v-model="createTime"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
        >
        </el-date-picker>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="18">
        <span>地区：</span>
        <span class="ml-20">省：</span>
        <el-select
          v-model="provId"
          placeholder="请选择省"
          @change="getFindCityList(provId)"
          clearable
          :disabled="
            $store.state.userPower == 3 ||
            $store.state.userPower == 4 ||
            $store.state.userPower == 5 ||
            $store.state.userPower == 6
          "
        >
          <el-option
            v-for="item in provList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <span class="ml-20">市：</span>
        <el-select
          v-model="cityId"
          placeholder="请选择市"
          @change="getFindDistrictList(cityId)"
          clearable
          :disabled="
            $store.state.userPower == 4 ||
            $store.state.userPower == 5 ||
            $store.state.userPower == 6
          "
        >
          <el-option
            v-for="item in cityList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <span class="ml-20">区：</span>
        <el-select
          v-model="regionId"
          placeholder="请选择区"
          @change="getFindStreetList(regionId)"
          clearable
          :disabled="$store.state.userPower == 5 || $store.state.userPower == 6"
        >
          <el-option
            v-for="item in regionList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <span class="ml-20">街道：</span>
        <el-select
          v-model="streetId"
          placeholder="请选择街道"
          clearable
          :disabled="$store.state.userPower == 6"
        >
          <el-option
            v-for="item in streetList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="search">查询</el-button>
        <!-- <el-button type="default" @click="reset">重置</el-button> -->
      </el-col>
    </el-row>

    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column type="selection" fixed></el-table-column>
      <el-table-column prop="id" label="uni-id" fixed></el-table-column>
      <el-table-column
        prop="name"
        label="培训名称"
        width="150"
        fixed
      ></el-table-column>
      <el-table-column prop="area" label="地区" width="150">
        <template slot-scope="scope">
          <span>{{ scope.row.areaCount }}</span>
          <el-button
            class="ml-20"
            type="text"
            @click="detail(scope, '参与培训地区', 'area')"
            >查看详情</el-button
          >
        </template>
      </el-table-column>
      <!-- <el-table-column prop="trainingUnit" label="参与培训单位" width="150">
        <template slot-scope="scope">
          <span>{{ scope.row.companyCount }}</span>
          <el-button
            class="ml-20"
            type="text"
            @click="detail(scope, '参与培训单位', 'trainingUnit')"
            >查看详情</el-button
          >
        </template>
      </el-table-column> -->
      <el-table-column prop="trainingPosition" label="参与培训岗位" width="150">
        <template slot-scope="scope">
          <span>{{ scope.row.positionCount }}</span>
          <el-button
            class="ml-20"
            type="text"
            @click="detail(scope, '参与培训岗位', 'trainingPosition')"
            >查看详情</el-button
          >
        </template></el-table-column
      >
      <el-table-column
        prop="authorName"
        label="创建人"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="phone"
        label="联系电话"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="inTime"
        label="创建时间"
        width="200"
      ></el-table-column>
      <el-table-column prop="trainingSummary" label="培训简介" width="240">
        <template slot-scope="scope">
          <span>{{
            scope.row.content.length > 10
              ? scope.row.content.substring(0, 10) + "..."
              : scope.row.content
          }}</span>
          <el-button
            class="ml-20"
            type="text"
            @click="detail(scope, '培训简介', 'trainingSummary')"
            >查看详情</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.state == 1 ? "公开" : "隐藏" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="300">
        <template slot-scope="scope">
          <el-button
            type="primary"
            @click="navigationCheckTrainingData(scope.row)"
            >查看培训数据</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalItems"
    ></el-pagination>
    <el-dialog :title="detailObj.title" :visible.sync="dialogTableVisible">
      <span v-if="detailObj.type != 'trainingSummary'">职业健康培训</span>
      <template v-if="detailObj.type == 'area'">
        <el-table :data="gridData" border style="margin-top: 20px">
          <el-table-column label="序号" type="index"></el-table-column>
          <el-table-column label="地区">
            <template slot-scope="scope">
              {{
                scope.row.provinceName +
                scope.row.cityName +
                scope.row.regionName +
                scope.row.streetName
              }}
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChangeArea"
          @current-change="handleCurrentChangeArea"
          :current-page="currentPageArea"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSizeArea"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalItemsArea"
        ></el-pagination>
      </template>
      <template v-if="detailObj.type == 'trainingUnit'">
        <el-table :data="gridData" border style="margin-top: 20px">
          <el-table-column label="序号" type="index"></el-table-column>
          <el-table-column prop="companyName" label="单位"></el-table-column>
          <!-- <el-table-column prop="areaName" label="所属地区"></el-table-column> -->
        </el-table>
      </template>
      <template v-if="detailObj.type == 'trainingPosition'">
        <el-table :data="gridData" border style="margin-top: 20px">
          <el-table-column label="序号" type="index"></el-table-column>
          <el-table-column prop="postionName" label="岗位"></el-table-column>
        </el-table>
      </template>
      <template v-if="detailObj.type == 'trainingSummary'">
        <div v-html="gridData"></div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  findCityList,
  findDistrictList,
  findProviceList,
  findStreetList,
} from "@/api/userLogin";
import {
  platformTrainingList,
  getAreaList,
  getCompanyList,
  getPositionList,
} from "@/api/trainingManage";
import { msToDate } from "@/plugins/msToDate";
export default {
  data() {
    return {
      gridData: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      createTime: "",
      provList: [], //省级
      cityList: [], //市级列表
      regionList: [], // 区级
      streetList: [], // 街道
      provId: "",
      cityId: "",
      regionId: "",
      streetId: "",
      searchInput: "",
      dropdownValue: "",
      selectedDate: "",
      selectedAddress: [],
      addressOptions: [],
      createDialogVisible: false,
      positionName: "",
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      totalItems: 0,
      currentPageArea: 1,
      pageSizeArea: 10,
      totalItemsArea: 0,
      dialogTableVisible: false,
      detailObj: "",
      currentAreaId: "",
    };
  },
  created() {
    this.getFindProviceList();
    switch (this.$store.state.userPower) {
      case 3:
        this.provId = this.$store.state.userInfo.province;
        break;
      case 4:
        this.provId = this.$store.state.userInfo.province;
        this.cityId = this.$store.state.userInfo.city;
        break;
      case 5:
        this.provId = this.$store.state.userInfo.province;
        this.cityId = this.$store.state.userInfo.city;
        this.regionId = this.$store.state.userInfo.region;
        break;
      case 6:
        this.provId = this.$store.state.userInfo.province;
        this.cityId = this.$store.state.userInfo.city;
        this.regionId = this.$store.state.userInfo.region;
        this.streetId = this.$store.state.userInfo.street;
        break;
    }
    this.$nextTick(() => {
      this.platformTrainingList();
    });
  },
  mounted() {
    this.getFindCityList(this.provId);
    this.getFindDistrictList(this.cityId);
    this.getFindStreetList(this.regionId);
  },
  methods: {
    // 获取列表数据
    async platformTrainingList() {
      let param = {
        name: this.searchInput,
        begintime: this.createTime[0] ? msToDate(this.createTime[0]) : "",
        endtime: this.createTime[1] ? msToDate(this.createTime[1]) : "",
        status: this.dropdownValue,
        provinceId: this.provId,
        cityId: this.cityId,
        regionId: this.regionId,
        streetId: this.streetId,
        pages: this.pageSize,
        startNum: this.currentPage,
        userId: this.$store.state.userInfo.id,
      };
      console.log(param, "param");
      let res = await platformTrainingList(param);
      if (res.data.result == "success") {
        this.tableData = res.data.msg;
        this.totalItems = res.data.size;
        console.log(res, "platformTrainingList");
      }
    },
    // 获取省
    async getFindProviceList() {
      let res = await findProviceList();
      if (res.data.result == "success") {
        this.provList = res.data.msg;
      }
    },
    // 获取市
    async getFindCityList(id) {
      let res = await findCityList({ id: id });
      if (res.data.result == "success") {
        this.cityList = res.data.msg;
      }
    },
    // 获取区
    async getFindDistrictList(id) {
      let res = await findDistrictList({ id: id });
      if (res.data.result == "success") {
        this.regionList = res.data.msg;
      }
    },
    // 获取街道
    async getFindStreetList(id) {
      let res = await findStreetList({ id: id });
      if (res.data.result == "success") {
        this.streetList = res.data.msg;
      }
    },
    search() {
      // Implement search functionality
      this.platformTrainingList();
    },
    reset() {
      // Implement reset functionality
    },
    showCreateDialog() {
      this.createDialogVisible = true;
    },
    cancelCreate() {
      this.createDialogVisible = false;
      this.positionName = "";
    },
    confirmCreate() {
      // Implement create functionality
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.platformTrainingList();
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.platformTrainingList();
    },
    async handleSizeChangeArea(size) {
      this.pageSizeArea = size;
      let res = await getAreaList({
        id: this.currentAreaId,
        startNum: this.currentPageArea,
        pageSize: this.pageSizeArea,
      });
      this.gridData = res.data.areaList;
      this.totalItemsArea = res.data.size;
    },
    async handleCurrentChangeArea(page) {
      this.currentPageArea = page;
      let res = await getAreaList({
        id: this.currentAreaId,
        startNum: this.currentPageArea,
        pageSize: this.pageSizeArea,
      });
      this.gridData = res.data.areaList;
      this.totalItemsArea = res.data.size;
    },
    fetchData() {
      // Implement data fetching based on current page and page size
    },
    navigationCheckTrainingData(row) {
      this.$router.push({ path: "/checkTrainingData", query: { id: row.id } });
    },
    closeDetailDialog() {
      this.detailDialogVisible = false;
    },
    // 查看详情
    /**
     *
     * @param Object data 详情数据
     * @param String title 标题
     * @param String type 详情类型
     */
    async detail(data, title, type) {
      this.dialogTableVisible = true;
      this.detailObj = {
        title: title,
        type: type,
        data: data,
      };
      console.log(this.detailObj, "detailObj", data.row.id);
      let res = "";
      // 地区
      if (type == "area") {
        this.currentAreaId = data.row.id;
        res = await getAreaList({
          id: this.currentAreaId,
          startNum: this.currentPageArea,
          pageSize: this.pageSizeArea,
        });
        this.gridData = res.data.areaList;
        this.totalItemsArea = res.data.size;
      }
      // 单位
      if (type == "trainingUnit") {
        res = await getCompanyList({ id: data.row.id });
        this.gridData = res.data.msg;
      }
      // 岗位
      if (type == "trainingPosition") {
        res = await getPositionList({ id: data.row.id });
        this.gridData = res.data.msg;
      }
      // 内容
      if (type == "trainingSummary") {
        this.gridData = data.row.content;
      }
    },
  },
};
</script>

<style scoped>
/* Add custom styles here */
#trainingSetupList {
  padding: 20px;
}
.el-row {
  margin-bottom: 20px;
}
.ml-20 {
  margin-left: 20px;
}
</style>
