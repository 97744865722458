<template>
  <div class="review-detail">
    <el-card class="course-order">
      <el-row
        type="flex"
        class="row-bg"
        v-for="(item, index) in invoiceDetailList"
        :key="index"
      >
        <el-col :span="10">对应课程：{{ item.name }}</el-col>
        <el-col :span="10">订单编号：{{ item.orderNo }}</el-col>
      </el-row>
      <div>开票金额：￥{{ totalMoney }}</div>
    </el-card>
    <el-card class="audit-detail">
      <div slot="header" class="clearfix">
        <span>审核详情</span>
      </div>
      <el-descriptions title="用户信息" :column="2">
        <el-descriptions-item label="发票类型">{{
          examineInvoiceListDetail[0].invoiceType
        }}</el-descriptions-item>
        <el-descriptions-item label="抬头类型">{{
          examineInvoiceListDetail[0].headerType == 0
            ? "企业单位"
            : "个人/非企业"
        }}</el-descriptions-item>
        <el-descriptions-item label="发票抬头">{{
          examineInvoiceListDetail[0].invoiceHeader
        }}</el-descriptions-item>
        <el-descriptions-item label="发票税号">
          {{ examineInvoiceListDetail[0].invoiceTaxNumber }}
        </el-descriptions-item>
        <el-descriptions-item label="联系地址">{{
          examineInvoiceListDetail[0].contractAddress
        }}</el-descriptions-item>
        <el-descriptions-item label="联系电话">{{
          examineInvoiceListDetail[0].contractPhone
        }}</el-descriptions-item>
        <el-descriptions-item label="开户银行">{{
          examineInvoiceListDetail[0].depositBank
        }}</el-descriptions-item>
        <el-descriptions-item label="银行账号">{{
          examineInvoiceListDetail[0].depositBackNumber
        }}</el-descriptions-item>
        <el-descriptions-item label="发票备注">{{
          examineInvoiceListDetail[0].invoiceRemark
        }}</el-descriptions-item>
        <el-descriptions-item label="发票项目">{{
          examineInvoiceListDetail[0].invoiceItems
        }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions title="接收方式" :column="2">
        <el-descriptions-item label="电子邮箱接收">{{
          examineInvoiceListDetail[0].invoiceEmail
        }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <div class="audit-btn">
        <el-button plain @click="goBack">返回</el-button>
        <el-button
          v-if="examineInvoiceListDetail[0].status == 0"
          type="primary"
          plain
          @click="auditPass"
          >通过</el-button
        >
        <el-button
          v-if="examineInvoiceListDetail[0].status == 0"
          type="danger"
          plain
          @click="auditReject"
          >驳回</el-button
        >
      </div>
    </el-card>
    <el-dialog
      title="是否确定通过审核"
      :close-on-click-modal="false"
      :visible.sync="dialogIsPass"
      width="30%"
      center
    >
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogIsPass = false">取 消</el-button>
        <el-button type="primary" @click="confirmAuditPass">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="驳回原因"
      :close-on-click-modal="false"
      :visible.sync="dialogIsReject"
      width="30%"
    >
      <div>
        <span>驳回原因：</span>
        <el-input
          type="textarea"
          :rows="10"
          placeholder="请输入内容"
          v-model="rejectContent"
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogIsReject = false">取 消</el-button>
        <el-button type="primary" @click="confirmAuditReject">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { examineInvoiceDetail, examineInvoice } from "@/api/invoiceReview";
export default {
  data() {
    return {
      examineInvoiceListDetail: [], //发票课程信息
      invoiceDetailList: [], // 发票抬头信息
      dialogIsPass: false, //审核对话框
      dialogIsReject: false, //驳回对话框
      rejectContent: "", // 驳回原因
    };
  },
  computed: {
    totalMoney() {
      let money = 0;
      this.invoiceDetailList.forEach((item) => (money += item.invoicingAmount));
      return money;
    },
  },
  created() {
    this.examineInvoiceDetail(this.$route.query.id);
  },
  methods: {
    // 获取发票详情数据
    async examineInvoiceDetail(id) {
      let res = await examineInvoiceDetail({ id: id });
      if (res.data.result === "success") {
        this.examineInvoiceListDetail = res.data.examineInvoiceListDetail;
        this.invoiceDetailList = res.data.invoiceDetailList;
      }
    },
    // 返回
    goBack() {
      this.$router.go(-1);
    },
    // 通过
    auditPass() {
      this.dialogIsPass = true;
    },
    async confirmAuditPass() {
      let res = await examineInvoice({
        invoiceId: this.$route.query.id,
        examineStatus: 1,
        userId: this.$store.state.userInfo.id,
      });
      console.log(res, "auditPassExamineInvoice");
      if (res.data.result === "success") {
        this.dialogIsPass = false;
        this.$router.go(-1);
        this.$message({
          type: "success",
          message: res.data.msg,
        });
      }
    },
    // 驳回
    auditReject() {
      this.dialogIsReject = true;
    },
    async confirmAuditReject() {
      let res = await examineInvoice({
        invoiceId: this.$route.query.id,
        examineStatus: 2,
        userId: this.$store.state.userInfo.id,
        rejectReason: this.rejectContent,
      });
      console.log(res, "auditRejectExamineInvoice");
      if (res.data.result === "success") {
        this.dialogIsReject = false;
        this.$router.go(-1);
        this.$message({
          type: "success",
          message: res.data.msg,
        });
      }
    },
  },
};
</script>

<style scoped>
.el-row,
.el-descriptions {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.audit-detail {
  margin-top: 20px;
}
</style>
