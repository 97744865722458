<template>
  <div id="invoiceReviewList">
    <el-row>
      <el-button
        :type="status == '' ? 'primary' : ''"
        round
        @click="cutAuditStatus('')"
        >全部</el-button
      >
      <el-button
        :type="status == '1' ? 'primary' : ''"
        round
        @click="cutAuditStatus('1')"
        >已通过</el-button
      >
      <el-button
        :type="status == '2' ? 'primary' : ''"
        round
        @click="cutAuditStatus('2')"
        >未通过</el-button
      >
      <el-button
        :type="status == '0' ? 'primary' : ''"
        round
        @click="cutAuditStatus('0')"
        >未审核</el-button
      >
      <!-- <el-button
        :type="status == '3' ? 'primary' : ''"
        round
        @click="cutAuditStatus('3')"
        >已失效</el-button
      > -->
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <span>搜索内容：</span>
        <el-input
          style="width: 400px"
          v-model="searchInput"
          placeholder="输入框搜索功能"
          clearable
        ></el-input>
      </el-col>
      <el-col :span="8">
        <span>申请时间：</span>
        <el-date-picker
          v-model="applyTime"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="8">
        <span>审核时间：</span>
        <el-date-picker
          v-model="auditTime"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
        >
        </el-date-picker>
      </el-col>
    </el-row>
    <el-row>
      <!-- <el-col :span="18">
        <span>地区：</span>
        <span class="ml-20">省：</span>
        <el-select
          v-model="provId"
          placeholder="请选择省"
          @change="getFindCityList(provId)"
        >
          <el-option
            v-for="item in provList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <span class="ml-20">市：</span>
        <el-select
          v-model="cityId"
          placeholder="请选择市"
          @change="getFindDistrictList(cityId)"
        >
          <el-option
            v-for="item in cityList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <span class="ml-20">区：</span>
        <el-select
          v-model="regionId"
          placeholder="请选择区"
          @change="getFindStreetList(regionId)"
        >
          <el-option
            v-for="item in regionList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <span class="ml-20">街道：</span>
        <el-select v-model="streetId" placeholder="请选择街道">
          <el-option
            v-for="item in streetList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col> -->
    </el-row>
    <el-row>
      <el-col :span="2">
        <el-button type="primary" @click="search">查询</el-button>
      </el-col>
      <!-- <el-col :span="2" v-if="status == ''">
        <el-button type="primary" @click="ManualCheck = true"
          >手动校验</el-button
        >
      </el-col> -->
      <el-col :span="2" v-if="status == '1'">
        <el-button type="primary" @click="examineExportInvoiceList"
          >批量导出</el-button
        >
      </el-col>
      <el-col :span="2" v-if="status == '1'">
        <el-button type="primary" @click="examineExportInvoiceListAll"
          >全部导出</el-button
        >
      </el-col>
      <!-- <el-col :span="2" v-if="status == '1'">
        <el-button type="primary">推送到发票系统</el-button>
      </el-col> -->
      <el-col :span="2" v-if="status == '0'">
        <el-button type="primary" @click="examineExportInvoiceList"
          >批量导出</el-button
        >
      </el-col>
      <el-col :span="2" v-if="status == '0'">
        <el-button type="primary" @click="examineExportInvoiceListAll"
          >全部导出</el-button
        >
      </el-col>
    </el-row>
    <el-table
      ref="multipleTable"
      :data="tableData"
      style="width: 100%"
      border
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" fixed></el-table-column>
      <el-table-column type="index" label="序号" fixed></el-table-column>
      <el-table-column
        prop="applyName"
        label="申请人"
        width="100"
        fixed
      ></el-table-column>
      <el-table-column prop="phone" label="电话" width="150"> </el-table-column>
      <el-table-column prop="status" label="状态" width="150">
        <template slot-scope="scope">
          <div v-if="scope.row.status == 0">未审核</div>
          <div v-if="scope.row.status == 1">已通过</div>
          <div v-if="scope.row.status == 2">未通过</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="invoiceType"
        label="发票类型"
        width="200"
      ></el-table-column>
      <el-table-column
        prop="outTradeNo"
        label="订单号"
        width="350"
      ></el-table-column>
      <el-table-column
        prop="applyTime"
        label="申请时间"
        width="200"
      ></el-table-column>
      <el-table-column
        v-if="status !== '3'"
        prop="invoicingAmount"
        label="金额"
        width="100"
      >
        <template slot-scope="scope">
          {{ scope.row.invoicingAmount }}元
        </template>
      </el-table-column>
      <el-table-column
        v-if="status !== '3'"
        prop="headerType"
        label="抬头类型"
        width="200"
      ></el-table-column>
      <el-table-column
        v-if="status !== '3'"
        prop="invoicHeader"
        label="发票抬头"
        width="200"
      ></el-table-column>
      <el-table-column
        v-if="status !== '3'"
        prop="invoiceTaxNumber"
        label="发票税号"
        width="200"
      ></el-table-column>
      <el-table-column
        v-if="status !== '3'"
        prop="depositBank"
        label="开户银行"
        width="200"
      ></el-table-column>
      <el-table-column
        v-if="status !== '3'"
        prop="depositBankNumber"
        label="银行账号"
        width="200"
      ></el-table-column>
      <el-table-column
        prop="examineName"
        label="审核人"
        width="150"
      ></el-table-column>
      <el-table-column
        v-if="status !== '3'"
        prop="examineTime"
        label="审核时间"
        width="200"
      ></el-table-column>
      <el-table-column
        v-if="status == '3'"
        prop="examineTime"
        label="失效时间"
        width="200"
      ></el-table-column>
      <el-table-column label="操作" fixed="right" width="400">
        <template slot-scope="scope">
          <el-button
            :disabled="scope.row.status != 0"
            type="primary"
            @click="showDialogIsPass(scope.row)"
            >通过</el-button
          >
          <el-button
            type="danger"
            :disabled="scope.row.status != 0"
            @click="showDialogIsReject(scope.row)"
            >驳回</el-button
          >
          <el-button type="primary" @click="navigationReviewDetail(scope.row)"
            >查看审核详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalItems"
    ></el-pagination>
    <el-dialog
      title="是否确定通过审核"
      :close-on-click-modal="false"
      :visible.sync="dialogIsPass"
      width="30%"
      center
    >
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogIsPass = false">取 消</el-button>
        <el-button type="primary" @click="confirmAuditPass">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="驳回原因"
      :close-on-click-modal="false"
      :visible.sync="dialogIsReject"
      width="30%"
    >
      <div>
        <span>驳回原因：</span>
        <el-input
          type="textarea"
          :rows="10"
          placeholder="请输入内容"
          v-model="rejectContent"
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogIsReject = false">取 消</el-button>
        <el-button type="primary" @click="confirmAuditReject">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="手动校验" :visible.sync="ManualCheck" center>
      <el-table
        :data="gridData"
        border
        :header-cell-style="{ background: '#999999', color: '#4a4a4a' }"
      >
        <el-table-column
          property="name"
          label="申请人"
          width="150"
        ></el-table-column>
        <el-table-column
          property="phone"
          label="电话"
          width="200"
        ></el-table-column>
        <el-table-column property="date" label="申请时间"></el-table-column>
        <el-table-column property="money" label="金额(元)"></el-table-column>
        <el-table-column property="address" label="订单号"></el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange1"
        @current-change="handleCurrentChange1"
        :current-page="currentPage1"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalItems1"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button
          style="margin-right: 80px"
          type="primary"
          @click="ManualCheck = false"
          >批量设为失效</el-button
        >
        <el-button @click="ManualCheck = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  findCityList,
  findDistrictList,
  findProviceList,
  findStreetList,
} from "@/api/userLogin";
import {
  examineInvoiceList,
  examineExportInvoiceList,
  examineExportInvoiceListAll,
  examineInvoice,
} from "@/api/invoiceReview";
import { msToDate } from "@/plugins/msToDate";
export default {
  data() {
    return {
      ManualCheck: false, //打开关闭弹窗
      gridData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          phone: "12345678910",
          money: "100",
          address: "J12345678914112",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          money: "100",
          phone: "12345678910",
          address: "J12345678914112",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          money: "100",
          phone: "12345678910",
          address: "J12345678914112",
        },
        {
          date: "2016-05-03",
          money: "100",
          phone: "12345678910",
          name: "王小虎",
          address: "J12345678914112",
        },
      ],
      dialogIsPass: false, //审核对话框
      dialogIsReject: false, //驳回对话框
      rejectContent: "", //驳回原因
      invoiceId: "", // 当前选择的发票id
      multipleSelection: [], // 选中已通过的发票信息数组
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      status: "",
      applyTime: null, // 申请时间
      auditTime: null, // 审核时间
      provList: [], //省级
      cityList: [], //市级列表
      regionList: [], // 区级
      streetList: [], // 街道
      provId: "",
      cityId: "",
      regionId: "",
      streetId: "",
      searchInput: "",
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      totalItems: 0,
      currentPage1: 1,
      pageSize1: 10,
      totalItems1: 0,
    };
  },
  created() {
    this.getFindProviceList();
    this.examineInvoiceList();
  },
  methods: {
    // 审核状态切换
    cutAuditStatus(status) {
      this.status = status;
      this.examineInvoiceList();
    },
    // 审核批量导出
    async examineExportInvoiceList() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          type: "warning",
          message: "请勾选需要导出的数据",
        });
        return;
      }
      let param = {
        ids: this.multipleSelection.join(","),
        name: this.searchInput,
        status: this.status,
        applyStartTime: this.applyTime ? msToDate(this.applyTime[0]) : "",
        applyEndTime: this.applyTime ? msToDate(this.applyTime[1]) : "",
        examineStartTime: this.auditTime ? msToDate(this.auditTime[0]) : "",
        examineEndTime: this.auditTime ? msToDate(this.auditTime[1]) : "",
      };
      console.log(param, "param");
      let res = await examineExportInvoiceList(param);
      console.log(res, res.data, "555555555555555");

      let link = document.createElement("a");
      let body = document.querySelector("body");

      // let blob = new Blob([res.data], {type: "application/vnd.ms-excel"});  // res就是接口返回的文件流了
      let blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }); // res就是接口返回的文件流了
      link.href = window.URL.createObjectURL(blob);
      link.download = `待开发票名单`;
      // fix Firefox
      link.style.display = "none";
      body.appendChild(link);

      link.click();
      body.removeChild(link);

      window.URL.revokeObjectURL(link.href);
    },
    // 已通过全部导出
    async examineExportInvoiceListAll() {
      let param = {
        name: this.searchInput,
        status: this.status,
        applyStartTime: this.applyTime ? msToDate(this.applyTime[0]) : "",
        applyEndTime: this.applyTime ? msToDate(this.applyTime[1]) : "",
        examineStartTime: this.auditTime ? msToDate(this.auditTime[0]) : "",
        examineEndTime: this.auditTime ? msToDate(this.auditTime[1]) : "",
      };
      // console.log(param, "param");
      let res = await examineExportInvoiceListAll(param);
      console.log(res, "66");
      let link = document.createElement("a");
      let body = document.querySelector("body");
      let blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }); // res就是接口返回的文件流了
      console.log(blob, "55");
      link.href = window.URL.createObjectURL(blob);
      link.download = `待开发票名单`;
      // fix Firefox
      link.style.display = "none";
      body.appendChild(link);
      link.click();
      body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    },
    // 获取发票审核列表数据
    async examineInvoiceList() {
      let param = {
        name: this.searchInput,
        status: this.status,
        applyStartTime: this.applyTime ? msToDate(this.applyTime[0]) : "",
        applyEndTime: this.applyTime ? msToDate(this.applyTime[1]) : "",
        examineStartTime: this.auditTime ? msToDate(this.auditTime[0]) : "",
        examineEndTime: this.auditTime ? msToDate(this.auditTime[1]) : "",
        pageSize: this.pageSize,
        startNum: this.currentPage,
      };
      console.log(param, "param");
      let res = await examineInvoiceList(param);
      if (res.data.result == "success") {
        this.tableData = res.data.uninvoicedOrderList;
        this.totalItems = res.data.size;
        console.log(res, "platformTrainingList");
      }
    },
    // 获取省
    async getFindProviceList() {
      let res = await findProviceList();
      if (res.data.result == "success") {
        this.provList = res.data.msg;
      }
    },
    // 获取市
    async getFindCityList(id) {
      this.cityId = "";
      this.regionId = "";
      this.streetId = "";
      let res = await findCityList({ id: id });
      if (res.data.result == "success") {
        this.cityList = res.data.msg;
      }
    },
    // 获取区
    async getFindDistrictList(id) {
      this.regionId = "";
      this.streetId = "";
      let res = await findDistrictList({ id: id });
      if (res.data.result == "success") {
        this.regionList = res.data.msg;
      }
    },
    // 获取街道
    async getFindStreetList(id) {
      this.streetId = "";
      let res = await findStreetList({ id: id });
      if (res.data.result == "success") {
        this.streetList = res.data.msg;
      }
    },
    search() {
      // Implement search functionality
      this.examineInvoiceList();
    },
    reset() {
      // Implement reset functionality
    },
    // 审核是否通过
    showDialogIsPass(row) {
      this.dialogIsPass = true;
      this.invoiceId = row.id;
    },
    async confirmAuditPass() {
      let res = await examineInvoice({
        invoiceId: this.invoiceId,
        examineStatus: 1,
        userId: JSON.parse(sessionStorage.getItem("userInfo")).id,
      });
      if (res.data.result === "success") {
        this.dialogIsPass = false;
        this.$message({
          type: "success",
          message: res.data.msg,
        });
        this.examineInvoiceList();
      }
    },
    // 驳回及原因
    showDialogIsReject(row) {
      this.dialogIsReject = true;
      this.invoiceId = row.id;
    },
    async confirmAuditReject() {
      let res = await examineInvoice({
        invoiceId: this.invoiceId,
        examineStatus: 2,
        userId: JSON.parse(sessionStorage.getItem("userInfo")).id,
        rejectReason: this.rejectContent,
      });
      if (res.data.result === "success") {
        this.dialogIsReject = false;
        this.$message({
          type: "success",
          message: res.data.msg,
        });
        this.examineInvoiceList();
      }
    },
    // 跳转审核详情
    navigationReviewDetail(row) {
      this.$router.push({ path: "/reviewDetail", query: { id: row.id } });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.examineInvoiceList();
    },
    handleSizeChange1(size) {
      this.pageSize1 = size;
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.examineInvoiceList();
    },
    handleCurrentChange1(page) {
      this.currentPage1 = page;
    },
    handleSelectionChange(val) {
      console.log(val, "handleSelectionChange");
      val.forEach((item) => this.multipleSelection.push(item.id));
    },
  },
  mounted() {
    // Fetch address options for cascader
    // Fetch initial data for table
  },
};
</script>

<style scoped>
/* Add custom styles here */
#invoiceReviewList {
  padding: 20px;
}
.el-row {
  margin-bottom: 20px;
}
.ml-20 {
  margin-left: 20px;
}
</style>
