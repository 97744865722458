<template>
  <div id="invoiceReviewList">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="3" style="line-height: 50px">
          <el-button type="primary" @click="back">返回上一页</el-button>
        </el-col>
        <el-col
          :span="10"
          style="
            height: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
          "
          v-if="lessonName"
        >
          <div>课程名称：{{ lessonName.name }}</div>
          <div>
            收费模式：{{ lessonName.chargeMode == "2" ? "一次性收费" : "免费" }}
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-row style="margin: 20px 0" :gutter="40">
      <el-col :span="6">
        <el-input
          v-model="crux"
          placeholder="请输入用户名/手机号"
          @keyup.enter.native="search"
          clearable
        />
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="search">搜索</el-button>
        <!-- <el-button type="text" @click="navigationRefundRecord"
          >退款记录</el-button
        > -->
        <!-- <el-button type="" @click="reset">重置</el-button> -->
      </el-col>
      <el-col :span="2" v-if="flag !== 1">
        <el-button type="primary" @click="DerivedData">导出数据</el-button>
      </el-col>
      <!-- </el-row>
    <el-row style="margin: 20px 0" :gutter="40"> -->
      <el-col :span="3" v-if="flag !== 1">
        <el-upload
          class="upload-demo"
          action="none"
          :auto-upload="false"
          :on-change="importNationUserExcel"
          multiple
          :limit="1"
          :file-list="fileList"
          :on-exceed="handleExceed"
          :show-file-list="false"
        >
          <el-button type="primary">批量导入学员</el-button>
        </el-upload>
      </el-col>
      <el-col :span="4" style="line-height: 40px" v-if="flag !== 1">
        <a
          href="https://jwzjy.oss-cn-wulanchabu.aliyuncs.com/%E5%AF%BC%E5%85%A5%E5%9F%B9%E8%AE%AD%E6%A8%A1%E6%9D%BF.xlsx"
          >导入表模板下载</a
        >
      </el-col>
    </el-row>
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column type="index" label="序号" width="100" fixed>
      </el-table-column>
      <el-table-column prop="name" label="姓名" fixed> </el-table-column>
      <el-table-column prop="phone" label="手机号"> </el-table-column>
      <el-table-column prop="inTime" label="加入时间"> </el-table-column>
      <!-- <el-table-column prop="" label="支付方式"> </el-table-column>
      <el-table-column prop="" label="商户订单号"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" @click="removeStudent(scope.row)"
            >退款/移除学员</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalItems"
    ></el-pagination>
    <el-dialog
      title="退款操作"
      :visible.sync="refundDialogVisible"
      width="30%"
      center
      v-if="refundUserData"
    >
      <div style="margin-bottom: 20px">手机号：{{ refundUserData.phone }}</div>
      <div style="margin-bottom: 20px">姓名：{{ refundUserData.name }}</div>
      <div style="margin-bottom: 20px">
        退款金额：{{ refundUserData.money }}
      </div>
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
        <el-form-item label="输入退款密码" prop="password">
          <el-input
            show-password
            type="password"
            v-model="ruleForm.password"
            placeholder="请输入退款密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="refundDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="refund('ruleForm')">完 成</el-button>
      </span>
    </el-dialog>
    <!-- 导出数据弹窗 -->
    <el-dialog
      title="导出设置"
      :visible.sync="dialogVisiv"
      width="30%"
      :before-close="handleClose"
    >
      <template>
        <div style="margin: -20px 0 20px">请勾选需要加密的内容：</div>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="1">身份证号</el-checkbox>
          <el-checkbox label="2">手机号</el-checkbox>
        </el-checkbox-group>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiv = false">取 消</el-button>
        <el-button type="primary" @click="examineExportListAll"
          >确定导出</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  listRecordCount,
  examineExportListAll,
  importNationUserExcel,
} from "@/api/courseManage";
import { msToDate } from "@/plugins/msToDate";

export default {
  data() {
    return {
      dialogVisiv: false,
      checkList: [],
      tableData: [],
      multipleSelection: [],
      fileList: [],
      currentPage: 1,
      pageSize: 10,
      totalItems: 0,
      lessonName: "",
      crux: "",
      refundDialogVisible: false,
      refundUserData: null,
      ruleForm: {
        password: "",
      },
      rules: {
        password: [
          {
            required: true,
            message: "请输入退款密码",
            trigger: "blur",
          },
        ],
      },
      flag: null,
    };
  },
  created() {
    this.lessonId = this.$route.query.lessonId
      ? this.$route.query.lessonId
      : "";
    this.listRecordCount();
    this.flag = this.$store.state.userInfo.flag;
    if (this.$store.state.userInfo.companyAdmin === true) {
      this.flag = 1;
    }
  },
  methods: {
    async listRecordCount() {
      let res = await listRecordCount({
        startNum: this.currentPage,
        pageSize: this.pageSize,
        lessonId: this.lessonId,
        crux: this.crux,
      });
      if (res.data.list) {
        this.tableData = res.data.list;
        this.lessonName = res.data.lessonName;
        this.tableData.forEach((item) => (item.inTime = msToDate(item.inTime)));
        this.totalItems = res.data.recordCount;
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    // 导出数据弹窗
    DerivedData() {
      this.dialogVisiv = true;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    async examineExportListAll() {
      let encryptIdCard = this.checkList.indexOf("1") != -1 ? true : false;
      let encryptPhone = this.checkList.indexOf("2") != -1 ? true : false;
      console.log(encryptPhone, encryptIdCard, this.checkList);
      let param = {
        lessonId: this.lessonId,
        crux: this.crux,
        encryptPhone: encryptPhone,
        encryptIdCard: encryptIdCard,
        // ids: this.selectedRows.join(","),
      };
      console.log(param, "param");
      let res = await examineExportListAll(param);
      console.log(res, "121");
      let link = document.createElement("a");
      let body = document.querySelector("body");
      let blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }); // res就是接口返回的文件流了
      console.log(blob, "55");
      link.href = window.URL.createObjectURL(blob);
      link.download = `普通用户名单`;
      // fix Firefox
      link.style.display = "none";
      body.appendChild(link);
      link.click();
      body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
      this.dialogVisiv = false;
    },
    // 学员导入
    importNationUserExcel(file, fileList) {
      console.log(file, fileList);
      const isXlsx =
        file.raw.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.raw.type === "application/vnd.ms-excel";
      this.fileList = [];
      if (!isXlsx) {
        this.$message.error("上传文件只能是 Excel 表格!");
        return;
      }
      let formData = new FormData();
      formData.append("file", file.raw);
      formData.append("lessonId", this.$route.query.lessonId);
      let result = importNationUserExcel(formData);
      result.then((res) => {
        console.log(res, "res");
        if (res.status == 200) {
          this.$message({
            message: "导入成功",
            type: "success",
          });
          this.listRecordCount();
        } else {
          this.$message.error("导入失败");
        }
      });
    },
    // 退款/移除学员
    removeStudent(row) {
      this.refundUserData = row;
      this.refundDialogVisible = true;
    },
    refund(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          console.log(valid);
        }
      });
    },
    // 跳转退款记录
    navigationRefundRecord() {
      this.$router.push({
        path: "/refundRecord",
        query: {
          lessonId: this.lessonId,
        },
      });
    },
    back() {
      this.$router.back();
    },
    search() {
      this.listRecordCount();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.listRecordCount();
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.listRecordCount();
    },
  },
  mounted() {
    // Fetch address options for cascader
    // Fetch initial data for table
  },
};
</script>

<style scoped>
.ml-20 {
  margin-left: 20px;
}
</style>
