<template>
  <div class="add-plat-form-admin">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="150px"
      class="demo-ruleForm"
    >
      <el-form-item label="设置账号" prop="accountNumber">
        <el-input
          v-model="ruleForm.accountNumber"
          placeholder="请输入街道级管理员账号"
          maxlength="30"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="设置密码" prop="password">
        <el-input
          type="password"
          show-password
          v-model="ruleForm.password"
          placeholder="请输入街道级管理员账号密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="再次输入密码" prop="verifyPassword">
        <el-input
          type="password"
          show-password
          v-model="ruleForm.verifyPassword"
          placeholder="请输入街道级管理员账号密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="管理员姓名" prop="adminName">
        <el-input
          v-model="ruleForm.adminName"
          placeholder="请输入街道级管理员姓名"
          maxlength="30"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone">
        <el-input
          v-model="ruleForm.phone"
          placeholder="请输入街道级管理员联系电话"
        ></el-input>
      </el-form-item>
      <el-form-item label="管理地区设置" prop="streetId">
        <el-select
          v-model="ruleForm.provId"
          placeholder="请选择省"
          @change="clearProvId"
          clearable
        >
          <el-option
            v-for="item in provList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="ruleForm.cityId"
          placeholder="请选择市"
          @change="clearCityId"
          clearable
        >
          <el-option
            v-for="item in cityList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="ruleForm.regionId"
          placeholder="请选择区"
          @change="clearRegionId"
          clearable
        >
          <el-option
            v-for="item in regionList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="ruleForm.streetId"
          placeholder="请选择街道"
          clearable
          @change="clearStreetId"
        >
          <el-option
            v-for="item in streetList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设置所在政府部门" prop="governmentId">
        <el-select
          v-model="ruleForm.governmentId"
          placeholder="请选择所在政府部门"
          clearable
        >
          <el-option
            v-for="item in governmentSectorList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >保存</el-button
        >
        <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  findProviceList,
  findCityList,
  findDistrictList,
  findStreetList,
} from "@/api/userLogin";
import { saveAdmin } from "@/api/userManagement";
import { queryEducationGovDepartmentById } from "@/api/common";

export default {
  data() {
    return {
      provList: [], //省级
      cityList: [], //市级列表
      regionList: [], // 区级
      streetList: [], // 街道
      governmentSectorList: [],

      ruleForm: {
        accountNumber: "",
        password: "",
        verifyPassword: "",
        adminName: "",
        phone: "",
        provId: "",
        cityId: "",
        regionId: "",
        streetId: "",
        governmentId: "",
      },
      rules: {
        accountNumber: [
          {
            required: true,
            message: "账号可为字母、数字或符号",
            trigger: "blur",
          },
        ],
        streetId: [
          {
            required: true,
            message: "请选择地区",
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: "至少6位密码，区分大小写",
            trigger: "blur",
          },
          {
            // pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}/,
            pattern: /[a-zA-Z\d]{6,}/,
            message: "至少6位密码，区分大小写",
            trigger: "blur",
          },
        ],
        verifyPassword: [
          { required: true, message: "确认密码", trigger: "blur" },
          {
            // pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}/,
            pattern: /[a-zA-Z\d]{6,}/,
            message: "至少6位密码，区分大小写",
            trigger: "blur",
          },
        ],
        adminName: [
          {
            required: true,
            message: "姓名只可为汉字、字母、数字或符号",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        // governmentId: [
        //   {
        //     required: true,
        //     message: "请选择所在政府部门",
        //     trigger: "change",
        //   },
        // ],
      },
    };
  },
  watch: {
    "ruleForm.streetId": {
      handler(val) {
        this.governmentSectorList = [];
        if (val) {
          this.queryEducationGovDepartmentById(val);
        }
      },
    },
    "ruleForm.regionId": {
      handler(val) {
        if (val) {
          this.getFindStreetList(val);
        }
      },
    },
    "ruleForm.cityId": {
      handler(val) {
        if (val) {
          this.getFindDistrictList(val);
        }
      },
    },
    "ruleForm.provId": {
      handler(val) {
        if (val) {
          this.getFindCityList(val);
        }
      },
    },
  },
  created() {
    this.getFindProviceList();
  },
  methods: {
    // 获取省市区街道政府部门
    async queryEducationGovDepartmentById(id) {
      let res = await queryEducationGovDepartmentById({ id: id });
      if (res.data.length > 0) {
        this.governmentSectorList = res.data;
      }
    },
    submitForm(formName) {
      if (this.ruleForm.password != this.ruleForm.verifyPassword) {
        this.$message({
          message: "请确认两次密码输入是否一致",
          type: "warning",
        });
        return;
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = await saveAdmin({
            account: this.ruleForm.accountNumber,
            password: this.ruleForm.password,
            confirmPassword: this.ruleForm.verifyPassword,
            name: this.ruleForm.adminName,
            phone: this.ruleForm.phone,
            isAdmin: 1,
            power: 6,
            province: this.ruleForm.provId,
            city: this.ruleForm.cityId,
            region: this.ruleForm.regionId,
            street: this.ruleForm.streetId,
            departmentId: this.ruleForm.governmentId,
          });
          console.log(res, "res");
          if (res.data.result == "success") {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
            this.$router.go(-1);
          } else {
            this.$message({
              type: "error",
              message: res.data.msg,
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取省
    async getFindProviceList() {
      let res = await findProviceList();
      if (res.data.result == "success") {
        this.provList = res.data.msg;
      }
    },
    // 清空省触发
    clearProvId() {
      this.ruleForm.cityId = "";
      this.ruleForm.regionId = "";
      this.ruleForm.streetId = "";
      this.ruleForm.governmentId = "";
      this.cityList = [];
      this.regionList = [];
      this.streetList = [];
      this.governmentSectorList = [];
    },
    // 清空市触发
    clearCityId() {
      this.ruleForm.regionId = "";
      this.ruleForm.streetId = "";
      this.ruleForm.governmentId = "";
      this.regionList = [];
      this.streetList = [];
      this.governmentSectorList = [];
    },
    // 清空区触发
    clearRegionId() {
      this.ruleForm.streetId = "";
      this.ruleForm.governmentId = "";
      this.streetList = [];
      this.governmentSectorList = [];
    },
    // 清空街道触发
    clearStreetId() {
      this.ruleForm.governmentId = "";
      this.governmentSectorList = [];
    },
    // 获取市
    async getFindCityList(id) {
      let res = await findCityList({ id: id });
      if (res.data.result == "success") {
        this.cityList = res.data.msg;
      }
    },
    // 获取区
    async getFindDistrictList(id) {
      let res = await findDistrictList({ id: id });
      if (res.data.result == "success") {
        this.regionList = res.data.msg;
      }
    },
    // 获取街道
    async getFindStreetList(id) {
      let res = await findStreetList({ id: id });
      if (res.data.result == "success") {
        this.streetList = res.data.msg;
      }
    },
  },
};
</script>

<style scoped>
.add-plat-form-admin {
  width: 70%;
  padding: 50px;
}
</style>
