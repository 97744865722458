//实现方法格式化时间为 YY-MM-DD hh:mm:ss @return 返回2个值，一个是带时分秒，另一个不带。
export function msToDate(msec) {
  let datetime = new Date(msec);
  let year = datetime.getFullYear();
  let month = datetime.getMonth();
  let date = datetime.getDate();
  let hour = datetime.getHours();
  let minute = datetime.getMinutes();
  let second = datetime.getSeconds();

  let result1 =
    year +
    "-" +
    (month + 1 >= 10 ? month + 1 : "0" + (month + 1)) +
    "-" +
    (date + 1 < 10 ? "0" + date : date) +
    " " +
    (hour + 1 < 10 ? "0" + hour : hour) +
    ":" +
    (minute + 1 < 10 ? "0" + minute : minute) +
    ":" +
    (second + 1 < 10 ? "0" + second : second);

  // let result2 = year +
  //              '-' +
  //              ((month + 1) >= 10 ? (month + 1) : '0' + (month + 1)) +
  //              '-' +
  //              ((date + 1) < 10 ? '0' + date : date);

  // let result = {
  //     hasTime: result1,
  //     withoutTime: result2
  // };

  return result1;
}
