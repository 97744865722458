<template>
  <router-view></router-view>
</template>

<script>
export default {
  created() {
    if (sessionStorage.getItem("ExternalPath") == "true") {
      this.$store.state.breadListState.push({
        name: this.$route.name,
        path: this.$route.fullPath,
      });

      sessionStorage.setItem(
        "umeetCloudVuex",
        JSON.stringify(this.$store.state)
      );
      sessionStorage.setItem(
        "navigateIndex",
        JSON.stringify({
          menuId: 9999,
          menuIndex: this.$route.fullPath,
          menuIcon: "",
          menuName: this.$route.name,
          childrens: [],
        })
      );
      sessionStorage.setItem("ExternalPath", false);
    }
    // 在页面加载时读取sessionStorage里的状态信息
    sessionStorage.getItem("umeetCloudVuex") &&
      this.$store.replaceState(
        Object.assign(
          this.$store.state,
          JSON.parse(sessionStorage.getItem("umeetCloudVuex"))
        )
      );
    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("currentNavigate", this.$route.fullPath);
      sessionStorage.setItem(
        "umeetCloudVuex",
        JSON.stringify(this.$store.state)
      );
    });
  },
};
</script>

<style></style>
